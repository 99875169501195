<template>
	<v-container
		align-center
		align-content-center
		fill-height
		fluid
		justify-center
		wrap
		class="container"
		:style="{ backgroundImage: `url('${api.url}img/fondo (1).jpg')` }"
	>
		<v-card v-if="!registerMode" width="400px" class="text-left">
			<v-card-title>
				<v-img contain height="50px" style="margin: auto" :src="api.url + 'img/logo-newton.png'"></v-img>
			</v-card-title>
			<p class="title text-center">Iniciar sesión</p>
			<v-card-text>
				<v-form>
					<v-text-field type="url" v-model="api.url" v-if="!preconfigured" hide-details label="URL"></v-text-field>
					<v-text-field
						v-model="email"
						autocomplete="username"
						hide-details
						name="email"
						:label="'email' | trans"
						id="email"
						autofocus
					></v-text-field>
					<v-text-field
						v-model="password"
						name="password"
						hide-details
						:label="'password' | trans"
						autocomplete="current-password"
						id="password"
						:type="showPassword ? 'text' : 'password'"
						:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
						@click:append="showPassword = !showPassword"
						@keypress.enter="login()"
					></v-text-field>
					<v-checkbox v-model="remember" :label="'remember me' | trans"></v-checkbox>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn v-if="api.registrationOpen" @click="registerMode = true" tabindex="-1" text color="primary"
					>Registrarse</v-btn
				>
				<v-spacer></v-spacer>
				<v-btn
					:loading="loginin"
					@click="login()"
					color="primary"
					large
					:disabled="email.length < 3 || password.length < 4"
					>Entrar</v-btn
				>
			</v-card-actions>
			<v-card-actions>
				<v-btn color="orange" block small text :href="api.url + 'password/reset'" target="_system">
					Olvide Mi Contrasena
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-card v-else width="400px" class="text-left">
			<v-card-title>
				<v-img contain height="50px" style="margin: auto" :src="api.url + 'img/logo-newton.png'"></v-img>
			</v-card-title>
			<p class="title text-center">Registro</p>
			<v-card-text>
				<v-form>
					<v-text-field v-model="nombre" name="nombre" :label="'name' | trans" autofocus></v-text-field>
					<v-text-field v-model="documento" name="documento" :label="'document' | trans"></v-text-field>
					<v-text-field v-model="email" name="email" :label="'email' | trans" id="email" autofocus></v-text-field>
					<v-text-field
						v-model="password"
						name="password"
						autocomplete="current-password"
						label="Contrasena"
						type="password"
						@keypress.enter="login()"
					></v-text-field>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn large @click="registerMode = false" tabindex="-1" text color="primary">Ya soy usuario</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					large
					:loading="loginin"
					@click="register()"
					color="primary"
					:disabled="nombre.length < 3 || documento.length < 3 || email.length < 3 || password.length < 4"
					>Registrarse</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-container>
</template>
<script>
export default {
	mounted() {
		window.localStorage.removeItem("url");
		if (this.api.user) {
			this.api.logout();
		}
		this.preconfigured = !!window.url;
	},
	data() {
		return {
			showPassword: false,
			preconfigured: true,
			registerMode: false,
			loginin: false,
			remember: false,
			nombre: "",
			documento: "",
			email: "",
			password: "",
		};
	},
	methods: {
		login() {
			if (this.api.url.indexOf("http") == -1) {
				this.api.url = `https://${this.api.url}.eycproveedores.com/`;
			}
			if (!window.url) {
				window.localStorage.setItem("url", this.api.url);
				this.api.storage.prefix = this.api.url;
			}
			this.loginin = true;
			return this.api
				.login(this.email, this.password, this.remember)
				.then(() => {
					this.loginin = false;
					this.$router.replace("/");
					if (this.api.user && this.api.user.app_color) {
						this.$vuetify.theme.themes.light.primary = this.api.user.app_color;
						this.$vuetify.theme.themes.dark.primary = this.api.user.app_color;
						document.querySelector("meta[name=theme-color]").setAttribute("content", this.api.user.app_color);
					}
				})
				.catch((err) => {
					this.api.handleError(err);
					this.loginin = false;
					console.error(err);
				});
		},
		async register() {
			this.loginin = true;
			try {
				await this.api.post("register", {
					documento: this.documento,
					nombre: this.nombre,
					email: this.email,
					password: this.password,
				});
				await this.login();
			} catch (err) {
				this.loginin = false;
				console.error(err);
				this.api.handleError(err);
			}
		},
	},
};
</script>
<style scoped>
.container {
	background-size: cover;
}
</style>
