import { api } from "@/services/api";
import trans from "@/services/trans";
import moment from "moment";
export const validations = {
	titulo: "required|min:3|max:255",
	contenido: "required|min:5",
	estado: "required|min:2",
	user_id: "required|exists:users,id",
	guardian_id: "required|exists:users,id",
	assignees: "nullable|array",
	"assignees.*": "exists:users,id",
	categoria_id: "required|exists:categorias_tickets,id",
	cliente_id: "nullable|exists:clientes,id",
	vencimiento: "date|nullable|after:tomorrow",
	archivo: "file|max:20480",
	priority: "nullable|numeric|min:0|max:5",
	points: "nullable|numeric|min:0|max:99",
	extra: "array|nullable",
};

export const priorities = {
	0: trans("none"),
	1: trans("low"),
	2: trans("medium"),
	3: trans("high"),
	4: trans("urgent"),
	5: trans("overdue"),
};

export const priorities_list = [
	{ value: 0, action: "➖", text: trans("none") },
	{ value: 1, action: "⬇", text: trans("low") },
	{ value: 2, action: "↘", text: trans("medium") },
	{ value: 3, action: "➡", text: trans("high") },
	{ value: 4, action: "↗", text: trans("urgent") },
	{ value: 5, action: "⬆", text: trans("overdue") },
];

export const model = {
	model: "ticket",
	filters: [
		{
			name: "user_id",
			label: "user",
			type: "relation",
			resource: "users",
			title: "full_name",
			description: "departamento",
			handler: (v) => `whereIn[user_id]=${v}`,
			searchLogic: (v, i) => `${i.user_id}` == `${v}`,
		},
		{
			name: "guardian_id",
			label: "guardian",
			type: "relation",
			resource: "users",
			title: "full_name",
			description: "departamento",
			handler: (v) => `whereIn[guardian_id]=${v}`,
			searchLogic: (v, i) => `${i.guardian_id}` == `${v}`,
		},
		{
			name: "categories",
			label: "category",
			type: "relation",
			resource: "categorias-tickets",
			handler: (v) => `whereIn[categoria_id]=${v}`,
			searchLogic: (v, i) => `${i.categoria_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "title",
			value: "titulo",
			type: "text",
			tab: "ticket",
			required: true,
			fullWidth: true,
		},
		{
			text: "content",
			value: "contenido",
			type: "richtext",
			tab: "ticket",
			height: "200px",
			editonly: true,
			required: true,
			fullWidth: true,
		},
		{
			text: "status",
			value: "estado",
			type: "select",
			tab: "ticket",
			default: "abierto",
			options: [
				{ text: "abierto", value: "abierto" },
				{ text: "en curso", value: "en curso" },
				{ text: "completado", value: "completado" },
				{ text: "rechazado", value: "rechazado" },
				{ text: "vencido", value: "vencido" },
			],
			chip: true,
			required: true,
			rules: "required",
		},
		{
			text: "user",
			value: "user_id",
			entity: "user",
			type: "relation",
			tab: "ticket",
			resource: "users?scope[fromClient]=1&scope[can]=Ver Tickets",
			rules: "required",
			required: true,
			attribute: "full_name",
			description: "departamento",
			default: () => api.user.id,
			visibleIf: () => api.user.roles.collection["SuperAdmin"],
		},
		{
			text: "user",
			value: "user_id",
			entity: "user",
			type: "hidden",
			tab: "ticket",
			resource: "users?scope[fromClient]=1&scope[can]=Ver Tickets",
			rules: "required",
			required: true,
			attribute: "full_name",
			description: "departamento",
			editonly: true,
			default: () => api.user.id,
			visibleIf: () => !api.user.roles.collection["SuperAdmin"],
		},
		{
			text: "priority",
			value: "priority",
			default: 2,
			type: "select",
			options: priorities_list,
			tab: "ticket",
		},
		{
			text: "guardian",
			value: "guardian_id",
			entity: "guardian",
			model: "user",
			type: "relation",
			tab: "ticket",
			resource: "users?scope[fromClient]=1&scope[can]=Ver Tickets",
			attribute: "full_name",
			description: "departamento",
			required: true,
		},
		{
			text: "assignees",
			value: "assignees",
			entity: "assignees",
			model: "user",
			type: "relation_multiple",
			tab: "ticket",
			multiple: true,
			default: () => [],
			resource: "users?scope[fromClient]=1&scope[can]=Ver Tickets",
			attribute: "full_name",
			description: "departamento",
		},
		{
			text: "category",
			value: "categoria_id",
			model: "CategoriaTicket",
			entity: "categoria",
			type: "relation",
			tab: "ticket",
			required: true,
			resource: "categorias-tickets",
			rules: "required",
			attribute: "full_name",
		},
		{
			text: "client",
			value: "cliente_id",
			entity: "cliente",
			model: "cliente",
			type: "relation",
			tab: "ticket",
			resource: "clientes",
			attribute: "full_name",
			visibleIf: () => !api.user.cliente_id,
		},
		{
			text: "created_at",
			value: "created_at",
			type: "datetime",
			tab: "ticket",
			readonly: true,
			visible: false,
		},
		{
			text: "last update",
			value: "updated_at",
			type: "datetime",
			tab: "ticket",
			readonly: true,
		},
		{
			text: "expiration",
			value: "vencimiento",
			type: "datetime",
			allowedDates: (d) => !["Saturday", "Sunday"].includes(moment(d).locale("en").format("dddd")),
			tab: "ticket",
			visible: true,
		},
		{
			value: "archivo",
			text: "file",
			type: "file",
			tab: "ticket",
			editonly: true,
		},
		{
			text: "¿Es transferible?",
			value: "transferible",
			type: "checkbox",
			tab: "ticket",
			visible: false,
		},
		{
			text: "extra",
			value: "extra",
			type: "extra",
			tab: "others",
			visible: false,
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
