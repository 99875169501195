<template>
	<div>
		<!-- Top  Header -->
		<div>
			<v-layout row wrap align-start justify-center>
				<v-flex xs6 sm5 align-self-start>
					<slot name="top-buttons" :addItem="addItem" :reorderAction="reorderAction" :label="label_ | trans">
						<v-btn
							class="hidden-sm-and-down mx-2"
							v-if="crudActions && !hideCreate"
							color="primary"
							:title="'crud.add' | trans"
							depressed
							v-can="createPermission"
							@click="addItem()"
							small
						>
							<v-icon left>add</v-icon>
							{{ "crud.add" | trans }}
						</v-btn>
						<slot name="extra-buttons" :selected="selected"></slot>
						<v-btn
							v-if="reorderAction"
							depressed
							small
							:to="`${resource}/reorder?title=${reorderTitle}&description=${reorderDescription}`"
						>
							<v-icon left>mdi-drag-variant</v-icon>
							{{ "crud.reorder" | trans }}
							{{ label_ | trans }}
						</v-btn>
						<h4 class="mx-2 text-capitalize" style="display: inline-block">
							{{ label_ | trans }}
						</h4>
						<slot name="bulk-buttons" :selected="selected"></slot>
					</slot>
				</v-flex>

				<v-flex xs6 sm4 class="text-right" justify-center align-self-start>
					<!-- Columns visibility template -->
					<v-menu offset-y :close-on-content-click="false">
						<template v-slot:activator="{ on }">
							<v-btn
								class="mt-3"
								:title="'literals.columns' | trans"
								:icon="$vuetify.breakpoint.xsOnly"
								color="dark"
								text
								small
								v-on="on"
							>
								<span class="hidden-xs-only">{{ "literals.columns" | trans }}</span>
								<v-icon class="hidden-sm-and-up">visibility_off</v-icon>
							</v-btn>
						</template>
						<v-list dense>
							<template v-for="header in $data.$headers">
								<v-list-item
									:key="header.value"
									v-if="!header.editonly"
									:class="{ 'v-list-item--active': !!header.visible }"
									@click="changeVisibility(header)"
								>
									<v-list-item-content>{{ header.text | trans }}</v-list-item-content>
								</v-list-item>
							</template>
							<v-list-item
								:class="{ 'v-list-item--active': crudActionsVisible }"
								v-if="crudActions"
								@click="crudActionsVisible = !crudActionsVisible"
							>
								<v-list-item-content>{{ "crud.actions" | trans }}</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>

					<v-menu open-on-hover transition="slide-x-transition" bottom right min-width="100" offset-y>
						<template v-slot:activator="{ on }">
							<v-btn class="mt-3" icon small v-on="on" text depressed :title="'export' | trans">
								<v-icon>import_export</v-icon>
							</v-btn>
						</template>
						<v-list dense>
							<v-list-item @click="exportData('xlsx')">
								<v-list-item-title>{{ "crud.export.excel" | trans }}</v-list-item-title>
							</v-list-item>
							<v-list-item @click="exportData('pdf')">
								<v-list-item-title>{{ "crud.export.pdf" | trans }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>

				<v-flex xs12 sm3 align-self-start>
					<div style="height: 10px" class="hidden-sm-and-up"></div>
					<v-text-field
						outlined
						rounded
						type="search"
						@input="debounceSearch()"
						append-icon="search"
						v-model="query"
						color="accent"
						clearable
						:label="'crud.search' | trans"
					></v-text-field>
				</v-flex>
			</v-layout>

			<!-- Filter Template -->
			<template v-if="$data.$filters.length > 0">
				<span class="grey--text layout hidden-lg-and-up">
					<v-btn small text @click="filtersShow = !filtersShow">
						<span>{{ "filters" | trans }}</span>
						<v-icon small class="icon-arrow" :class="{ rotated: !filtersShow }">arrow_downward</v-icon>
					</v-btn>
				</span>
				<span class="hidden-md-and-down">Filtros</span>
				<v-layout
					row
					:wrap="false"
					class="mx-2 my-1"
					style="overflow: scroll; flex-wrap: initial"
					:class="{ 'hidden-md-and-down': !filtersShow }"
				>
					<v-flex shrink v-for="filter in $data.$filters" :key="filter.name" v-can="filter.can">
						<default-field v-model="filter._value" @input="getData()" :no-icon="true" :props="filter"></default-field>
					</v-flex>
				</v-layout>
			</template>
		</div>

		<!-- Datatable -->
		<v-data-table
			class="datatable-crud"
			calculate-widths
			:id="`${resource}-table`"
			:headers="$data.$headers"
			v-model="selected"
			hide-default-header
			:items="items"
			:loading="loading"
			locale="es"
			item-key="id"
			:items-per-page.sync="pagination.itemsPerPage"
			:footer-props="footerProps"
			no-data-text="No se han encontrado registros"
			:options="pagination"
			:server-items-length.sync="pagination.totalItems"
			@update:page="debounceSearch('page', $event)"
			@update:items-per-page="debounceSearch('itemsPerPage', $event)"
			@update:sort-by="debounceSearch('sortBy', $event)"
			@update:sort-desc="debounceSearch('descending', $event)"
		>
			<!-- Headers  -->
			<template v-slot:header="{ props }">
				<tr>
					<th v-if="bulkActions">
						<v-simple-checkbox
							:value="props.everyItem"
							:indeterminate="props.someItems && !props.everyItem"
							color="primary"
							hide-details
							@click.stop="toggleAll"
						></v-simple-checkbox>
					</th>
					<template v-for="header in $data.$headers">
						<th
							v-if="!header.editonly && header.visible !== false"
							:key="header.value"
							style="min-width: 110px"
							class="text-center v-data-cell-header"
							:class="[`${header.class}`, { 'column sortable': header.sortable !== false }]"
							@click="header.sortable !== false && changeSort(header.field)"
						>
							<span>{{ header.text | trans }}</span>
							<template v-if="header.sortable !== false">
								<template v-if="header.value === pagination.sortBy || header.field === pagination.sortBy">
									<v-icon v-if="pagination.descending" small>arrow_downward</v-icon>
									<v-icon v-else small>arrow_upward</v-icon>
								</template>
								<v-icon v-else small class="only-on-hover">arrow_upward</v-icon>
							</template>
						</th>
					</template>
					<th v-if="crudActions && crudActionsVisible" class="text-center v-data-cell-header">
						{{ "crud.actions" | trans }}
					</th>
				</tr>
			</template>

			<!-- Items -->
			<template v-slot:item="props">
				<slot
					name="items"
					:item="props.item"
					:selected="props.isSelected"
					:index="props.index"
					:headers="$data.$headers"
				>
					<tr
						:active="props.isSelected"
						@dblclick.stop="clickItem(props.item, props.index)"
						@contextmenu.prevent="showMenu(props.item, $event)"
					>
						<td style="width: 40px" v-if="bulkActions">
							<v-simple-checkbox
								:value="props.isSelected"
								@click.stop="props.select(!props.isSelected)"
								color="primary"
								hide-details
							></v-simple-checkbox>
						</td>
						<template v-for="header in $data.$headers">
							<td
								class="text-center"
								:key="header.value"
								v-if="!header.editonly && header.visible !== false"
								:class="props.item.class"
							>
								<default-row :item="props.item" :header="header"></default-row>
							</td>
						</template>
						<slot
							name="buttons"
							v-show="crudActions"
							v-if="crudActionsVisible"
							:crudActions="crudActions"
							:hideUpdate="hideUpdate"
							:index="props.index"
							:crudActionsVisible="crudActionsVisible"
							:edit-permission="editPermission"
							:delete-permission="deletePermission"
							:hide-delete="hideDelete"
							:model="model"
							:revisions="revisions"
							:item="props.item"
							:edit-handler="editItem"
							:delete-handler="confirmDelete"
						>
							<td class="text-center crud-buttons" style="white-space: nowrap">
								<v-btn
									:x-small="!minimized && $vuetify.breakpoint.mdAndUp"
									:small="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:icon="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:title="'crud.edit' | trans"
									color="primary"
									v-if="!hideUpdate"
									v-can="editPermission"
									@click="editItem(props.item, props.index)"
								>
									<v-icon :small="!minimized && $vuetify.breakpoint.mdAndUp">edit</v-icon>
									<span v-if="!minimized && $vuetify.breakpoint.mdAndUp">{{ "crud.edit" | trans }}</span>
								</v-btn>
								<v-btn
									:x-small="!minimized && $vuetify.breakpoint.mdAndUp"
									:small="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:icon="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:title="'crud.delete' | trans"
									v-if="!hideDelete"
									v-can="deletePermission"
									color="error"
									@click="confirmDelete(props.item)"
								>
									<v-icon :small="!minimized && $vuetify.breakpoint.mdAndUp">delete</v-icon>
									<span v-if="!minimized && $vuetify.breakpoint.mdAndUp">{{ "crud.delete" | trans }}</span>
								</v-btn>
								<v-btn
									:x-small="!minimized && $vuetify.breakpoint.mdAndUp"
									:small="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:icon="!(!minimized && $vuetify.breakpoint.mdAndUp)"
									:title="'crud.revisions' | trans"
									v-if="revisions"
									v-has-role="revisions"
									color="warning"
									:to="`/${model}/${props.item.id}/revisions`"
								>
									<v-icon :small="!minimized && $vuetify.breakpoint.mdAndUp">history</v-icon>
									<span v-if="!minimized && $vuetify.breakpoint.mdAndUp">{{ "crud.revisions" | trans }}</span>
								</v-btn>
								<slot name="extra-item-buttons" :minimized="minimized" :item="props.item"></slot>
								<v-menu left v-if="!!$scopedSlots['extra-item-actions']">
									<template v-slot:activator="{ on }">
										<v-btn text icon v-on="on">
											<v-icon>more_vert</v-icon>
										</v-btn>
									</template>
									<v-card>
										<v-card-text class="layout column" style="gap: 8px">
											<slot name="extra-item-actions" :minimized="minimized" :item="props.item"></slot>
										</v-card-text>
									</v-card>
								</v-menu>
							</td>
						</slot>
					</tr>
				</slot>
			</template>
		</v-data-table>

		<slot class="text-right" name="bulk-buttons" :selected="selected" v-if="bulkActions">
			<slot name="bulk-buttons-extra" :selected="selected"></slot>
			<v-btn
				color="red"
				v-if="selected.length && bulkDelete"
				small
				dark
				depressed
				@click="confirmMultipleDeleteDialog = true"
				v-can="multipleDeletePermission"
			>
				<v-icon left>delete</v-icon>
				{{ "crud.delete" | trans }} {{ selected.length }}
				{{ "records" | trans }}
			</v-btn>
		</slot>

		<!-- Create/Edit Dialog -->
		<v-dialog
			v-model="dialog"
			scrollable
			persistent
			no-click-animation
			max-width="80%"
			:fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
			transition="dialog-transition"
		>
			<slot
				v-if="item && dialog"
				name="form"
				:headers="$data.$headers"
				:item="item"
				:save-action="save"
				:cancel-action="cancel"
				:get-data="getData"
			>
				<crud-editor :headers="$data.$headers" :item="item" :save-action="save" :cancel-action="cancel">
					<template v-slot:title>
						{{ item.id ? "edit" : "add" | trans("crud") }}
						{{ (singleLabel ? singleLabel : model) | trans("literals") }}
					</template>
				</crud-editor>
			</slot>
		</v-dialog>

		<!-- View Dialog -->
		<v-dialog
			v-model="viewDialog"
			scrollable
			no-click-animation
			persistent
			max-width="550px"
			:fullscreen="$vuetify.breakpoint.smAndDown"
			transition="dialog-transition"
		>
			<slot
				v-if="item && viewDialog"
				name="default"
				:headers="$data.$headers"
				:item="item"
				:cancel-action="
					() => {
						viewDialog = false;
						item = null;
					}
				"
			>
				<crud-viewer
					:color="null"
					:headers="$data.$headers"
					:item="item"
					:cancel-action="
						() => {
							viewDialog = false;
							item = null;
						}
					"
				>
					<template v-slot:title>{{ item.full_name || item.title || item.name }}</template>
				</crud-viewer>
			</slot>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog v-model="confirmDeleteDialog" persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-card-title class="text-center layout row">
					<v-icon color="error" large left>warning</v-icon>
					<h5>{{ "crud.delete_confirm" | trans }}</h5>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						autofocus
						text
						@click="
							confirmDeleteDialog = false;
							item = null;
						"
						>{{ "crud.cancel" | trans }}</v-btn
					>
					<v-btn
						color="error"
						@click="
							deleteItem(item);
							confirmDeleteDialog = false;
						"
						>{{ "crud.delete" | trans }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Multiple Delete Dialog -->
		<v-dialog v-model="confirmMultipleDeleteDialog" persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-card-title class="text-center layout row">
					<v-icon color="error" large left>warning</v-icon>
					<h5>{{ "crud.delete_confirm" | trans }}</h5>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn autofocus text @click="confirmMultipleDeleteDialog = false">{{ "crud.cancel" | trans }}</v-btn>
					<v-btn
						color="error"
						@click="
							deleteMultipleItems(selected);
							confirmMultipleDeleteDialog = false;
						"
						>{{ "crud.delete" | trans }} {{ selected.length }} {{ "records" | trans }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Context Menu  -->
		<v-menu
			origin="center center"
			transition="slide-y-transition"
			offset-y
			absolute
			v-model="menu"
			style="max-width: 600px"
			:position-x="x"
			:position-y="y"
		>
			<v-list v-if="item && menu" dense flat>
				<slot
					name="menu"
					:closeAction="() => (menu = false)"
					:item="item"
					:view-item="viewItem"
					:edit-item="editItem"
					:duplicate-item="duplicateItem"
					:delete-item="confirmDelete"
				>
					<v-list-item @click="viewItem(item)">
						<v-list-item-title>{{ "view_resource" | trans }}</v-list-item-title>
						<v-list-item-action>
							<v-icon>mdi-eye</v-icon>
						</v-list-item-action>
					</v-list-item>
					<slot name="extra-items-menu" :item="item" :closeAction="() => (menu = false)">
						<v-divider></v-divider>
					</slot>
					<v-list-item v-if="!hideUpdate" v-can="editPermission" @click="editItem(item)">
						<v-list-item-title>{{ "crud.edit" | trans }}</v-list-item-title>
						<v-list-item-action>
							<v-icon>edit</v-icon>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-if="showClone" v-can="editPermission" @click="duplicateItem(item)">
						<v-list-item-title>{{ "crud.duplicate" | trans }}</v-list-item-title>
						<v-list-item-action>
							<v-icon>file_copy</v-icon>
						</v-list-item-action>
					</v-list-item>
					<v-list-item v-if="!hideDelete" v-can="deletePermission" @click="confirmDelete(item)">
						<v-list-item-title>{{ "crud.delete" | trans }}</v-list-item-title>
						<v-list-item-action>
							<v-icon>delete</v-icon>
						</v-list-item-action>
					</v-list-item>
					<template v-if="revisions" v-has-role="revisions">
						<v-divider></v-divider>
						<v-list-item :to="`/${model}/${item.id}/revisions`">
							<v-list-item-title>{{ "crud.revisions" | trans }}</v-list-item-title>
							<v-list-item-action>
								<v-icon>history</v-icon>
							</v-list-item-action>
						</v-list-item>
					</template>
				</slot>
			</v-list>
		</v-menu>

		<v-btn
			fixed
			bottom
			right
			class="hidden-md-and-up"
			fab
			v-if="crudActions && !hideCreate"
			color="primary"
			:title="'crud.add' | trans"
			v-can="createPermission"
			@click="addItem()"
		>
			<v-icon>add</v-icon>
		</v-btn>
	</div>
</template>
<script>
import { get, isEqual } from "lodash";

import moment from "moment";
import DefaultRow from "@/components/main/DefaultRow";
import DefaultField from "@/components/main/DefaultField";
import CrudEditor from "@/components/main/CrudEditor";
import CrudViewer from "@/components/main/CrudViewer";
import IMEX from "@/services/IMEX";
import models from "@/models/modelsDefinitions";
import { createInstance } from "@/services/storage";
export default {
	components: { DefaultRow, DefaultField, CrudEditor, CrudViewer },
	async beforeMount() {
		if (!this.pagination.itemsPerPage) {
			var pag = parseInt(this.perPage);
			if (isNaN(pag)) pag = 5;
			this.$set(this.pagination, "itemsPerPage", pag);
		}
		if (this.defaultOrder) {
			var descending = this.defaultOrder[0] == "-";
			this.pagination.descending = descending;
			if (descending) {
				this.pagination.sortBy = this.defaultOrder.substring(1);
			} else {
				this.pagination.sortBy = this.defaultOrder;
			}
		}
		var filters = this.filters;
		if (!filters) filters = models[this.resource] ? models[this.resource].filters : [];
		this.toLoad = this.load.length
			? this.load
			: models[this.resource] && models[this.resource].load
			? models[this.resource].load
			: [];

		if (filters && filters.length > 0) {
			this.$data.$filters = filters.map((filter) => {
				filter.text = this.$options.filters.trans(filter.label);
				filter._value = filter.default || null;
				return filter;
			});
		}
		var headers = this.headers;
		if (!headers) headers = models[this.resource] ? models[this.resource].headers : [];
		headers.forEach((h) => {
			if (h.visible === undefined) h.visible = true;
			if (h.field === undefined) h.field = h.value;
			if (h.value === undefined) h.value = h.field;
		});
		const visibleColumns = await this.columnStorage.get(`visible_columns_${this.resource}`, []);
		if (visibleColumns.length > 0) {
			headers.forEach((h) => {
				h.visible = visibleColumns.includes(h.value || h.field);
			});
		}

		this.$data.$headers = headers;
		console.log(this.$data.$headers);
		this.model = models[this.resource]
			? models[this.resource].model
			: this.resource.substring(0, this.resource.length - 1);
	},
	mounted() {
		this.getFromQueryParams();
		if (this.label) {
			this.label_ = this.label;
		} else {
			this.label_ = this.resource.split("?")[0];
		}
		this.debounceSearch();
		this.api.ready.then(() => {
			if (!this.data && this.api.user.broadcasting) {
				this.api.Echo.private(`.${this.resource.split("?")[0]}`)
					.listen(`.${this.model.toLowerCase()}.deleted`, (payload) => {
						var item = this.items.find((i) => {
							return i.id == payload.model.id;
						});
						if (item) this.getData();
					})
					.listen(`.${this.model.toLowerCase()}.updated`, (payload) => {
						var item = this.items.find((i) => {
							return i.id == payload.model.id;
						});
						if (item) Object.assign(item, payload.model);
					});
			}
		});
	},
	beforeDestroy() {
		if (!this.data && this.api.user.broadcasting) {
			this.api.Echo.private(`.${this.resource.split("?")[0]}`);
		}
	},
	methods: {
		log(toLog) {
			console.log(toLog);
		},
		debounceSearch(attribute = null, value = null) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.getData(attribute, value);
			}, 700);
		},
		toggleAll() {
			if (this.selected.length) this.selected = [];
			else this.selected = this.items.slice();
		},
		changeVisibility(header) {
			this.$set(header, "visible", !header.visible);
			this.columnStorage.set(
				`visible_columns_${this.resource}`,
				this.$data.$headers.filter((h) => h.visible).map((h) => h.value || h.field)
			);
		},
		changeSort(column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending;
			} else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
			this.getData();
		},
		_getDataApi() {
			this.loading = true;

			var uri = `${this.resource.indexOf("?") > -1 ? "&" : "?"}paginate=${
				this.pagination.itemsPerPage > 0 ? this.pagination.itemsPerPage : 10000
			}&page=${this.pagination.page || 1}`;
			if (this.toLoad.length > 0) {
				uri += `&include=${this.toLoad}`;
			}
			if (this.append.length > 0) {
				uri += `&${this.append}`;
			}
			if (this.pagination.sortBy) {
				uri += `&order[${this.pagination.sortBy}]=${this.pagination.descending ? "desc" : "asc"}`;
			}
			this.$data.$filters.forEach((f) => {
				if (f._value) {
					uri += "&" + f.handler(f._value);
				}
			});

			if (this.query && this.query.length > 0) {
				uri += `&query=${this.query}`;
			}
			this.api
				.get(`${this.resource}${uri}`)
				.then((resp) => {
					if (Number(resp.data.per_page) < 10000) {
						this.pagination.itemsPerPage = parseInt(resp.data.per_page);
					}
					this.pagination.totalItems = parseInt(resp.data.total);
					this.pagination.page = parseInt(resp.data.current_page);
					this.items = resp.data.data;
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					this.api.handleError(err);
				});
		},
		_getDataLocal() {
			var uri = `?limit=10000`;
			if (this.toLoad.length > 0) uri += `&include=${this.toLoad}`;
			var prepareData = (items) => {
				var filtered = items.filter((item) => {
					for (var i = 0; i < this.$data.$filters.length; i++) {
						var filter = this.$data.$filters[i];
						if (filter._value && filter.searchLogic && filter.searchLogic(filter._value, item) == false) {
							return false;
						}
					}
					var valid = !this.query || this.query.length === 0;
					if (!valid) {
						var q = this.query
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "");
						for (var j = 0; j < this.$data.$headers.length; j++) {
							var header = this.$data.$headers[j];
							var toEval = item[header.field];
							if (header.type == "relation") {
								if (item[header.entity]) {
									toEval = item[header.entity][header.attribute];
								} else {
									continue;
								}
							}
							if (
								`${toEval}`
									.toLowerCase()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.indexOf(q) > -1
							) {
								return true;
							}
						}
					}
					return valid;
				});

				this.pagination.totalItems = filtered.length;
				if (this.pagination.sortBy) {
					filtered.sort((a, b) => {
						if (a[this.pagination.sortBy] < b[this.pagination.sortBy]) {
							return -1;
						}
						if (a[this.pagination.sortBy] > b[this.pagination.sortBy]) {
							return 1;
						}
						return 0;
					});
					if (this.pagination.descending) {
						filtered.reverse();
					}
				}
				this.items = filtered.slice(
					(this.pagination.page - 1) * this.pagination.itemsPerPage,
					this.pagination.page * this.pagination.itemsPerPage
				);
				this.loading = false;
			};

			this.loading = true;
			this.deferreds
				.load(`${this.resource}${uri}`)
				.then(prepareData, prepareData)
				.catch((err) => {
					this.loading = false;
					this.api.handleError(err);
				});
		},
		_getDataFromArray() {
			this.loading = true;
			var prepareData = (items) => {
				var filtered = items.filter((item) => {
					for (var i = 0; i < this.$data.$filters.length; i++) {
						var filter = this.$data.$filters[i];
						if (filter._value && filter.searchLogic && filter.searchLogic(filter._value, item) == false) {
							return false;
						}
					}
					var valid = !this.query || this.query.length === 0;
					if (!valid) {
						var q = this.query
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "");
						for (var j = 0; j < this.$data.$headers.length; j++) {
							var header = this.$data.$headers[j];
							var toEval = item[header.field];
							if (header.type == "relation") {
								if (item[header.entity]) {
									toEval = item[header.entity][header.attribute];
								} else {
									continue;
								}
							}
							if (
								`${toEval}`
									.toLowerCase()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.indexOf(q) > -1
							) {
								return true;
							}
						}
					}
					return valid;
				});

				this.pagination.totalItems = filtered.length;
				if (this.pagination.sortBy) {
					filtered.sort((a, b) => {
						if (a[this.pagination.sortBy] < b[this.pagination.sortBy]) {
							return -1;
						}
						if (a[this.pagination.sortBy] > b[this.pagination.sortBy]) {
							return 1;
						}
						return 0;
					});
					if (this.pagination.descending) {
						filtered.reverse();
					}
				}
				this.items = filtered.slice(
					(this.pagination.page - 1) * this.pagination.itemsPerPage,
					this.pagination.page * this.pagination.itemsPerPage
				);
				this.loading = false;
			};
			prepareData(this.data);
		},
		getData(attribute, value) {
			this.selected.length = 0;
			if (attribute && value) this.$set(this.pagination, attribute, value);
			if (this.data) {
				this._getDataFromArray();
			} else if (this.mode == "ajax") {
				this._getDataApi();
			} else {
				this._getDataLocal();
			}
			this.setQueryParams();
		},
		getFromQueryParams() {
			if (this.$route.query.descending !== undefined)
				this.pagination.descending = this.$route.query.descending == "true";
			if (this.$route.query.sortBy) this.pagination.sortBy = this.$route.query.sortBy;
			if (this.$route.query.page) this.pagination.page = parseInt(this.$route.query.page);
			if (this.$route.query.itemsPerPage) this.pagination.itemsPerPage = parseInt(this.$route.query.itemsPerPage);
			if (this.$route.query.query) this.query = this.$route.query.query;

			for (var i = 0; i < this.$data.$filters.length; i++) {
				var filter = this.$data.$filters[i];
				var key = filter.value || filter.name;
				if (this.$route.query[key]) {
					if (filter.multiple || filter.type == "relation_multiple")
						this.$set(filter, "_value", this.$route.query[key].split(","));
					else filter._value = this.$route.query[key];
				}
			}
		},
		setQueryParams() {
			var params = {};
			if (this.pagination.descending) params.descending = `${this.pagination.descending}`;
			if (
				this.pagination.sortBy &&
				this.pagination.sortBy != this.defaultOrder &&
				this.pagination.sortBy != "-" + this.defaultOrder
			)
				params.sortBy = `${this.pagination.sortBy}`;
			if (this.pagination.page && this.pagination.page > 1) params.page = `${this.pagination.page}`;
			if (this.pagination.itemsPerPage && this.perPage != this.pagination.itemsPerPage)
				params.itemsPerPage = `${this.pagination.itemsPerPage}`;
			if (this.query) params.query = this.query;
			for (var i = 0; i < this.$data.$filters.length; i++) {
				var filter = this.$data.$filters[i];
				if (filter._value) {
					if (Array.isArray(filter._value)) params[filter.value || filter.name] = filter._value.join(",");
					else params[filter.value || filter.name] = filter._value;
				}
			}
			if (!isEqual(this.$route.query, params))
				this.$router.replace({
					query: params,
				});
		},
		addItem() {
			this.item = {};
			this.$data.$headers.forEach(async (header) => {
				if (header.readonly !== true && header.default !== undefined) {
					var value = header.default;
					if (typeof header.default == "function") {
						value = await header.default(this.item, header);
					}
					this.$set(this.item, header.field, value);
				}
			});
			this.$emit("add-item", this.item);
			if (this.beforeAdd && this.beforeAdd(this.item)) return;
			this.dialog = true;
		},
		clickItem(item, index) {
			this.$emit("item-clicked", item);
			if (this.crudActions && !this.hideUpdate) {
				if (this.beforeEdit && this.beforeEdit(item, index)) return;
				this.editItem(item, index);
			}
		},
		viewItem(item) {
			this.item = JSON.parse(JSON.stringify(item));
			if (this.beforeView && this.beforeView(this.item)) return;
			this.viewDialog = true;
			this.$emit("item-viewed", item);
		},
		showMenu(item, ev) {
			this.$emit("item-context-menu", { item: item, event: ev });
			this.item = item;
			this.x = ev.clientX;
			this.y = ev.clientY;
			this.$nextTick(() => {
				this.menu = true;
			});
		},
		duplicateItem(item) {
			this.item = JSON.parse(JSON.stringify(item));
			this.item.id = undefined;
			this.$emit("duplicate-item", this.item);
			if (this.beforeDuplicate && this.beforeDuplicate(this.item)) return;
			this.editItem(this.item);
		},
		editItem(item, index) {
			this.item = JSON.parse(JSON.stringify(item));
			this.$emit("edit-item", this.item);
			if (this.beforeEdit && this.beforeEdit(this.item, index)) return;
			this.$data.$headers.forEach((header) => {
				if (header.type == "relation_multiple") {
					if (item[header.entity]) {
						if (item[header.entity].length > 0) {
							var value = item[header.entity].map((o) => o.id);
							this.$set(this.item, header.value, value);
						} else {
							this.$set(this.item, header.value, []);
						}
					} else {
						delete this.item[header.value];
					}
				}
			});
			this.dialog = true;
		},
		confirmDelete(item) {
			this.$emit("delete-item", item);
			this.confirmDeleteDialog = true;
			this.item = item;
		},
		async deleteItem(item) {
			if (this.beforeDelete && this.beforeDelete(item)) return;
			try {
				var resp = await this.api.delete(`${this.resource}/${item.id}`);
				this.$emit("after-delete", resp.data);
				this.api.successful(this.$options.filters.trans("crud.delete_confirmation_title"));
				this.getData();
			} catch (err) {
				this.api.handleError(err);
			}
		},
		async save(item) {
			if (this.beforeSave && this.beforeSave(item)) return;
			var promise;
			var data = {
				id: item.id,
			};
			this.$data.$headers.forEach((header) => {
				if (header.readonly !== true) data[header.field] = item[[header.field]];
			});
			if (data.id) {
				promise = this.api.put(`${this.resource}/${data.id}`, data).then((resp) => {
					this.$emit("after-update", resp.data);
					this.$emit("after-save", resp.data);
				});
			} else {
				promise = this.api.post(`${this.resource}`, data).then((resp) => {
					this.$emit("after-create", resp.data);
					this.$emit("after-save", resp.data);
				});
			}
			try {
				await promise;
				this.getData();
				this.api.successful();
				this.item = null;
				this.cancel(true);
				return promise;
			} catch (err) {
				this.api.handleError(err);
			}
		},
		confirmMultipleDelete(items) {
			this.$emit("delete-item", items);
			this.confirmMultipleDeleteDialog = true;
		},
		async deleteMultipleItems(items) {
			if (this.beforeDelete && this.beforeDelete(items)) return;
			try {
				var resp = await this.api.delete(`${this.resource}?${items.map((i) => `ids[]=${i.id}`).join("&")}`);
				this.$emit("after-delete", resp.data);
				this.api.successful(this.$options.filters.trans("crud.delete_confirmation_title"));
				this.getData();
			} catch (err) {
				this.api.handleError(err);
			}
		},
		cancel(refresh) {
			this.dialog = false;
			this.item = null;
			if (refresh) this.getData();
			this.saving = false;
			this.$emit("cancel-edition");
		},
		exportData(format = "xlsx", simplified = true) {
			let items = [];
			if (simplified) {
				items = this.items.map((item) => {
					var i = {};
					this.$data.$headers.forEach(async (header) => {
						if (header.visible == false || header.editonly) {
							return;
						}
						var key = this.$options.filters.trans(header.text);
						if (header.type == "boolean") {
							i[key] = i[key] ? this.$options.filters.trans("yes") : this.$options.filters.trans("no");
						} else if (header.type == "date") {
							i[key] = item[header.field] ? moment(item[header.field]).format("YYYY-MM-DD") : "--";
						} else if (header.type == "datetime") {
							i[key] = item[header.field] ? moment(item[header.field]).format("YYYY-MM-DD hh:mm:ss A") : "--";
						} else if (header.type == "image" && format == "pdf") {
							IMEX.toDataUrl(i[key]).then((data) => {
								i[key] = data;
							});
						} else if (header.type == "relation_multiple" || (header.type == "relation" && header.multiple)) {
							i[key] = item[header.entity] ? item[header.entity].map((r) => r[header.attribute]).join(",") : "--";
						} else if (header.type == "relation") {
							i[key] = item[header.entity] ? item[header.entity][header.attribute] : "--";
						} else if (header.function === " function") {
							i[key] = header.handler(item, header);
						} else {
							i[key] = get(item, header.field);
						}
					});
					return i;
				});
			} else {
				items = this.items;
			}

			if (typeof this.onExport === "function") items = this.onExport(items, this.$data.$headers, this.items, format);
			console.log(items);

			setTimeout(() => {
				IMEX.export(
					items,
					this.$options.filters.trans(this.label_.substring(0, 30)),
					format,
					this.$options.filters.trans(this.label_.substring(0, 30))
				);
			}, 2000);
		},
	},
	data() {
		return {
			columnStorage: createInstance("column_storage"),
			toLoad: [],
			menu: false,
			x: 0,
			y: 0,
			model: null,
			validation: false,
			preventChanges: false,
			crudActionsVisible: true,
			dialog: false,
			viewDialog: false,
			confirmDeleteDialog: false,
			confirmMultipleDeleteDialog: false,
			filtersShow: false,
			query: "",
			timeout: null,
			loading: false,
			items: [],
			label_: "",
			selected: [],
			$filters: [],
			$headers: [],
			pagination: {
				descending: false,
				page: 1,
				itemsPerPage: 10,
				sortBy: null,
				totalItems: 0,
			},
			footerProps: {
				"items-per-page-all-text": "Todos",
				"items-per-page-options": [5, 10, 25, 50, -1],
				"items-per-page-text": "Por página:",
				"show-current-page": true,
				"show-first-last-page": true,
			},
			item: null,
		};
	},
	props: {
		queryChanges: {
			type: Boolean,
			default: true,
		},
		mode: {
			type: String,
			default: "ajax",
			validator(value) {
				return ["ajax", "local"].indexOf(value) !== -1;
			},
		},
		minimized: {
			type: Boolean,
			default: () => true,
		},
		data: {
			type: Array,
		},
		resource: {
			type: String,
		},
		label: {
			type: String,
		},
		singleLabel: {
			type: String,
		},
		perPage: {
			type: Number,
			default: 10,
		},
		load: {
			type: String,
			default: "",
		},
		append: {
			type: String,
			default: "",
		},
		defaultOrder: {
			type: String,
			default: "-id",
		},
		fullscreen: {
			type: Boolean,
			default: false,
		},
		headers: {
			type: Array,
		},
		filters: {
			type: Array,
		},
		bulkActions: {
			type: Boolean,
			default: false,
		},
		bulkDelete: {
			type: Boolean,
			default: true,
		},
		crudActions: {
			type: Boolean,
			default: true,
		},
		reorderAction: {
			type: Boolean,
			default: false,
		},
		reorderTitle: {
			type: String,
			default: "name",
		},
		reorderDescription: {
			type: String,
			default: "codigo",
		},
		hideCreate: {
			type: Boolean,
			default: false,
		},
		hideUpdate: {
			type: Boolean,
			default: false,
		},
		hideDelete: {
			type: Boolean,
			default: false,
		},
		showClone: {
			type: Boolean,
			default: false,
		},
		createPermission: {
			type: String,
		},
		editPermission: {
			type: String,
		},
		deletePermission: {
			type: String,
		},
		multipleDeletePermission: {
			type: String,
		},
		revisions: {
			type: [String, Boolean],
			default: "SuperAdmin",
		},
		onExport: { type: Function },
		beforeAdd: { type: Function },
		beforeEdit: { type: Function },
		beforeView: { type: Function },
		beforeDuplicate: { type: Function },
		beforeDelete: { type: Function },
		beforeSave: { type: Function },
	},
	watch: {
		selected() {
			this.$emit("input", this.selected);
		},
		data() {
			this.debounceSearch();
		},
	},
};
</script>
<style lang="sass">
	.v-btn
		margin-left: 1px
		margin-right: 1px
.v-data-table.theme--light
	.v-data-cell-header
		color: rgba(0, 0, 0, 0.54)
		&.sortable
			cursor: pointer
			.only-on-hover
				visibility: hidden
			&:hover
				color: inherit
				.only-on-hover
					visibility: visible
.v-data-table.theme--dark
	.v-data-cell-header
		color: rgba(255, 255, 255, 0.54)
		&.sortable
			cursor: pointer
			.only-on-hover
				visibility: hidden
			&:hover
				color: inherit
				.only-on-hover
					visibility: visible
.datatable-crud
	.v-data-table__wrapper
		table
			min-width: 1200px
.icon-arrow
	transition: 300ms ease all !important
	&.rotated
		-webkit-transform: rotate(-180deg)
		transform: rotate(-180deg)
</style>
