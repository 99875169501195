export const validations = {
	code: "required|min:1|max:200|string|unique:App\\ModelsBank,code",
	name: "required|min:1|max:200|string|unique:App\\ModelsBank,name",
};

export const model = {
	model: "bank",
	load: "",
	defaultOrder: "code",
	filters: [],
	headers: [
		{
			value: "name",
			text: "name",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "code",
			text: "code",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "url",
			text: "url",
			required: false,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "extra",
			text: "Extra",
			required: false,
			default: null,
			type: "extra",
			visible: false,
		},
	],
};

export default {
	...model,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return {
			...h,
			rules,
		};
	}),
};
