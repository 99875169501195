import moment from "moment";
export const validations = {
	concept_id: "required|exists:concepts,id",
	type: "required,in:devengado,deducible",
	value_type: "required,in:monetary,percentage",
	description: "required|min:3|max:500",
	value: "required|numeric|min:0",
	quantity: "required|numeric|min:0",
	is_salarial: "boolean",
	entidad_id: "nullable|exists:entidades,id",
	cliente_id: "nullable|exists:clientes,id",
	worker_id: "nullable|exists:workers,id",
	date: "date|required",
	end_date: "date|nullable",
};

export const model = {
	model: "worker",
	load: "cliente,wo",
	order: "-date",
	filters: [
		{
			name: "worker_id",
			label: "workers",
			type: "relation_multiple",
			resource: "workers",
			handler: (v) => (v.length ? `whereIn[worker_id]=${v.join(",")}` : ""),
			searchLogic: (v, i) => `${i.cliente}` == `${v}`,
			can: "staff",
		},
		{
			name: "type",
			label: "type",
			type: "select",
			attribute: "text",
			translate: true,
			multiple: true,
			hideDetails: true,
			handler: (v) => (v.length ? `whereIn[type]=${v.join(",")}` : ""),
			searchLogic: (v, i) => `${i.status}` == `${v}`,
			options: [
				{ value: "devengado", text: "devengado" },
				{ value: "deducible", text: "deducible" },
			],
		},
		{
			name: "from",
			label: "date.from",
			type: "date",
			attribute: "date",
			hideDetails: true,
			handler: (v) => `from=${moment(v).format("YYYY-MM-DD")}`,
			searchLogic: (v, i) => moment(v).isAfter(moment(i.date)),
		},
		{
			name: "to",
			label: "date.to",
			type: "date",
			attribute: "date",
			hideDetails: true,
			handler: (v) => `to=${moment(v).add(1, "d").format("YYYY-MM-DD")}`,
			searchLogic: (v, i) => moment(v).add(1, "d").isBefore(moment(i.date)),
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			readonly: true,
		},
		{
			text: "worker",
			value: "worker_id",
			type: "relation",
			resource: "workers?scope[actives]=1",
			attribute: "full_name",
			entity: "worker",
			relation: "worker",
			description: "description",
			tab: "novelty",
		},
		{
			text: "concept",
			value: "_concept",
			type: "component",
			component: "concept-selector",
			tab: "novelty",
			editonly: true,
		},
		{
			text: "concept",
			readonly: true,
			value: "concept",
			type: "relation",
			resource: "concepts",
			attribute: "name",
			entity: "concept",
			component: "concept-selector",
			tab: "novelty",
		},
		{
			text: "description",
			value: "description",
			type: "text",
			tab: "novelty",
			readonly: true,
		},
		{
			text: "value",
			value: "value",
			type: "currency",
			min: 1,
			default: 1,
			step: "any",
			tab: "novelty",
		},
		{
			text: "quantity",
			value: "quantity",
			type: "number",
			min: 1,
			default: 1,
			step: 1,
			tab: "novelty",
		},
		{
			text: "is salarial",
			value: "is_salarial",
			type: "boolean",
			tab: "novelty",
		},
		{
			text: "date",
			value: "date",
			type: "date",
			tab: "novelty",
			default: moment().format("YYYY-MM-DD"),
		},
		{
			text: "date.end_time",
			value: "end_date",
			type: "date",
			tab: "novelty",
			readonly: true,
			visible: false,
			default: moment().format("YYYY-MM-DD"),
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
