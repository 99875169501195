import staticTables from "@/../public/staticTables.json";

export const validations = {
	first_name: "required|min:3|max:50",
	last_name: "required|min:3|max:50",
	document: "required",
	email: "required|email",
	phone_number: "phone",
	payment_type: "required",
	address: "",
	birthday: "date|before:now",
	salary: "numeric",
	status: "required",
	admission_date: "nullable|date",
	egress_date: "nullable|date",
	estado: "boolean",
	tipo_trabajador: "required|exists:dian_tipotrabajador,code",
	tipo_identificacion: "required|exists:dian_docidentificacion,codigo",
	medio_pago: "required|exists:dian_mediospago,codigo",
	subtipo_trabajador: "required|exists:dian_subtipotrabajador,codigo",
	nombre_banco: "nullable",
	tipo_cuenta: "nullable|exists:dian_tipocuenta,name",
	numero_cuenta: "nullable",
	departamento: "required_if:pais,CO|exists:dian_departamentos,code",
	ciudad: "required_if:pais,CO|exists:dian_municipios,code",
	entidad_id: "nullable|exists:entidades,id",
	cliente_id: "nullable|exists:clientes,id",
	horario_id: "nullable|exists:horarios,id",
	puesto_id: "nullable|exists:puestos,id",
	location: "nullable|array",
	extra: "nullable|array",
	imagen: "nullable",

	eps_id: "nullable|exists:master.eps,id",
	arl_id: "nullable|exists:master.arl,id",
	pension_id: "nullable|exists:master.fondosDePensiones,id",
	cesantia_id: "nullable|exists:master.cesantias,id",
	caja_compensacion_id: "nullable|exists:master.cajas_de_compensacion,id",
};

export const model = {
	model: "worker",
	load: "cliente,entidad",
	order: "first_name",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation_multiple",
			resource: "entidades",
			handler: (v) => (v.length ? `whereIn[entidad_id]=${v.join(",")}` : ""),
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
		{
			name: "cliente_id",
			label: "client",
			type: "relation_multiple",
			resource: "clientes",
			handler: (v) => (v.length ? `whereIn[cliente_id]=${v.join(",")}` : ""),
			searchLogic: (v, i) => `${i.cliente}` == `${v}`,
			can: "staff",
		},
		{
			name: "status",
			label: "status",
			type: "select",
			attribute: "text",
			translate: true,
			multiple: true,
			hideDetails: true,
			handler: (v) => (v.length ? `whereIn[status]=${v.join(",")}` : ""),
			searchLogic: (v, i) => `${i.status}` == `${v}`,
			options: [
				{ value: "active", text: "Activo" },
				{ value: "ausent", text: "Ausente" },
				{ value: "disabled", text: "Incapacitado" },
				{ value: "license", text: "Licencias" },
				{ value: "vacations", text: "Vacaciones" },
				{ value: "quarantine", text: "Cuarentena" },
				{ value: "allow", text: "Permiso" },
				{ value: "inactive", text: "Inactivo" },
			],
		},
		{
			name: "active",
			label: "active",
			type: "boolean",
			handler: () => `where[active]=1`,
			searchLogic: (v, i) => i.active,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			readonly: true,
		},
		{
			text: "name",
			value: "full_name",
			readonly: true,
		},
		{
			value: "first_name",
			text: "first_name",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "middle_name",
			text: "middle_name",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "last_name",
			text: "last_name",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			text: "Tipo Identificacion",
			value: "tipo_identificacion",
			visible: false,
			default: "13",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["dian_docidentificacion"],
			tab: "worker",
		},
		{
			value: "document",
			text: "document",
			type: "text",
			tab: "worker",
		},
		{
			text: "Tipo Trabajador",
			value: "tipo_trabajador",
			visible: false,
			default: "01",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["dian_tipotrabajador"],
			tab: "worker",
		},
		{
			text: "Subtipo Trabajador",
			value: "subtipo_trabajador",
			visible: false,
			default: "00",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["dian_subtipotrabajador"],
			tab: "worker",
		},
		{
			value: "payment_type",
			text: "payment type",
			type: "select",
			options: [
				{ value: "monthly", text: "Mensual" },
				{ value: "biweekly", text: "Quincenal" },
				{ value: "weekly", text: "Semanal" },
			],
			default: "monthly",
			translate: true,
			tab: "worker",
		},
		{
			value: "address",
			text: "address",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "phone_number",
			text: "phone_number",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "email",
			text: "email",
			type: "email",
			tab: "worker",
		},
		{
			text: "Medio de Pago",
			value: "medio_pago",
			visible: false,
			default: "31",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["dian_mediospago"],
			tab: "others",
		},
		{
			text: "Tipo Contrato",
			value: "tipo_contrato",
			visible: false,
			default: "1",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["dian_tipocontrato"],
			tab: "others",
		},
		{
			text: "Nombre del Banco",
			value: "nombre_banco",
			visible: false,
			type: "text",
			tab: "others",
		},
		{
			text: "Tipo de Cuenta",
			value: "tipo_cuenta",
			visible: false,
			type: "select",
			attribute: "name",
			trackBy: "name",
			default: "Ahorros",
			options: staticTables["dian_tipocuenta"],
			tab: "others",
		},
		{
			text: "Numero de Cuenta",
			value: "numero_cuenta",
			visible: false,
			type: "text",
			tab: "others",
		},
		{
			text: "Departamento",
			value: "departamento",
			visible: false,
			type: "select",
			attribute: "name",
			trackBy: "code",
			default: "11",
			options: staticTables["dian_departamentos"],
			tab: "others",
		},
		{
			text: "Municipio o Ciudad",
			value: "ciudad",
			visible: false,
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			default: "11001",
			options: (i) => staticTables["dian_municipios"].filter((o) => o.codigo.startsWith(i.departamento)),
			tab: "others",
		},
		{
			value: "birthday",
			text: "birthday",
			type: "date",
			visible: false,
			tab: "worker",
		},
		{
			value: "salary",
			text: "salary",
			type: "number",
			visible: true,
			default: 0,
			tab: "worker",
		},
		{
			value: "status",
			text: "status",
			type: "select",
			default: "active",
			attribute: "text",
			translate: true,
			rules: "required",
			options: [
				{ value: "active", text: "Activo" },
				{ value: "ausent", text: "Ausente" },
				{ value: "disabled", text: "Incapacitado" },
				{ value: "license", text: "Licencias" },
				{ value: "vacations", text: "Vacaciones" },
				{ value: "quarantine", text: "Cuarentena" },
				{ value: "allow", text: "Permiso" },
				{ value: "inactive", text: "Inactivo" },
			],
			tab: "worker",
		},
		{
			value: "notes",
			text: "notes",
			visible: false,
			type: "textarea",
			tab: "worker",
		},
		{
			value: "active",
			text: "active",
			type: "checkbox",
			default: "1",
			tab: "worker",
		},
		{
			value: "code",
			text: "code",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "charge",
			text: "charge",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "department",
			text: "department",
			type: "text",
			visible: false,
			tab: "worker",
		},
		{
			value: "gender",
			text: "gender",
			visible: false,
			type: "select",
			options: ["masculino", "femenino", "no binario"],
			default: "masculino",
			tab: "worker",
		},
		{
			visible: false,
			value: "admission_date",
			text: "admission_date",
			type: "date",
			tab: "worker",
		},
		{
			visible: false,
			value: "egress_date",
			text: "egress_date",
			type: "date",
			tab: "worker",
		},
		{
			value: "entidad_id",
			text: "entity",
			type: "relation",
			entity: "Entidad",
			resource: "entidades",
			attribute: "full_name",
			description: "codigo",
			action: "location",
			tab: "others",
			visible: false,
		},

		{
			visible: false,
			value: "location",
			text: "location",
			type: "location",
			tab: "others",
			fullWidth: true,
		},
		{
			visible: false,
			value: "extra",
			text: "extra",
			type: "extra",
			tab: "others",
			fullWidth: true,
		},
		{
			text: "Tipo de Riesgo",
			value: "tipo_riesgo",
			type: "select",
			options: [
				{ value: "0.522", text: "Riesgo 1" },
				{ value: "1.044", text: "Riesgo 2" },
				{ value: "2.436", text: "Riesgo 3" },
				{ value: "4.350", text: "Riesgo 4" },
			],
			tab: "worker",
			visible: false,
		},
		{
			text: "Arl",
			value: "arl_id",
			type: "relation",
			entity: "Arl",
			resource: "arl",
			attribute: "name",
			description: "document",
			action: "code",
			tab: "worker",
			visible: false,
		},
		{
			text: "Eps",
			value: "eps_id",
			type: "relation",
			entity: "Eps",
			resource: "eps",
			attribute: "name",
			description: "document",
			action: "code",
			tab: "worker",
			visible: false,
		},
		{
			text: "Fondo de Pensiones",
			value: "pension_id",
			type: "relation",
			entity: "FondoDePension",
			resource: "fondos_de_pension",
			attribute: "name",
			description: "document",
			action: "code",
			tab: "worker",
			visible: false,
		},
		{
			text: "Fondo de Cesantías",
			value: "cesantia_id",
			type: "relation",
			entity: "cesantia",
			resource: "cesantias",
			attribute: "name",
			description: "document",
			action: "code",
			tab: "worker",
			visible: false,
		},
		{
			text: "Caja de Compensación",
			value: "caja_compensacion_id",
			type: "relation",
			entity: "cajaCompensacion",
			resource: "caja_de_compensacion",
			attribute: "name",
			description: "document",
			action: "code",
			tab: "worker",
			visible: false,
		},

		{
			value: "image_url",
			field: "imagen",
			text: "image",
			type: "image",
			upload: true,
			crop: true,
			aspect_ratio: 1,
			tab: "worker",
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
