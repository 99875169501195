export const validations = {
	nombre: "required|min:2|max:50",
	localizacion: "nullable",
	descripcion: "nullable",
	entidad_id: "nullable|exists:\\App\\Models\\Entidad:id",
	producto_id: "nullable|exists:\\App\\Models\\Producto:id",
};
export const model = {
	model: "puesto",
	load: "entidad,producto",
	order: "id",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
	],
	headers: [
		{
			value: "nombre",
			text: "name",
			type: "text",
			tab: "job place",
		},
		{
			value: "localizacion",
			text: "location",
			type: "text",
			tab: "job place",
		},
		{
			value: "descripcion",
			text: "description",
			type: "textarea",
			tab: "job place",
		},
		{
			value: "entidad_id",
			text: "entity",
			type: "relation",
			entity: "entidad",
			attribute: "full_name",
			resource: "entidades",
			tab: "job place",
		},
		{
			value: "producto_id",
			text: "product",
			type: "relation",
			entity: "producto",
			resource: "productos",
			attribute: "full_name",
			tab: "job place",
		},
	],
};

export default {
	load: "entidad,producto",
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
