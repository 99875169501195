import Vue from "vue";
import settings from "@/services/settings";
import api from "@/services/api";
import get from "lodash/get";
function trunc(text = "", length = 50, suffix = "...") {
	if (!text) return "";
	if (`${text}`.length > length) return `${text}`.substring(0, length) + suffix;
	return text;
}

function money(value, currency_symbol = null, decimals = null) {
	if (value == undefined) return "--";

	if (!decimals) decimals = settings.decimals;
	if (decimals === null) decimals = 0;
	if (!currency_symbol) currency_symbol = settings.currency_symbol;
	if (!currency_symbol) currency_symbol = "$";

	return `${currency_symbol}${Number(value)
		.toFixed(decimals)
		.replace(".", ",")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
}

Vue.filter("dur", (totalSeconds) => {
	var hours = Math.floor(totalSeconds / 3600)
		.toString()
		.padStart(2, "0");
	totalSeconds %= 3600;
	var minutes = Math.floor(totalSeconds / 60)
		.toString()
		.padStart(2, "0");
	var seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${hours}:${minutes}:${seconds}`;
});

Vue.filter("numeric", (value, decimals = "auto") => {
	if (value == undefined) return "--";
	if (decimals == "auto") {
		if (parseInt(value) == parseFloat(value)) {
			return Number(value).toFixed(0);
		} else {
			decimals = settings.decimals;
			return Number(value).toFixed(decimals);
		}
	} else if (decimals > 0) {
		return Number(value).toFixed(decimals);
	}
});

Vue.filter("money", money);

Vue.filter("extra", (item, key, placeholder = "--", opts = { resource: "extra", key: "key", val: "value" }) => {
	if (!item[opts.resource] || !Array.isArray(item[opts.resource]) || item[opts.resource].length == 0)
		return placeholder;
	var val = item[opts.resource].find((e) => {
		return e[opts.key] == key;
	});
	if (val) return val[opts.val];
	return placeholder;
});

Vue.filter("ellipsed", trunc);

Vue.filter("truncate", trunc);

Vue.filter("pluck", function (objects, key = "full_name") {
	return objects.map((object) => {
		return object[key];
	});
});

Vue.filter("get", (object, path, defaultValue) => {
	return get(object, path, defaultValue);
});

Vue.filter("model", (model) => {
	if (!model) return "--";
	return (
		model.full_name ||
		model.name ||
		model.nombre ||
		model.full_name_cedula ||
		model.title ||
		model.titulo ||
		JSON.stringify(model)
	);
});

Vue.filter("setting", (key, defaultValue = null) => {
	if (api.objects.settings && api.objects.settings.collection[key] && api.objects.settings.collection[key].value)
		return api.objects.settings.collection[key].value;
	return defaultValue;
});

Vue.filter("payment", (val, total = 0) => {
	if (!val) return "--";
	if (typeof val == "string")
		var payments = val.split(",").map((s) => {
			return {
				method: s.split(":")[0],
				amount: parseFloat(s.split(":")[1] || 0),
			};
		});
	else payments = val;

	var txt = payments
		.map((t) => {
			return `${t.method}: ${money(t.amount)}`;
		})
		.join("<br />");

	var acum = payments.reduce((s, t) => s + t.amount, 0);
	if (total > 0 && Math.floor(total) < acum) {
		txt += `<br /> ${Vue.filter("trans")("finances.change")}: ${money(acum - total)}`;
	}
	return txt;
});

Vue.filter("characteristic", (model, index = null) => {
	if (!model.advanced_stock || index === null) {
		return "";
	}
	return Object.keys(model.advanced_stock[index])
		.filter((k) => k != "stock")
		.map((k) => `${k}: ${model.advanced_stock[index][k]}`)
		.join(",");
});
