import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":"","align-baseline":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c('label',{staticClass:"subhead"},[_vm._v(_vm._s(_vm._f("trans")(_vm.label)))])]),_vm._l((_vm.extra),function(field,index){return [_c(VFlex,{key:`key-${index}`,staticClass:"pa-1",attrs:{"xs5":""}},[_c(VTextField,{attrs:{"outlined":_vm.outlined,"filled":_vm.filled,"dense":_vm.dense,"rounded":_vm.dense,"solo":_vm.solo,"label":_vm._f("trans")('key'),"change":_vm.$emit('input', _vm.extra)},model:{value:(field.key),callback:function ($$v) {_vm.$set(field, "key", $$v)},expression:"field.key"}})],1),_c(VFlex,{key:`value-${index}`,staticClass:"pa-1",attrs:{"xs6":""}},[_c(VTextField,{attrs:{"outlined":_vm.outlined,"filled":_vm.filled,"dense":_vm.dense,"rounded":_vm.rounded,"solo":_vm.solo,"label":_vm._f("trans")('value'),"change":_vm.$emit('input', _vm.extra)},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1),((!_vm.min || _vm.extra.length > _vm.min) && (!_vm.max || _vm.extra.length < _vm.max))?_c(VFlex,{key:`remove-${index}`,attrs:{"shrink":""}},[_c(VBtn,{attrs:{"small":"","text":"","icon":"","color":"red"},on:{"click":function($event){_vm.extra.splice(index, 1);
							_vm.$emit('input', _vm.extra);}}},[_c(VIcon,[_vm._v("remove_circle")])],1)],1):_vm._e()]}),_c(VFlex,{staticClass:"text-right",attrs:{"xs12":""}},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.extra.push({ key: '', value: '' });
						_vm.$emit('input', _vm.extra);}}},[_c(VIcon,[_vm._v("add")])],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }