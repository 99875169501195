import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export const primary_color = colors.green.base;

export default new Vuetify({
	icons: {
		iconfont: "mdi",
	},
	theme: {
		themes: {
			light: { primary: primary_color },
			dark: { primary: primary_color },
		},
	},
});
