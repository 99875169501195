import moment from "moment";

export const pages = [
	{
		name: "Dashboard",
		icon: "mdi-view-dashboard",
		to: "/",
	},
	{
		name: "reports",
		icon: "mdi-file-chart",
		to: "/reports",
		modulo: "reportes",
		can: ["Administrar Reportes", "Ver Reportes", "Agregar Reportes", "Editar Reportes", "Eliminar Reportes"],
	},
	{
		name: "calendar",
		icon: "mdi-calendar",
		to: "/calendar",
		modulo: "calendario",
		can: ["SuperAdmin"],
	},
	{
		type: "divider",
	},
	{
		name: "Admin",
		type: "header",
	},
	{
		name: "Nomina",
		icon: "mdi-file",
		to: `/nomina/${moment().year()}/${moment().month() + 1}`,
		can: ["Administrar Nomina", "Ver Nomina", "Agregar Nomina", "Editar Nomina", "Eliminar Nomina"],
	},

	//CRUD
	{
		name: "users",
		icon: "mdi-account-circle",
		to: "/users",
		can: ["Administrar Usuarios", "Ver Usuarios", "Agregar Usuarios", "Editar Usuarios", "Eliminar Usuarios"],
	},
	{
		name: "clients",
		icon: "mdi-account-multiple",
		to: "/clients",
		can: ["staff"],
	},
	// {
	// 	name: "roles",
	// 	type: "group",
	// 	icon: "mdi-shield-account",
	// 	to: "/roles",
	// 	can: "staff",
	// 	pages: [
	// 		{
	// 			name: "roles",
	// 			icon: "mdi-shield-account",
	// 			to: "/roles",
	// 			can: "staff",
	// 		},
	// 		{
	// 			name: "permissions",
	// 			icon: "mdi-account-lock",
	// 			to: "/permissions",
	// 			can: "staff",
	// 		},
	// 	],
	// },
	{
		name: "entities",
		icon: "mdi-map",
		to: "/entities",
		modulo: "entidades",
		can: ["Administrar Entidades", "Ver Entidades", "Agregar Entidades", "Editar Entidades", "Eliminar Entidades"],
	},
	{
		name: "workers",
		to: "/workers",
		icon: "mdi-account-hard-hat",
		can: [
			"Administrar Trabajadores",
			"Ver Trabajadores",
			"Agregar Trabajadores",
			"Editar Trabajadores",
			"Eliminar Trabajadores",
		],
	},
	{
		name: "concepts",
		to: "/concepts",
		icon: "mdi-list-status",
		can: ["Administrar Conceptos", "Ver Conceptos", "Agregar Conceptos", "Editar Conceptos", "Eliminar Conceptos"],
	},
	{
		name: "novelties",
		to: "/novelties",
		icon: "mdi-emoticon-sick",
		can: ["Administrar Novedades", "Ver Novedades", "Agregar Novedades", "Editar Novedades", "Eliminar Novedades"],
	},
	{
		name: "Facturación",
		to: "/facturación",
		icon: "mdi-file",
		external: true,
		handler() {
			window.location.href = "https://facturacion.eycproveedores.com"
		}
	},
	// Others
	// {
	// 	name: "advanced",
	// 	icon: "mdi-lock-outline",
	// 	type: "group",
	// 	can: "SuperAdmin",
	// 	pages: [
	// 		{
	// 			name: "translations",
	// 			icon: "mdi-earth",
	// 			to: "/translations",
	// 			can: "staff",
	// 		},
	// 		{
	// 			name: "Log Viewer",
	// 			icon: "mdi-information-outline",
	// 			to: "/logs",
	// 			can: "staff",
	// 		},
	// 	],
	// },
	{
		name: "settings",
		icon: "mdi-cog",
		to: "/settings",
	},
	{
		name: "about",
		icon: "mdi-information-outline",
		to: "/about",
	},
];
