// Initialization Imports
import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { SplashScreen } from "@capacitor/splash-screen";
// Plugins
import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);
Vue.mixin(Vue2Filters.mixin);

import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/es-us";
Vue.use(VueMoment, { moment });
import "@/directives/newtonDirectives";

import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
Vue.use(VueVirtualScroller);

import "./services/filters";

// Newton Components
import Api from "./services/api";
import { api as dataApi } from "./services/api";
Vue.mixin(Api);

import Toolbar from "./services/toolbar";
Vue.mixin(Toolbar);

import Settings from "./services/settings";
Vue.mixin(Settings);

import trans from "./services/trans";
Vue.filter("trans", trans);

import ImageOverlay from "./components/main/ImageOverlay";
Vue.component("image-overlay", ImageOverlay);

import ItemSelector from "./components/main/ItemSelector";
Vue.component("item-selector", ItemSelector);

import Signature from "./components/main/Signature";
Vue.component("signature", Signature);

import ImageInput from "./components/main/ImageInput";
Vue.component("image-input", ImageInput);

import Crud from "./components/main/Crud";
import vuetify from "./plugins/vuetify";
Vue.component("crud", Crud);

import ItemsReorder from "./components/main/ItemsReorder";
Vue.component("ItemsReorder", ItemsReorder);

function addLivechat() {
	var s1 = document.createElement("script"),
		s0 = document.getElementsByTagName("script")[0];
	s1.async = true;
	s1.src = "https://embed.tawk.to/5f52da244704467e89ec5276/default";
	s1.charset = "UTF-8";
	s1.setAttribute("crossorigin", "*");
	s0.parentNode.insertBefore(s1, s0);
	setTimeout(() => {
		window.Tawk_API.visitor = {
			name: dataApi.user ? dataApi.user.full_name : "Visitante",
			email: dataApi.user ? dataApi.user.email : null,
		};
		window.Tawk_API.setAttributes(
			{
				name: dataApi.user ? `${dataApi.user.full_name}` : "Visitante",
				email: dataApi.user ? dataApi.user.email : null,
			},
			function (error) {
				console.error(error);
			}
		);
	}, 1000 * 45);
}

setTimeout(() => {
	addLivechat();
}, 1000 * 30);

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	mounted() {
		SplashScreen.hide();
		defineCustomElements(window);
	},
	render: (h) => h(App),
}).$mount("#app");
