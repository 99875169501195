export const validations = {
	name: "nullable",
	text: "nullable",
	color: "nullable",
	url: "nullable",
	image: "nullable|file",
	entidad_id: "nullable|exists:\\App\\Models\\Entidad,id",
	puesto_id: "nullable|exists:\\App\\Models\\Puesto,id",
	company_id: "nullable|exists:\\App\\Models\\Company,id",
	cliente_id: "nullable|exists:\\App\\Models\\Cliente,id",
	role_id: "nullable|exists:\\Spatie\\Permission\\Models\\Role,id",
};

export const model = {
	model: "slider",
	load: "cliente,entidad,puesto,company,role",
	defaultOrder: "id",
	filters: [
		{
			name: "cliente_id",
			label: "client",
			type: "relation",
			resource: "clientes",
			ajax: true,
			handler: (v) => `whereIn[cliente_id]=${v}`,
			searchLogic: (v, i) => `${i.cliente_id}` == `${v}`,
		},
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
		{
			name: "puesto_id",
			label: "job place",
			type: "relation",
			resource: "puestos",
			title: "descripcion",
			handler: (v) => `whereIn[puesto_id]=${v}`,
			searchLogic: (v, i) => `${i.puesto_id}` == `${v}`,
		},
		{
			name: "company_id",
			label: "company",
			type: "relation",
			resource: "companies",
			title: "descripcion",
			handler: (v) => `whereIn[company]=${v}`,
			searchLogic: (v, i) => `${i.company}` == `${v}`,
		},
		{
			name: "role_id",
			label: "role",
			type: "relation",
			resource: "roles",
			handler: (v) => `whereIn[role_id]=${v}`,
			searchLogic: (v, i) => `${i.role_id}` == `${v}`,
		},
	],
	headers: [
		{
			value: "name",
			text: "name",
			required: false,
			default: null,
			type: "text",
			visible: true,
			tab: "slider",
			fullWidth: true,
		},
		{
			value: "text",
			text: "text",
			required: false,
			default: null,
			type: "textarea",
			visible: true,
			tab: "slider",
			fullWidth: true,
		},
		{
			value: "color",
			text: "color",
			required: true,
			default: "white",
			type: "color",
			visible: true,
			tab: "slider",
			fullWidth: true,
		},
		{
			value: "url",
			text: "url",
			required: false,
			default: null,
			type: "text",
			visible: true,
			tab: "slider",
			fullWidth: true,
		},
		{
			text: "image",
			value: "image_url",
			placeholder: "image_url",
			field: "imagen",
			visible: true,
			type: "image",
			tab: "slider",
			fullWidth: true,
		},
		{
			text: "entity",
			value: "entidad_id",
			visible: true,
			entity: "entidad",
			resource: "entidades",
			align: "center",
			type: "relation",
			modulo: "entidades",
			attribute: "full_name",
			tab: "slider",
			sortable: false,
		},
		{
			text: "job place",
			value: "puesto_id",
			visible: true,
			entity: "puesto",
			modulo: "tablas",
			resource: "puestos",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "slider",
			sortable: false,
		},
		{
			text: "client",
			value: "cliente_id",
			visible: true,
			ajax: true,
			entity: "cliente",
			resource: "clientes",
			align: "center",
			type: "relation",
			modulo: "pedidos",
			attribute: "full_name",
			tab: "slider",
			sortable: false,
		},
		{
			text: "role",
			value: "role_id",
			visible: true,
			entity: "role",
			resource: "roles",
			align: "center",
			type: "relation",
			modulo: "pedidos",
			attribute: "name",
			tab: "slider",
			sortable: false,
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
