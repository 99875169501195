<template>
	<v-container class="pa-0" fill-height layout column wrap>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-list two-line style="width: 100%">
			<template v-for="not in notifications">
				<v-list-item
					@dblclick="deleteNoti(not)"
					:key="not.id"
					:light="!not.read_at"
					:class="{ 'grey lighten-3': !not.read_at }"
					@click="clickNotification(not)"
				>
					<v-list-item-content>
						<v-list-item-title>{{ not.titulo }}</v-list-item-title>
						<v-list-item-subtitle>{{ not.mensaje }}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-icon v-if="not.read_at">notifications_none</v-icon>
						<v-icon v-else>notifications</v-icon>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="not.id + '-divider'"></v-divider>
			</template>
		</v-list>

		<v-layout
			column
			fill-height
			justify-center
			align-center
			align-content-center
			v-if="!loading && notifications.length == 0"
		>
			<v-flex xs12 align-self-center class="text-center">
				<v-icon style="font-size: 100px" color="grey lighten-2" x-large>mdi-bell-sleep</v-icon>
				<div class="display-1 text-capitalize pa-3">
					{{ "no notifications" | trans }}
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
export default {
	mounted() {
		this.loading = true;
		this.api
			.get("notifications?limit=50&order=-created_at")
			.then((resp) => {
				this.notifications = resp.data;
				this.setUnreadCount();
				this.loading = false;
			})
			.catch((err) => {
				this.api.handleError(err);
				this.loading = false;
			});
	},
	data() {
		return {
			notifications: [],
			loading: false,
		};
	},
	methods: {
		read(not) {
			this.api.get(`notificacion/${not.id}/leida`).then((resp) => {
				not = Object.assign(not, resp.data);
				this.setUnreadCount();
			});
		},
		unread(not) {
			this.api.get(`notificacion/${not.id}/noleida`).then((resp) => {
				not = Object.assign(not, resp.data);
				this.setUnreadCount();
			});
		},
		clickNotification(not) {
			if (not.data.ticket_id && this.api.modulos.tickets) {
				this.$router.push(
					`/tickets/${not.data.ticket_id}${not.data.comentario_id ? `?comentarioId=${not.data.comentario_id}` : ""}`
				);
				this.$emit("close");
			}
			if (!not.read_at) this.read(not);
		},
		setUnreadCount() {
			var count = this.notifications.filter((n) => !n.read_at).length;
			this.$set(this.api.user, "notificaciones_count", count);
			this.api.storage.set("user", this.api.user);
		},
		deleteNoti(not) {
			this.notifications = this.notifications.filter((n) => n.id != not.id);
			this.api.delete(`notificacion/${not.id}/delete`, {}).then((resp) => {
				this.setUnreadCount();
				this.api.successful(resp);
			});
		},
	},
};
</script>
