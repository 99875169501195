import moment from "moment";
export const validations = {
	numero_pedido: "required|unique:\\App\\Models\\Pedido,numero_pedido,NULL,id,deleted_at,NULL",
	cliente_id: "required|exists:\\App\\Models\\Cliente:id",
	entidad_id: "nullable|exists:\\App\\Models\\Entidad:id",
	user_id: "nullable|exists:\\App\\Models\\User:id",
	seller_id: "nullable|exists:\\App\\Models\\User:id",
	bodega_id: "nullable|exists:\\App\\Models\\Bodega:id",
	conductor_id: "nullable|exists:\\App\\Models\\Conductor:id",
	conductor2_id: "nullable|exists:\\App\\Models\\Conductor:id",
	vehiculo_id: "nullable|exists:\\App\\Models\\Vehiculo:id",
	orden_compra: "nullable|string",
	pago: "nullable|string",
	fecha_pedido: "required|date",
	fecha_envio: "nullable|date",
	fecha_entrega: "nullable|date",
	direccion_envio: "required|string",
	direccion_destino: "nullable|string",
	direccion_facturado: "nullable|string",
	estado: "required|string",
	tipo: "nullable|string",
	category: "nullable|string",
	transporte: "nullable|string",
	notas: "nullable|string",
	facturar: "boolean",
	location: "array|nullable",
	extra: "array|nullable",
	items: "array|required|min:1",
};

export const states = [
	{ value: "pedido", text: "Pedido" },
	{ value: "revisado", text: "Revisado" },
	{ value: "aprobado", text: "Aprobado" },
	{ value: "parcialmente entregado", text: "Parcialmente Entregado" },
	{ value: "entregado", text: "Entregado" },
	{ value: "rechazado", text: "Rechazado" },
	{ value: "cancelado", text: "Cancelado" },
	{ value: "pagado", text: "Pagado" },
];

export const model = {
	model: "pedido",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `whereDategte[fecha_pedido]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha_pedido) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `whereDatelwe[fecha_pedido]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha_pedido) < moment(v),
		},
		{
			name: "estado",
			label: "status",
			type: "select",
			options: [
				{
					text: "Pedido",
					value: "Pedido",
				},
				{
					text: "Enviado",
					value: "Enviado",
				},
				{
					text: "Despachado",
					value: "Despachado",
				},
				{
					text: "Entregado",
					value: "Entregado",
				},
				{
					text: "Pagado",
					value: "Pagado",
				},
				{
					text: "Anulado",
					value: "Anulado",
				},
				{
					text: "Cancelado",
					value: "Cancelado",
				},
				{
					text: "Reembolso",
					value: "Reembolso",
				},
				{
					text: "Error en el Pago",
					value: "Error en el Pago",
				},
				{
					text: "Retrasado",
					value: "Retrasado",
				},
				{
					text: "Parcialmente Enviado",
					value: "Parcialmente Enviado",
				},
				{
					text: "Desconocido",
					value: "Desconocido",
				},
				{
					text: "Por Confirmar",
					value: "Por Confirmar",
				},
				{
					text: "Revisión",
					value: "Revisión",
				},
			],
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[estado]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.estado) > -1;
				return true;
			},
		},
		{
			name: "tipo",
			label: "type",
			chip: true,
			type: "select",
			options: [
				{ text: "Pedido", value: "pedido" },
				{ text: "Prueba", value: "prueba" },
				{ text: "Servicio", value: "servicio" },
			],
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[tipo]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.tipo) > -1;
			},
		},
		{
			name: "cliente_id",
			label: "clients",
			type: "relation",
			resource: "clientes",
			handler: (v) => `whereIn[cliente_id]=${v}`,
			searchLogic: (v, i) => `${i.cliente_id}` == `${v}`,
		},
		{
			name: "user_id",
			label: "users",
			type: "relation",
			resource: "users",
			subtitle: "document",
			handler: (v) => `whereIn[user_id]=${v}`,
			searchLogic: (v, i) => `${i.user_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			type: "number",
			readonly: true,
			tab: "order",
		},
		{
			text: "order number",
			value: "numero_pedido",
			type: "text",
			tab: "order",
		},
		{
			text: "client",
			value: "cliente_id",
			action: "estado",
			visible: true,
			attribute: "full_name",
			type: "relation",
			entity: "cliente",
			resource: "clientes",
			action2: "null",
			tab: "order",
			rules: "required",
		},
		{
			text: "orden compra",
			value: "orden_compra",
			type: "text",
			tab: "order",
		},
		{
			text: "payment method",
			value: "pago",
			type: "text",
			default: "Crédito",
			tab: "order",
		},
		{
			text: "order date",
			value: "fecha_pedido",
			type: "date",
			default: () => moment().format("YYYY-MM-DD"),
			format: "LL",
			rules: "required",
			tab: "order",
		},
		{
			text: "shipping date",
			value: "fecha_envio",
			visible: false,
			format: "LL",
			type: "date",
			tab: "order",
		},
		{
			text: "delivery date",
			value: "fecha_entrega",
			format: "LL",
			visible: false,
			type: "date",
			tab: "order",
		},
		{
			value: "direccion_envio",
			text: "shipping address",
			type: "textarea",
			visible: false,
			rules: "required",
			tab: "order",
		},
		{
			value: "direccion_destino",
			text: "delivery address",
			visible: false,
			type: "textarea",
			tab: "order",
		},
		{
			value: "direccion_facturado",
			text: "billing address",
			visible: false,
			type: "textarea",
			tab: "order",
		},
		{
			text: "status",
			value: "estado",
			type: "select",
			chip: true,
			default: "Pedido",
			options: [
				{
					text: "Pedido",
					value: "Pedido",
				},
				{
					text: "Enviado",
					value: "Enviado",
				},
				{
					text: "Despachado",
					value: "Despachado",
				},
				{
					text: "Entregado",
					value: "Entregado",
				},
				{
					text: "Pagado",
					value: "Pagado",
				},
				{
					text: "Anulado",
					value: "Anulado",
				},
				{
					text: "Cancelado",
					value: "Cancelado",
				},
				{
					text: "Reembolso",
					value: "Reembolso",
				},
				{
					text: "Error en el Pago",
					value: "Error en el Pago",
				},
				{
					text: "Retrasado",
					value: "Retrasado",
				},
				{
					text: "Parcialmente Enviado",
					value: "Parcialmente Enviado",
				},
				{
					text: "Desconocido",
					value: "Desconocido",
				},
				{
					text: "Por Confirmar",
					value: "Por Confirmar",
				},
				{
					text: "Revisión",
					value: "Revisión",
				},
			],
			tab: "order",
		},
		{
			text: "type",
			value: "tipo",
			chip: true,
			visible: true,
			type: "select",
			default: "pedido",
			options: [
				{ text: "Pedido", value: "pedido" },
				{ text: "Prueba", value: "prueba" },
				{ text: "Servicio", value: "servicio" },
			],
			tab: "order",
		},
		{
			text: "total",
			value: "total",
			type: "currency",
			readonly: true,
		},
		{
			value: "category",
			text: "category",
			visible: false,
			chip: true,
			type: "text",
			options: [],
			tab: "order",
		},
		{
			text: "transport",
			value: "transporte",
			visible: false,
			chip: true,
			type: "text",
			options: [],
			tab: "order",
		},
		{
			value: "notas",
			text: "notes",
			visible: false,
			type: "textarea",
			tab: "advanced",
		},
		{
			value: "facturar",
			type: "checkbox",
			editonly: true,
			text: "Generar factura a cliente",
			default: (i) => !i.id,
			tab: "advanced",
		},
		{
			text: "warehouse",
			value: "bodega_id",
			type: "relation",
			visible: false,
			attribute: "nombre",
			resource: "bodegas",
			modulo: "inventarios",
			tab: "order",
		},
		{
			value: "items",
			text: "items",
			type: "component",
			editonly: true,
			fullWidth: true,
			component: "item-searcher",
			tab: "order",
		},
		{
			value: "conductor_id",
			text: "driver",
			type: "relation",
			visible: false,
			attribute: "full_name",
			resource: "conductores",
			image: "imagen",
			entity: "Driver",
			tab: "advanced",
		},
		{
			value: "vehiculo_id",
			text: "vehicle",
			type: "relation",
			visible: false,
			entity: "Vehiculo",
			attribute: "full_name",
			image: "imagen",
			resource: "vehiculos",
			tab: "advanced",
		},
		{
			value: "conductor2_id",
			text: "co-driver",
			type: "relation",
			visible: false,
			entity: "Codriver",
			attribute: "full_name",
			image: "imagen",
			resource: "conductores",
			tab: "advanced",
		},
		{
			text: "user",
			value: "user_id",
			type: "relation",
			visible: true,
			entity: "user",
			attribute: "full_name",
			description: "documento",
			subtitle: "entidad.full_name",
			image: "imagen",
			resource: "users",
			tab: "advanced",
		},
		{
			text: "approver",
			value: "approved_by",
			type: "relation",
			visible: true,
			entity: "approver",
			attribute: "full_name",
			image: "imagen",
			resource: "users",
			subtitle: "document",
			tab: "advanced",
		},
		{
			text: "entity",
			value: "entidad_id",
			type: "relation",
			visible: false,
			entity: "entidad",
			attribute: "full_name",
			resource: "entidades",
			tab: "advanced",
		},
		{
			text: "seller",
			value: "vendedor_id",
			type: "relation",
			visible: false,
			entity: "vendedor",
			attribute: "full_name",
			image: "imagen",
			resource: "users",
			subtitle: "document",
			tab: "advanced",
		},
		{
			value: "location",
			text: "location",
			visible: false,
			type: "location_picker",
			tab: "advanced",
			fullWidth: true,
		},
		{
			value: "extra",
			text: "extra",
			visible: false,
			type: "extra",
			tab: "advanced",
			fullWidth: true,
			default: [],
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
//     rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
