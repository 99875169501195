export const validations = {
	name: "required|min:2|max:50",
};

export const model = {
	model: "role",
	filters: [],
	headers: [
		{
			text: "name",
			value: "name",
			type: "text",
			tab: "role",
		},
		{
			text: "permissions",
			value: "permissions",
			visible: true,
			entity: "permissions",
			resource: "permissions",
			align: "center",
			type: "relation_multiple",
			default: [],
			attribute: "name",
			tab: "role",
			sortable: false,
		},
		{
			text: "users",
			value: "users",
			visible: true,
			entity: "users",
			resource: "users",
			align: "center",
			type: "relation_multiple",
			default: [],
			attribute: "full_name",
			tab: "role",
			sortable: false,
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
