import Vue from "vue";
import { api } from "@/services/api";
async function moduleHandler(el, binding) {
	await api.ready;
	var modulo = binding.value;
	if (!modulo) return;

	if (modulo == "facturacion_electronica") {
		if (!api.user.canSendToDian) el.style.display = "none";
		return;
	}
	if (!api.modulos || !api.modulos[modulo]) el.style.display = "none";
	else el.style.display = "";
}
async function roleHandler(el, binding) {
	await api.ready;
	var role = binding.value;
	if (!binding.value) {
		return;
	}
	if (api.roles && api.roles.collection["SuperAdmin"]) return (el.style.display = "");
	if (!api.roles || !api.roles.collection[role]) el.style.display = "none";
	else el.style.display = "";
}
async function permissionHandler(el, binding) {
	await api.ready;
	if (!api.permissions) {
		if (!api.user.perPromise)
			api.user.perPromise = api.get(`users/${api.user.id}?include=permissions,roles.permissions`);
		var user = (await api.user.perPromise).data;
		var permissions = user.permissions;
		user.roles.forEach((role) => {
			role.permissions.forEach((p) => {
				if (!permissions.find((per) => per.id == p.id)) permissions.push(p);
			});
		});
		permissions.collection = api.mapToCollection(permissions, "id");
	}
	if (!binding.value) {
		return;
	}
	await api.ready;
	var permission = binding.value;
	if (!permission) return (el.style.display = "");
	if (api.roles && api.roles.collection["SuperAdmin"]) return (el.style.display = "");
	if (!api.permissions || !api.permissions.collection[permission]) el.style.display = "none";
	else el.style.display = "";
}

async function canHandler(el, binding) {
	await api.ready;
	if (!binding.value) {
		return;
	}
	var response = await api.can(binding.value);
	if (binding.modifiers.not) {
		response = !response;
	}
	if (response) {
		if (el.style.display != "none") return (el.style.display = "");
	} else {
		return (el.style.display = "none");
	}
}
async function authHandler(el) {
	await api.ready;
	if (api.user) el.style.display = "none";
	else el.style.display = "";
}
async function guestHandler(el) {
	await api.ready;
	if (!api.user) el.style.display = "none";
	else el.style.display = "";
}
async function clientHandler(el, binding) {
	await api.ready;
	var client_id = binding.value;
	var response = false;
	if (!api.user) response = false;
	else if (!client_id && api.user.cliente_id) response = true;
	else if (client_id && api.user.cliente_id == client_id) response = true;
	if (binding.modifiers.not) response = !response;
	el.style.display = response ? "" : "none";
}
async function entityHandler(el, binding) {
	await api.ready;
	var entity_id = binding.value;
	var response = false;
	if (!api.user) response = false;
	else if (!entity_id && api.user.entidad_id) response = true;
	else if (entity_id && api.user.entidad_id == entity_id) response = true;
	if (binding.modifiers.not) response = !response;
	el.style.display = response ? "" : "none";
}
async function adminHandler(el) {
	await api.ready;
	if (!api.user || !api.user.admin) el.style.display = "none";
	else el.style.display = "";
}
async function settingHandler(el, binding) {
	await api.ready;
	if (!binding.value) {
		return;
	}
	const value = binding.value;
	const argument = binding.arg;
	const settings = await api.loadSettings();
	let response = ["y", "yes", "1", "true", "ok"].includes(settings[value]);
	if (argument) {
		if (binding.modifiers.contains) {
			response = `${settings[value]}`.includes(argument);
		} else {
			response = settings[value] == argument;
		}
	}

	if (binding.modifiers.not) {
		response = !response;
	}
	if (response) {
		if (el.style.display != "none") return (el.style.display = "");
	} else {
		return (el.style.display = "none");
	}
}

Vue.directive("modulo", moduleHandler);
Vue.directive("module", moduleHandler);

Vue.directive("hasRole", roleHandler);
Vue.directive("role", roleHandler);

Vue.directive("hasPermission", permissionHandler);
Vue.directive("permission", permissionHandler);
Vue.directive("can", canHandler);

Vue.directive("auth", authHandler);
Vue.directive("guest", guestHandler);
Vue.directive("admin", adminHandler);

Vue.directive("hasClient", clientHandler);
Vue.directive("client", clientHandler);
Vue.directive("hasEntity", entityHandler);

Vue.directive("setting", settingHandler);

// Helper Directives
Vue.directive("autoType", {
	bind(el, binding, vnode) {
		let handler = function (e) {
			var val = e.target.value;
			try {
				e.target.value = JSON.parse(val);
				vnode.elm.dispatchEvent(new CustomEvent("input"));
			} catch (error) {
				e.target.value = val;
				vnode.elm.dispatchEvent(new CustomEvent("input"));
			}
		};
		el.addEventListener("input", handler);
	},
});

function unbind(el) {
	el.onclick = null;
}
async function download(el, binding, vnode) {
	await api.ready;
	var url = binding.value;
	el.onclick = function (ev) {
		if (binding.modifiers.stop) ev.stopPropagation();
		api.downloadFile(url, vnode.data.attrs.name).catch(api.handleError);
	};
}
Vue.directive("download", {
	bind: download,
	update: download,
	componentUpdated: download,
	unbind,
});

async function link(el, binding, vnode) {
	await api.ready;
	var uri = binding.value;
	var location = vnode.data.attrs.target || "_system";
	el.onclick = function (ev) {
		if (binding.modifiers.stop) ev.stopPropagation();
		var url = `${api.url}api/${uri}`;
		if (uri.startsWith("https://") || uri.startsWith("http://")) url = uri;
		if (window.cordova) {
			if (window.cordova.platformId == "android") {
				return navigator.app.loadUrl(url, {
					openExternal: location == "_system",
				});
			} else if (window.cordova.platformId == "ios") {
				return window.open(url, location, "location=yes");
			}
		}
		return window.open(url, location);
	};
}
Vue.directive("link", {
	bind: link,
	update: link,
	componentUpdated: link,
	unbind,
});
