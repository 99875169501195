import rules from "@/services/rules";
import { isNumber } from "lodash";
import moment from "moment";

function createDateFromRelative(string = "now", item) {
	if (item[string]) return moment(item[string]);
	switch (string) {
		case "now":
			return moment();
		case "today":
			return moment().startOf("day");
		case "tomorrow":
			return moment().add(1, "day").startOf("day");
		case "yesterday":
			return moment().subtract(1, "day").startOf("day");
		default:
			return moment(string);
	}
}

function getValidationsFromString(text) {
	return text.split("|");
}

function getRuleFromValidation(val, item, field) {
	if (rules[val] && val != "date") {
		return rules[val];
	} else if (val == "date") {
		return (v) => !v || moment(item[field]).isValid() || "must be a valid date";
	} else if (val.startsWith("before:")) {
		let [, date] = val.split(":");
		let comparable = createDateFromRelative(date, item);
		return (v) =>
			!v || moment(item[field]).isBefore(comparable) || `debe ser una fecha antes de ${comparable.format("LLL")}`;
	} else if (val.startsWith("before_or_equal:")) {
		let [, date] = val.split(":");
		let comparable = createDateFromRelative(date, item);
		return (v) =>
			!v || moment(item[field]).isSameOrBefore(comparable) || `debe ser una fecha antes de ${comparable.format("LLL")}`;
	} else if (val.startsWith("after:")) {
		let [, date] = val.split(":");
		let comparable = createDateFromRelative(date, item);
		return (v) => {
			return (
				!v || moment(item[field]).isAfter(comparable) || `debe ser una fecha después de ${comparable.format("LLL")}`
			);
		};
	} else if (val.startsWith("after_or_equal:")) {
		let [, date] = val.split(":");
		let comparable = createDateFromRelative(date, item);
		return (v) =>
			!v ||
			moment(item[field]).isSameOrAfter(comparable) ||
			`debe ser una fecha después de ${comparable.format("LLL")}`;
	} else if (val.startsWith("nit_if:")) {
		let [, attributes] = val.split(":");
		let [another_field, ...values] = attributes.split(",").map((v) => `${v}`);
		return (v) => {
			if (values.includes(item[another_field])) {
				return !v || /(^[0-9]+-{1}[0-9]{1})$/gm.test(v) || "No es un NIT válido";
			}
			return true;
		};
	} else if (val.startsWith("max:")) {
		let [, limit] = val.split(":");
		return (v) =>
			!v ||
			(v instanceof File && v.size <= limit * 1000) ||
			(isNumber(v) && v <= limit) ||
			(!isNumber(v) && v.length <= limit) ||
			"Max. " + limit;
	} else if (val.startsWith("min:")) {
		let [, limit] = val.split(":");
		return (v) => !v || (isNumber(v) && v >= limit) || (!isNumber(v) && v.length >= limit) || "Min. " + limit;
	} else if (val.startsWith("maxvalue:")) {
		let [, limit] = val.split(":");
		return (v) => !v || v <= limit || "Maximum " + limit;
	} else if (val.startsWith("minvalue:")) {
		let [, limit] = val.split(":");
		return (v) => !v || v >= limit || "Maximum " + limit;
	} else if (val.startsWith("regex:")) {
		let [, regex] = val.split(":");
		return (v) => !v || new RegExp(regex).test(v) || "not a valid value";
	} else if (val.startsWith("in:")) {
		let [, options] = val.split(":");
		options = options.split(",");
		return (v) => !v || options.includes(v) || "not a valid value";
	} else if (val.startsWith("required_if:")) {
		let [, options] = val.split(":");
		options = options.split(",");
		let [key, ...values] = options;
		return (v) => !values.includes(item[key]) || !!v || "Required";
	} else if (val.startsWith("required_unless:")) {
		let [, options] = val.split(":");
		options = options.split(",");
		let key = options[0];
		let values = options.slice(1);
		return (v) => values.includes(item[key]) || !!v || "Required";
	} else if (val.startsWith("not_in:")) {
		let [, options] = val.split(":");
		options = options.split(",");
		return (v) => !v || !options.includes(v) || "not a valid value";
	} else if (val.startsWith("parent")) {
		return (v) => {
			if (typeof v == "object") return !v || !item || v.id != item.id || "Este valor no puede ser igual al editado";
			else return !v || !item || v != item.id || "Este valor no puede ser igual al editado";
		};
	}
}

export function toRules(validation, item, field) {
	if (!validation) return [];
	var result = [];
	var validations = getValidationsFromString(validation);
	for (const val of validations) {
		var rule = getRuleFromValidation(val, item, field);
		if (rule && typeof rule === "function") result.push(rule);
	}
	return result;
}

export default { toRules };
