import Vue from "vue";
var settings = {
	language: (navigator.language || navigator.userLanguage || "es").substring(0, 2),
	puesto_id: null,
	entidad_id: null,
	cliente_id: null,
	symbol: "$",
	decimals: 0,

	pos_bodega_id: null,
	pos_invoice_number: 1,
	pos_invoice_prefix: "POS",
	pos_invoice_resolucion: null,
	pos_invoice_cabecera: null,
	pos_invoice_pie: null,
};

var set = window.localStorage.getItem("settings");
if (set) settings = Object.assign(settings, JSON.parse(set));

export default {
	data() {
		return {
			settings: settings,
		};
	},
	methods: {
		incrementSetting(key, qty = 1) {
			this.settings[key] += qty;
			this.saveSettings();
		},
		decrementSetting(key, qty = 1) {
			this.settings[key] -= qty;
			this.saveSettings();
		},
		addSetting(key, value) {
			Vue.set(this.settings, key, value);
		},
		removeSetting(key) {
			Vue.set(this.settings, key, undefined);
		},
		saveSettings() {
			window.localStorage.setItem("settings", JSON.stringify(this.settings));
		},
		loadSettings() {
			var set = window.localStorage.getItem("settings");
			if (set) {
				this.settings = JSON.parse(set);
			}
		},
	},
};

window.$settings = settings;
