<template>
	<div class="fully text-center">
		<video @click="takePicture()" v-if="cameraMode" class="fully" ref="video" id="video" autoplay></video>
		<template v-else>
			<div class="fully" text-center v-if="originalFile">
				<template v-if="aspectRatio">
					<vue-cropper
						style="max-height: 200px"
						restore
						:auto-crop-area="1"
						:aspect-ratio="aspectRatio"
						ref="cropper"
						:src="originalFile"
						alt="Source Image"
						:cropend="updatePic"
						:background="false"
					>
					</vue-cropper>
					<v-btn small icon class="right" @click="remove()" color="red"> <v-icon small>delete</v-icon></v-btn>
				</template>
				<v-img style="max-height: 200px" v-else :src="preview" contain @click="askFile()"></v-img>
			</div>
			<div class="fully text-center" v-else @click="askFile()">
				<slot name="default">
					<v-img
						v-if="placeholder && !file && !error"
						contain
						style="min-height: 50px; max-height: 200px; margin: 0 auto; cursor: pointer"
						:src="placeholder"
						@error="error = true"
					/>
					<v-btn
						v-else
						:clear="clear"
						:color="color"
						:block="block"
						:large="large"
						:dense="dense"
						:outlined="outlined"
						:dark="dark"
					>
						{{ label }}
						<v-icon>camera</v-icon>
					</v-btn>
				</slot>
			</div>
		</template>
		<div id="file-input-container" style="height: 0px; visibility: hidden"></div>
	</div>
</template>
<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { CameraSource, CameraResultType, CameraDirection, Camera } from "@capacitor/camera";
import { Device } from "@capacitor/device";
export default {
	components: { VueCropper },
	mounted() {},
	data() {
		return {
			cameraMode: false,
			mediaStream: null,
			file: null,
			error: false,
			preview: null,
			originalFile: null,
		};
	},
	methods: {
		askFile() {
			var input = document.createElement("input");
			input.setAttribute("type", "file");
			input.setAttribute("accept", "image/*");
			if (this.camera) {
				try {
					this.activateCamera();
					return;
				} catch (error) {
					input.setAttribute("capture", "camera");
				}
			}
			document.getElementById("file-input-container").appendChild(input);
			input.style.width = "0px";
			input.style.height = "0px";
			input.style.visibility = "hidden";
			input.onchange = (event) => {
				try {
					this.file = event.target.files[0];
					if (!this.file) {
						return;
					}
					if (this.file.size > this.maxSize * 1000000) {
						return alert("el archivo es muy grande: " + Math.floor(this.file.size) + ` (< ${this.maxSize}MB)`);
					}

					var reader = new FileReader();
					reader.onload = () => {
						this.preview = reader.result;
						this.originalFile = reader.result;
						this.$emit("input", reader.result);
						this.$emit("change", reader.result);
						input.remove();
					};
					reader.readAsDataURL(this.file);
				} catch (e) {
					console.error(e);
				}
			};
			input.click();
		},
		async activateCamera() {
			const platform = (await Device.getInfo()).platform;
			if (platform !== "internet explorer") {
				const photo = await Camera.getPhoto({
					resultType: CameraResultType.DataUrl,
					source: CameraSource.Camera,
					saveToGallery: true,
					direction: CameraDirection.Rear,
				});
				console.log(photo);
				const result = `${photo.dataUrl}`;
				this.file = result;
				this.preview = result;
				this.originalFile = result;
				this.$emit("input", result);
				this.$emit("change", result);
				return;
			}
			this.cameraMode = true;
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoDevices = devices.filter((device) => device.kind === "videoinput");
			this.mediaStream = await window.navigator.mediaDevices.getUserMedia({
				video: {
					deviceId: videoDevices[videoDevices.length - 1].deviceId,
				},
			});
			this.$refs.video.srcObject = this.mediaStream;
		},
		async takePicture() {
			this.cameraMode = true;
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoDevices = devices.filter((device) => device.kind === "videoinput");
			this.mediaStream = await window.navigator.mediaDevices.getUserMedia({
				video: {
					deviceId: videoDevices[videoDevices.length - 1].deviceId,
				},
			});
			var mediaStreamTrack = this.mediaStream.getVideoTracks()[0];

			var imageCapture = new ImageCapture(mediaStreamTrack);
			console.log(imageCapture);
			var blob = await imageCapture.takePhoto();
			var reader = new FileReader();
			reader.onload = () => {
				console.log(reader.result);
				this.preview = reader.result;
				this.originalFile = reader.result;
				this.$emit("input", reader.result);
				this.$emit("change", reader.result);
				mediaStreamTrack.stop();
				this.cameraMode = false;
			};
			reader.readAsDataURL(blob);
		},
		updatePic() {
			this.preview = this.$refs.cropper.getCroppedCanvas().toDataURL();
			this.$emit("input", this.preview);
			this.$emit("change", this.preview);
		},
		remove() {
			this.file = null;
			this.preview = null;
			this.originalFile = null;
			this.$emit("input", this.file);
		},
	},
	watch: {
		value: {
			handler() {
				// this.placeholder = this.value;
			},
		},
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		showPreview: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: "Imagen",
		},
		color: {
			type: String,
			default: "primary",
		},
		dark: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: null,
		},
		maxSize: {
			type: Number,

			default: 5,
		},
		block: {
			type: Boolean,
			default: true,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		clear: {
			type: Boolean,
			default: true,
		},
		large: {
			type: Boolean,
			default: false,
		},
		camera: {
			type: Boolean,
			default: false,
		},
		aspectRatio: {
			type: Number,
		},
	},
};
</script>
<style>
.fully {
	height: auto;
	max-height: 300px;
	max-width: 100%;
}
.cropper-modal {
	background-color: #eee !important;
	border-radius: 10px;
	border: 1px solid #777;
}
</style>
