<template>
	<div class="signature-container">
		<v-layout row wrap align-content-center justify-center align-center="" class="pa-2">
			<span class="body-1 ml-2">{{ label | trans }}</span>
			<v-spacer></v-spacer>
			<v-btn @click="clear" small text icon>
				<v-icon>format_clear</v-icon>
			</v-btn>
		</v-layout>
		<vue-signature-pad
			:width="width"
			:height="height"
			ref="signaturePad"
			:options="{ onBegin, onEnd }"
		></vue-signature-pad>
		<v-divider></v-divider>
		<slot name="actions" :clear="clear" :value="value" :fire="() => $emit('fire', val)"></slot>
	</div>
</template>
<script>
import Vue from "vue";
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);
export default {
	mounted() {
		if (this.value) {
			this.val = this.value;
			this.$refs.signaturePad.fromDataURL(this.value);
		}
	},
	data() {
		return {
			val: null,
		};
	},
	methods: {
		onBegin() {},
		onEnd() {
			this.save();
		},
		clear() {
			this.$refs.signaturePad.clearSignature();
			this.save();
		},
		save() {
			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
			if (isEmpty) {
				this.$emit("input", null);
				this.val = null;
				return;
			}
			this.$emit("input", data);
			this.val = data;
		},
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: "signature",
		},
		height: {
			type: String,
			default: "200px",
		},
		width: {
			type: String,
			default: "100%",
		},
	},
};
</script>
<style>
.signature-container {
	border: 1px solid #ddd;
}
</style>
