<template>
	<div>
		<v-card>
			<v-text-field
				v-if="reference"
				:label="label | trans"
				:placeholder="reference.address"
				type="text"
				flat
				solo
				single-line
				hide-details
				dense
				@change="$emit('input', reference)"
				v-model="reference.name"
			></v-text-field>
			<newton-map
				:style="`height:${height};width:${width};cursor:pointer !important`"
				@load="getMap"
				:auto-center="false"
			>
				<div style="width: 40%; top: 5px; left: 5px; position: absolute">
					<v-text-field
						solo
						hide-details
						dense
						:loading="loading"
						v-model="query"
						light
						@keypress.enter.stop.prevent="searchAddress"
						append-icon="search"
						clearable
						:label="'crud.search' | trans"
						@click:append="searchAddress"
					></v-text-field>
					<v-list v-for="loc in geolocations" :key="loc.id" dense>
						<v-list-item
							@click="
								map.setCenter([loc.lon, loc.lat]);
								geolocations = [];
							"
						>
							<v-list-item-avatar size="16">
								<v-img :src="loc.icon"></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>
									{{ loc.display_name }}
								</v-list-item-title>
								<v-list-item-subtitle> {{ loc.lat }}, {{ loc.lon }} </v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</div>
				<MglMarker draggable @dragend="setDrag" :coordinates="coordinates" color="green" />
			</newton-map>
		</v-card>
	</div>
</template>
<script>
import NewtonMap from "@/components/main/NewtonMap";
import { MglMarker } from "vue-mapbox";
import { Plugins } from "@capacitor/core";
export default {
	components: { NewtonMap, MglMarker },
	methods: {
		getMap(map) {
			this.map = map;
			if (this.value && (this.value.lat || this.value.latitude)) {
				this.map.setCenter([
					this.value.lng || this.value.lon || this.value.longitude,
					this.value.lat || this.value.latitude,
				]);
			} else {
				this.map.setCenter(this.coordinates);
			}
			var func = (ev) => {
				this.geolocations = [];
				this.coordinates = [ev.lngLat.lng, ev.lngLat.lat];
				var value = {
					lat: this.coordinates[1],
					lng: this.coordinates[0],
					address: this.value ? this.value.address : null,
					name: this.value ? this.value.name : null,
				};
				this.getAddress();
				this.$emit("input", value);
			};
			this.map.on("click", func);
			this.map.on("touchstart", func);
			setTimeout(() => {
				this.map.resize();
			}, 1500);
		},
		setDrag(ev) {
			this.coordinates = [ev.mapboxEvent.target._lngLat.lng, ev.mapboxEvent.target._lngLat.lat];
			if (!this.value) {
				this.$emit("input", {});
			}
			var value = {
				lat: this.coordinates[1],
				lng: this.coordinates[0],
				address: this.value ? this.value.address : null,
				name: this.value ? this.value.name : null,
			};
			this.getAddress();
			this.$emit("input", value);
		},
		async getAddress() {
			var resp = await fetch(
				`https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.coordinates[1]}&lon=${this.coordinates[0]}`
			);

			var data = await resp.json();
			var value = {
				lat: this.coordinates[1],
				lng: this.coordinates[0],
				address: data.display_name,
				name: this.value.name || data.display_name,
			};
			this.$emit("input", value);
		},
		async searchAddress() {
			this.loading = true;
			this.geolocations = [];
			try {
				var resp = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${this.query}`);

				var data = await resp.json();
				this.geolocations = data.slice(0, 3);
			} catch (error) {
				console.error(error);
			}
			this.loading = false;
			console.log(data);
		},
	},
	data() {
		return {
			reference: null,
			query: "",
			loading: false,
			geolocations: [],
			map: null,
			coordinates: [-74.0564207, 4.6715242],
		};
	},
	props: {
		useLocation: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: "location",
		},
		value: {
			type: [Object, String, Array],
			default() {
				return {
					lat: 0,
					lng: 0,
					address: "Bogota Colombia",
					name: "",
				};
			},
		},
		height: {
			default: "400px",
		},
		width: {
			default: "100%",
		},
	},
	watch: {
		value: {
			immediate: true,
			async handler() {
				this.reference = this.value;
				if (this.value && (this.value.latitude || this.value.lat)) {
					this.coordinates = [
						this.value.longitude || this.value.lng || this.value.lon,
						this.value.latitude || this.value.lat,
					];
				} else if (this.useLocation) {
					try {
						const location = await Plugins.Geolocation.getCurrentPosition({
							enableHighAccuracy: true,
						});
						this.coordinates = [location.coords.longitude, location.coords.latitude];
						const value = {
							lat: this.coordinates[1],
							lng: this.coordinates[0],
							address: this.value ? this.value.address : null,
							name: this.value ? this.value.name : null,
						};
						this.getAddress();
						this.$emit("input", value);
						this.getAddress();
						this.map.setCenter([value.lng, value.lat]);
					} catch (error) {
						this.coordinates = [-74.0564207, 4.6715242];
					}
				} else {
					this.coordinates = [-74.0564207, 4.6715242];
				}
			},
		},
	},
};
</script>
