export const validations = {
	nombre: "required|min:2|max:50",
};

export const model = {
	model: "bodega",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "name",
			value: "nombre",
			type: "text",
			rules: "required",
			tab: "warehouse",
		},
		{
			text: "description",
			value: "descripcion",
			type: "textarea",
			tab: "warehouse",
		},
		{
			text: "entity",
			value: "entidad_id",
			visible: true,
			entity: "entidad",
			resource: "entidades",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "warehouse",
		},
		{
			text: "job place",
			value: "puesto_id",
			visible: true,
			entity: "puesto",
			resource: "puestos",
			modulo: "tablas",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "warehouse",
		},
		{
			text: "company",
			value: "company_id",
			visible: true,
			entity: "company",
			resource: "companies",
			modulo: "empresas",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "warehouse",
		},
		{
			text: "status",
			value: "estado",
			tab: "advanced",
			type: "checkbox",
			default: () => true,
		},
		{
			text: "client",
			value: "cliente_id",
			visible: true,
			entity: "cliente",
			resource: "clientes",
			modulo: "clientes",
			ajax: true,
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "advanced",
		},
		{
			text: "supplier",
			value: "proveedor_id",
			visible: true,
			ajax: true,
			entity: "proveedor",
			resource: "proveedores",
			modulo: "proveedores",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "advanced",
		},
		{
			text: "location",
			value: "location",
			type: "location_picker",
			tab: "warehouse",
			fullWidth: true,
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
