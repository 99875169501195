<template>
	<MglMap
		ref="map"
		:interactive="interactive"
		:center="center"
		:pitch="pitch"
		:mapboxGl="mapbox"
		:bearing="bearing"
		:zoom="zoom"
		@load="load"
		:mapStyle="mapStyle"
	>
		<MglNavigationControl position="bottom-right" />
		<MglGeolocateControl v-if="interactive" position="bottom-right" />
		<MglFullscreenControl position="top-right" />
		<MglScaleControl position="bottom-left" />
		<slot />
	</MglMap>
</template>
<script>
import "mapbox-gl/dist/mapbox-gl.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglNavigationControl, MglGeolocateControl, MglFullscreenControl, MglScaleControl } from "vue-mapbox";
export default {
	name: "NewtonMap",
	components: {
		MglMap,
		MglNavigationControl,
		MglGeolocateControl,
		MglFullscreenControl,
		MglScaleControl,
	},
	mounted() {
		this.mapbox = Mapbox;
	},
	methods: {
		load(event) {
			console.log(event.map);
			this.map = event.map;
			this.map.setStyle(this.style);
			this.$emit("load", event.map);
		},
	},
	data() {
		return {
			mapbox: null,
			map: null,
			style: "https://data.osmbuildings.org/0.2/rkc8ywdl/style.json",
			style2: "https://data.osmbuildings.org/0.2/anonymous/style.json",
			mapStyle: {
				version: 8,
				glyphs: "http://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
				sources: {
					tiles: {
						type: "raster",
						tiles: [
							"https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
							"https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
							"https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
						],
						tileSize: 256,
						minzoom: 2,
						maxzoom: 21,
						attribution: `<a href="http://openstreetmap.org">OpenStreetMap</a>`,
					},
				},
				layers: [
					{
						id: "tiles",
						type: "raster",
						source: "tiles",
					},
				],
			},
		};
	},
	watch: {
		center() {
			if (this.map) this.map.setCenter(this.center);
		},
		zoom() {
			if (this.map) this.map.setCenter(this.zoom);
		},
	},
	props: {
		center: {
			type: Array,
			default: () => [-74.16609564000794, 4.5443162057551945],
		},
		autoCenter: {
			type: Boolean,
			default: true,
		},
		zoom: {
			type: Number,
			default: 13,
		},
		pitch: {
			type: Number,
			default: 45,
		},
		bearing: {
			type: Number,
			default: 300,
		},
		interactive: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
<style>
.marker {
	height: 18px;
	width: 18px;
	border-radius: 50px;
	background-color: #5294e2;
	border: 1px solid white;
	margin: 0 auto;
}
.marker-red {
	height: 18px;
	width: 18px;
	border-radius: 50px;
	background-color: red;
	border: 1px solid white;
	margin: 0 auto;
}
.marker-tooltip {
	min-width: 75px;
	margin-top: 5px;
	padding: 3px 5px;
	color: white;
	background-color: rgba(5, 5, 5, 0.78);
}
</style>
