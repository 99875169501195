<template>
	<v-card class="v-card">
		<!-- Header -->
		<slot name="header">
			<v-toolbar color="primary darken-3" dark flat dense>
				<v-toolbar-title class="text-capitalize ma-0 pa-0 row">
					<slot name="title">
						{{ item.id ? "edit" : "add" | trans("crud") }}
					</slot>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<span class="caption hidden-xs-only" v-can="'SuperAdmin'" v-if="item.id"> ID: {{ item.id }} &nbsp; </span>
				<v-icon @click="cancel()">close</v-icon>
				<template v-if="!oneTab" v-slot:extension>
					<v-tabs
						@change="refreshTab"
						v-model="tab"
						background-color="transparent"
						centered
						slider-color="green ligthen-1"
					>
						<v-tab v-for="(tab, key) in tabs" :key="key + '-tab'" :href="`#${key}`">{{ key | trans }}</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
		</slot>
		<!-- Form -->
		<v-card-text style="height: 100%">
			<v-form v-model="validation" @submit.prevent="save()" ref="form">
				<!-- No Tabs -->
				<v-container fill-height grid-list-xs v-if="oneTab">
					<v-layout row wrap class="pa-2">
						<template v-for="(header, i) in $data.$headers">
							<template v-if="!header.visibleIf || header.visibleIf(item, item[header.value])">
								<v-flex
									v-if="header.type !== 'hidden'"
									v-can="header.can"
									v-module="header.modulo"
									xs12
									sm12
									:md6="header.fullWidth === false || header.type == 'boolean'"
									:key="i"
								>
									<default-field
										:read-only="header.readonly"
										:props="header"
										:item="reference"
										v-model="reference[header.field]"
										@input="$emit('input', reference)"
									></default-field>
								</v-flex>

								<default-field
									v-can="header.can"
									v-module="header.modulo"
									:key="i + '-hidden'"
									v-else
									:read-only="header.readonly"
									:props="header"
									:item="reference"
									v-model="reference[header.field]"
									@input="$emit('input', reference)"
								></default-field>
							</template>
						</template>
					</v-layout>
				</v-container>
				<!-- Tabs -->
				<v-tabs-items v-else v-model="tab">
					<v-tab-item v-for="(tab, key) in tabs" class="pa-2" :key="key + '-tab-item'" :value="key" eager>
						<v-container fill-height grid-list-xs>
							<v-layout row wrap>
								<template v-for="(header, i) in tab">
									<template v-if="!header.visibleIf || header.visibleIf(item, item[header.value])">
										<v-flex
											v-if="header.type !== 'hidden'"
											v-can="header.can"
											v-module="header.modulo"
											xs12
											sm12
											:md6="header.fullWidth === false || header.type == 'boolean'"
											:key="i"
										>
											<default-field
												:read-only="header.readonly"
												:props="header"
												:item="reference"
												v-model="reference[header.field]"
												@input="$emit('input', reference)"
											></default-field>
										</v-flex>

										<default-field
											:key="i + '-hidden'"
											v-can="header.can"
											v-module="header.modulo"
											v-else
											:read-only="header.readonly"
											:props="header"
											:item="reference"
											v-model="reference[header.field]"
											@input="$emit('input', reference)"
										></default-field>
									</template>
								</template>
							</v-layout>
						</v-container>
					</v-tab-item>
				</v-tabs-items>
			</v-form>
		</v-card-text>
		<!-- Actions -->
		<slot name="actions" :cancel-action="cancelAction" :save-action="save">
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="cancel()">
					{{ "crud.cancel" | trans }}
				</v-btn>
				<v-btn :color="validation ? 'primary' : 'warning'" :loading="saving" @click="save()">
					<v-icon left>
						{{ validation ? "mdi-content-save" : "mdi-cancel" }}
					</v-icon>
					{{ "crud.save" | trans }}
				</v-btn>
			</v-card-actions>
		</slot>
	</v-card>
</template>
<script>
import DefaultField from "@/components/main/DefaultField";
import moment from "moment";
import _ from "lodash";
export default {
	components: { DefaultField },
	mounted() {
		this.$data.$headers = this.headers
			.filter((h) => h.readonly !== true)
			.map((h) => {
				if (!h.field) h.field = h.value;
				if (!h.value) h.value = h.field;
				if (h.outlined !== false) h.outlined = true;
				if (h.dense !== false) h.dense = true;
				if (h.filled !== false) h.filled = true;
				return h;
			});
		this.tabs = _.groupBy(this.$data.$headers, "tab");
		if (this.tabs["undefined"]) {
			this.tabs["info"] = this.tabs["undefined"];
			delete this.tabs["undefined"];
		}
		this.tab = Object.keys(this.tabs)[0];
		this.oneTab = Object.keys(this.tabs).length === 1;
	},
	data() {
		return {
			validation: false,
			$headers: [],
			tabs: [],
			tab: "info",
			oneTab: true,
			saving: false,
			reference: null,
		};
	},
	methods: {
		refreshTab() {
			setTimeout(() => {
				window.dispatchEvent(new Event("resize"));
			}, 200);
		},
		async save() {
			if (this.$refs.form.validate()) {
				this.saving = true;
				this.$data.$headers
					.filter((h) => h.type === "date" || h.type === "datetime")
					.forEach((h) => {
						if (this.item[h.field || h.value]) {
							this.$set(this.item, h.field || h.value, moment(this.item[h.field || h.value]).format());
						}
					});
				this.$emit("save", this.item);
				if (this.saveAction) var resp = await this.saveAction(this.item);
				this.saving = false;
				return resp;
			} else {
				const errors = [];
				this.$refs.form.inputs.forEach((e) => {
					if (e.hasError) {
						e.errorBucket.forEach((error) => {
							if (this.api.trans["validation"][error.split(",")[0].toLowerCase()]) {
								errors.push(
									this.$options.filters.trans(error.split(",")[0].toLowerCase(), "validation", {
										attribute: `<b>${e.$props.label}</b>`,
									})
								);
							} else errors.push(`<b>${e.$props.label}</b>: ${error}`);
						});
					}
				});
				this.api.handleError({
					response: {
						status: 400,
						data: { errors: errors },
					},
				});
			}
		},
		cancel() {
			this.saving = false;
			this.$emit("cancel");
			if (this.cancelAction) this.cancelAction(false);
		},
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		saveAction: {
			type: Function,
		},
		cancelAction: {
			type: Function,
		},
	},
	watch: {
		item: {
			immediate: true,
			handler() {
				this.reference = this.item;
			},
		},
	},
};
</script>
