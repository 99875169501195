import localForage from "localforage";

export default {
	prefix: window.url,
	async get(key, defaultValue = null) {
		var val = await localForage.getItem(this.prefix + key);
		if (val !== undefined && val !== null) return val;
		return defaultValue;
	},
	async set(key, value) {
		return await localForage.setItem(this.prefix + key, value);
	},
	async remove(key) {
		return await localForage.removeItem(this.prefix + key);
	},
	async clear() {
		return await localForage.clear();
	},
	async increment(key, num = 1) {
		var val = await localForage.getItem(this.prefix + key);
		if (!val) val = 0;
		val += num;
		await localForage.setItem(this.prefix + key, val);
		return val;
	},
	async decrement(key, num = 1) {
		var val = await localForage.getItem(this.prefix + key);
		if (!val) val = 0;
		val -= num;
		await localForage.setItem(this.prefix + key, val);
		return val;
	},
};

const createInstance = (name) => {
	const store = localForage.createInstance({
		name: `${window.url}_${name}`,
	});

	return {
		async get(key, defaultValue = null) {
			var val = await store.getItem(this.prefix + key);
			if (val !== undefined && val !== null) return val;
			return defaultValue;
		},
		async set(key, value) {
			return await store.setItem(this.prefix + key, value);
		},
		async remove(key) {
			return await store.removeItem(this.prefix + key);
		},
		async clear() {
			return await store.clear();
		},
		async increment(key, num = 1) {
			var val = await store.getItem(this.prefix + key);
			if (!val) val = 0;
			val += num;
			await store.setItem(this.prefix + key, val);
			return val;
		},
		async decrement(key, num = 1) {
			var val = await store.getItem(this.prefix + key);
			if (!val) val = 0;
			val -= num;
			await store.setItem(this.prefix + key, val);
			return val;
		},
	};
};

export { createInstance };
