<template>
	<div class="ma-1">
		<template v-if="props.type == 'image'">
			<image-input
				v-model="input"
				:placeholder="item ? item[props.placeholder] : props.placeholder || input"
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:color="props.color"
				:large="props.large"
				:block="props.block"
				:dark="props.dark"
				:clear="props.clear"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@change="change"
				:readonly="props.readonly"
				:rules="rules"
				:label="props.text | trans"
				:aspect-ratio="props.aspectRatio"
			></image-input>
		</template>

		<template v-else-if="props.type == 'file'">
			<v-text-field
				v-if="typeof input == 'string'"
				readonly
				full-width
				v-model="input"
				filled
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:href="api.url + input"
				prepend-icon="cloud_download"
				append-icon="delete"
				@click:prepend="download(input)"
				@click:append="input = null"
			></v-text-field>
			<v-file-input
				v-else
				v-model="input"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				:append-icon="props.appendIcon"
				@change="change"
				:readonly="props.readonly"
				:rules="rules"
				:counter="props.counter"
				:hint="props.hint"
				:multiple="props.multiple"
				:accept="props.accept"
				:show-size="props.showSize"
				:label="props.text | trans"
			>
				<template v-slot:selection="{ text }">
					<v-chip small label block="primary">{{ text }}</v-chip>
				</template>
			</v-file-input>
		</template>

		<template v-else-if="props.type == 'date' || props.type == 'month'">
			<v-menu
				ref="menuRef"
				:close-on-content-click="false"
				v-model="menuValue"
				transition="scale-transition"
				offset-y
				:nudge-right="40"
				min-width="290px"
				:return-value.sync="input"
			>
				<template v-slot:activator="{ on }">
					<v-text-field
						:clearable="!props.required"
						:hint="props.hint"
						:prepend-icon="props.prependIcon"
						:prepend-inner-icon="props.prependInnerIcon"
						v-on="on"
						@click:clear="
							input = null;
							change();
						"
						:outlined="props.outlined"
						:solo="props.solo"
						:flat="props.flat"
						:dense="props.dense"
						:hide-details="!!props.hideDetails"
						:label="props.text | trans"
						:value="input | moment(props.format || (props.type === 'month' ? 'MMMM YYYY' : 'YYYY-MM-DD'))"
						:rules="rules"
						readonly
					></v-text-field>
				</template>
				<v-date-picker
					:allowed-dates="(date) => (props.allowedDates ? props.allowedDates(date, item, rules) : true)"
					@click:date="
						change();
						$refs.menuRef.save(input);
					"
					@click:month="
						change();
						$refs.menuRef.save(input);
					"
					v-model="input"
					:type="props.type"
					no-title
					scrollable
				>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="menuValue = false">Cancel</v-btn>
					<v-btn
						text
						color="primary"
						@click="
							$refs.menuRef.save(input);
							change();
						"
						>OK</v-btn
					>
				</v-date-picker>
			</v-menu>
		</template>

		<template v-else-if="props.type == 'time'">
			<v-menu
				ref="menu"
				v-model="menuValue"
				:close-on-content-click="false"
				:nudge-right="40"
				:return-value.sync="input"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="290px"
			>
				<template v-slot:activator="{ on }">
					<v-text-field
						:outlined="props.outlined"
						:solo="props.solo"
						:flat="props.flat"
						:dense="props.dense"
						:hide-details="!!props.hideDetails"
						:prepend-icon="props.prependIcon"
						:prepend-inner-icon="props.prependInnerIcon"
						:label="props.text | trans"
						:value="input | moment(props.format || 'hh:mm A')"
						:rules="rules"
						readonly
						@click:clear="
							input = null;
							change();
						"
						class="mt-0 pt-0"
						:clearable="!props.required"
						:hint="props.hint"
						v-on="on"
					></v-text-field>
				</template>
				<v-time-picker
					v-if="menuValue"
					v-model="input"
					@click:minute="
						change();
						$refs.menu.save(input);
					"
				>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="menuValue = false">{{ "crud.cancel" | trans }}</v-btn>
					<v-btn text color="primary" @click="$refs.menu.save(input)">{{ "crud.save" | trans }}</v-btn>
				</v-time-picker>
			</v-menu>
		</template>

		<template v-else-if="props.type == 'datetime'">
			<template v-if="props.simple">
				<v-text-field
					:outlined="props.outlined"
					:solo="props.solo"
					:flat="props.flat"
					:dense="props.dense"
					:hide-details="!props.hideDetails"
					:prepend-icon="props.prependIcon"
					:prepend-inner-icon="props.prependInnerIcon"
					@change="change"
					color="primary"
					:readonly="props.readonly"
					:rules="rules"
					type="datetime-local"
					:label="props.text | trans"
					v-model="dateTimeLocal"
					@input="changeDateToLocal"
				></v-text-field>
			</template>

			<v-menu
				v-else
				ref="menu"
				v-model="menuValue"
				:close-on-content-click="false"
				:nudge-right="40"
				:return-value.sync="input"
				transition="scale-transition"
				offset-y
				@click="mode = 'date'"
				min-width="290px"
				max-width="290px"
			>
				<template v-slot:activator="{ on }">
					<v-text-field
						:outlined="props.outlined"
						:solo="props.solo"
						:flat="props.flat"
						:dense="props.dense"
						:hide-details="!!props.hideDetails"
						:counter="props.counter"
						:rules="rules"
						:prepend-icon="props.prependIcon"
						:prepend-inner-icon="props.prependInnerIcon"
						:label="props.text | trans"
						:value="input | moment(props.format || 'YYYY-MM-DD hh:mm A')"
						class="mt-0 pt-0"
						readonly
						@click:clear="
							input = null;
							change();
						"
						:clearable="!props.required"
						:hint="props.hint"
						v-on="on"
					></v-text-field>
				</template>
				<v-date-picker
					:allowed-dates="(date) => (props.allowedDates ? props.allowedDates(date, item, rules) : true)"
					:min="props.min"
					v-if="mode === 'date'"
					v-model="date"
					no-title
					@input="mode = 'time'"
				></v-date-picker>
				<v-time-picker
					v-if="menuValue && mode !== 'date'"
					v-model="time"
					ampm-in-title
					use-seconds
					scrollable
					@click:second="
						input = `${date} ${time}`;
						change();
						$refs.menu.save(input);
						mode = 'date';
					"
				></v-time-picker>
			</v-menu>
		</template>
		<template v-else-if="props.type == 'boolean' || props.type == 'checkbox'">
			<v-checkbox
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@change="change"
				color="primary"
				:readonly="props.readonly"
				:rules="rules"
				:label="props.text | trans"
				v-model="input"
			></v-checkbox>
		</template>

		<template v-else-if="props.type == 'relation' || props.type == 'resource' || props.type == 'relation_multiple'">
			<item-selector
				:ajax="props.ajax"
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!!props.hideDetails"
				:modal="props.dialog"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				v-model="input"
				@input="change"
				:title="props.attribute || props.title"
				:description="props.description"
				:action="props.action"
				:trackBy="props.trackBy"
				:multiple="props.multiple || props.type == 'relation_multiple'"
				:image="props.image"
				:readonly="props.readonly"
				:null-value="props.nullValue"
				:rules="rules"
				:label="props.text | trans"
				:resource="props.resource"
				:items="props.options"
			></item-selector>
		</template>

		<template v-else-if="props.type == 'select'">
			<v-autocomplete
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				v-model="input"
				@input="change"
				:readonly="props.readonly"
				:rules="rules"
				:clearable="!props.required"
				:label="props.text | trans"
				:items="typeof props.options === 'function' ? props.options(item) : props.options"
				:multiple="props.multiple"
				:item-text="props.attribute || 'text'"
				:item-value="props.trackBy || 'value'"
			></v-autocomplete>
		</template>

		<template v-else-if="props.type == 'currency'">
			<v-text-field
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!!props.hideDetails"
				:hint="input | money"
				@input="change"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				prefix="$"
				:readonly="props.readonly"
				:rules="rules"
				:label="props.text | trans"
				v-model="input"
				type="number"
			></v-text-field>
		</template>

		<template v-else-if="props.type == 'extra'">
			<extra-input
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				v-model="input"
				@input="change"
				:rules="rules"
				:label="props.text"
				:default="props.default"
			></extra-input>
		</template>

		<template v-else-if="props.type == 'component'">
			<component
				v-model="input"
				v-bind="props.props"
				:is="props.component"
				:props="props"
				:item="item"
				@input="change"
				:rules="rules"
				:label="props.text"
				:default="props.default"
			></component>
		</template>

		<template v-else-if="props.type == 'textarea'">
			<v-textarea
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@input="change"
				:rows="props.rows || 2"
				:readonly="props.readonly"
				:rules="rules"
				:pattern="props.pattern"
				:counter="props.counter"
				:label="props.text | trans"
				v-model="input"
			></v-textarea>
		</template>

		<template v-else-if="props.type == 'richtext'">
			<!-- @blur="onEditorBlur($event)"
				@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"-->
			<quill-editor
				style="height: 350px; margin-bottom: 50px"
				ref="myQuillEditor"
				v-model="input"
				:options="props"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				:rules="rules"
				:label="props.text | trans"
				@input="change"
			/>
		</template>

		<template v-else-if="props.type == 'password'">
			<v-text-field
				autocomplete="off"
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:pattern="props.pattern"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@input="change"
				:readonly="props.readonly"
				:rules="rules"
				:counter="props.counter"
				:label="props.text | trans"
				v-model="input"
				type="password"
				:hint="props.hint"
				:min="props.min"
				:max="props.max"
				:step="props.step"
			></v-text-field>
		</template>

		<template v-else-if="props.type == 'address'">
			<address-input
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@input="change"
				:readonly="props.readonly"
				:rules="rules"
				:label="props.text | trans"
				v-model="input"
				:hint="props.hint"
			></address-input>
		</template>

		<template v-else-if="props.type == 'color'">
			<v-color-picker
				class="ma-2"
				:show-swatches="props.showSwatches"
				hide-canvas
				hide-mode-switch
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				mode="hexa"
				width="auto"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@input="changeColor"
				:readonly="props.readonly"
				:rules="rules"
				:label="props.text | trans"
				v-model="input"
				:hint="props.hint"
			></v-color-picker>
		</template>

		<template v-else-if="props.type == 'location_picker' || props.type == 'location'">
			<map-location-picker
				@input="change"
				v-model="input"
				:label="props.text | trans"
				:multiple="props.multiple"
				:readonly="props.readonly"
			></map-location-picker>
		</template>

		<template v-else-if="props.type == 'hidden'">
			<input
				type="hidden"
				:name="props.value"
				v-model="input"
				:readonly="props.readonly"
				:rules="rules"
				:disabled="props.disabled"
			/>
		</template>

		<template v-else>
			<v-text-field
				:outlined="props.outlined"
				:solo="props.solo"
				:flat="props.flat"
				:dense="props.dense"
				:hide-details="!props.hideDetails"
				:prepend-icon="props.prependIcon"
				:prepend-inner-icon="props.prependInnerIcon"
				@input="change"
				:pattern="props.pattern"
				:readonly="props.readonly"
				autocomplete="off"
				:rules="rules"
				:counter="props.counter"
				:label="props.text | trans"
				:disabled="props.disabled"
				v-model="input"
				:type="props.type"
				:hint="props.hint"
				:min="props.min"
				:max="props.max"
				:step="props.step"
				:persistent-hint="props.persistentHint"
			></v-text-field>
		</template>
	</div>
</template>
<script>
import ExtraInput from "@/components/main/ExtraInput";
import AddressInput from "@/components/main/AddressInput";
import MapLocationPicker from "@/components/main/MapLocationPicker";
import validator from "@/services/validator";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import moment from "moment";
export default {
	components: { ExtraInput, AddressInput, MapLocationPicker, quillEditor },
	props: {
		value: {
			default: null,
		},
		item: {
			type: Object,
		},
		props: {
			type: Object,
			default: () => {
				return {
					text: "full name",
					value: "full_name",
					type: "text",
					dated: null,
					rules: [],
					hideDetails: false,
					solo: false,
					flat: false,
					dense: false,
				};
			},
		},
		noIcon: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		change() {
			this.$emit("input", this.input || null);
		},
		changeColor(ev) {
			if (ev && ev.hex && ev.hex !== this.input) {
				return this.$emit("input", ev.hex);
			}
			this.$emit("input", ev);
		},
		changeDateToLocal(val) {
			var value = moment(val).format("YYYY-MM-DDTHH:mm");
			this.input = value;
			this.$emit("input", value);
		},
		download(input) {
			window.open(this.api.url + input, "_system");
		},
	},
	filters: {
		number(value, decimals = 0) {
			if (value == undefined) return "--";
			return Number(value).toFixed(decimals);
		},
	},
	data() {
		return {
			menuValue: false,
			input: null,
			date: null,
			dateTimeLocal: null,
			time: null,
			mode: "date",
		};
	},
	computed: {
		rules: {
			immediate: true,
			get() {
				if (!this.props || !this.props.rules) return [];
				if (Array.isArray(this.props.rules)) return this.props.rules.map((func) => (v) => func(v, this.item));
				return validator.toRules(this.props.rules, this.item, this.props.field || this.props.value);
			},
		},
	},
	beforeMount() {
		this.input = this.value;
		if (this.props.type == "datetime") {
			this.dateTimeLocal = moment(this.value).format("YYYY-MM-DDTHH:mm");
		}
	},
	watch: {
		value() {
			this.input = this.value;
			if (this.props.type == "datetime") {
				this.dateTimeLocal = moment(this.value).format("YYYY-MM-DDTHH:mm");
			}
		},
		time() {
			if (this.time == null || this.props.type !== "datetime") return;
			this.input = this.date + " " + this.time;
		},
	},
};
</script>
