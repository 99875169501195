<style scoped>
.item-container {
	max-width: 100%;
	margin: 5px;
	cursor: grab;
}
.sortable-ghost {
	height: 50px;
	border: 1px dashed blue;
}
.sortable-ghost * {
	display: none;
}
.item {
	margin-top: 3px;
	padding: 10px;
	border-radius: 10px;
	border: solid black 1px;
	background-color: rgb(250, 250, 250);
	color: #333;
}
.theme--dark .item {
	margin-top: 3px;
	padding: 10px;
	border-radius: 10px;
	border: solid black 1px;
	background-color: #333;
	color: rgb(250, 250, 250);
}
.item-sub {
	min-height: 10px;
	margin: 0 0 0 2rem;
}
</style>

<template>
	<draggable v-bind="dragOptions" tag="div" group="item" class="item-container" :value="value" @input="emitter">
		<div class="item-group" :key="el.id" v-for="el in value">
			<div class="item">
				<div>
					<span v-if="el.children.length > 0"> - </span>
					{{ el[title] }}
				</div>
				<div class="caption">{{ el[description] }}</div>
			</div>
			<nested-draggable
				class="item-sub"
				:title="title"
				:description="description"
				v-model="el.children"
				:save-action="null"
			/>
		</div>
	</draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
	name: "nested-draggable",
	methods: {
		emitter(value) {
			this.$emit("input", value);
		},
	},
	components: {
		draggable,
	},
	computed: {
		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: false,
				ghostClass: "sortable-ghost",
			};
		},
		realValue() {
			return this.value;
		},
	},
	props: {
		value: {
			required: true,
			type: Array,
			default: null,
		},
		title: {
			type: String,
			default: "full_name",
		},
		description: {
			type: String,
			default: "description",
		},
	},
};
</script>
