export const validations = {
	nombre: "required|min:2",
	descripción: "nullable|min:2",
	color: "nullable|dstring",
	lists: "nullable|string",
	parent_id: "nullable|exists:\\App\\ModelsCategoriasTickets,id|parent:\\App\\ModelsCategoriasTickets,id",
};

export const model = {
	model: "CategoriaTicket",
	filters: [
		{
			name: "parent_id",
			label: "parent",
			type: "relation",
			resource: "categorias-tickets?whereNull[]=parent_id",
			handler: (v) => `whereIn[parent_id]=${v}`,
			searchLogic: (v, i) => `${i.parent_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			visible: true,
			align: "center",
			type: "text",
			readonly: true,
		},
		{
			text: "name",
			value: "nombre",
			visible: true,
			type: "text",
			tab: "category",
		},
		{
			text: "color",
			value: "color",
			visible: true,
			type: "color",
			tab: "category",
		},
		{
			text: "lists",
			value: "lists",
			visible: false,
			hint: "Lista del tablero separados por coma (,)",
			default: () => "abierto,en curso,completado,rechazado",
			tab: "category",
		},
		{
			text: "description",
			value: "descripción",
			required: false,
			default: null,
			type: "textarea",
			fullWidth: false,
			tab: "category",
			visible: true,
		},
		{
			text: "parent",
			value: "parent_id",
			visible: true,
			fullWidth: false,
			attribute: "full_name",
			type: "relation",
			entity: "parent",
			tab: "category",
			resource: "categorias-tickets",
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
