<template>
	<span class="text-center">
		<template v-if="header.metadata === true">
			<default-row
				:header="{ ...header, metadata: false, value: header.metadata_field }"
				:item="item[header.value]"
			></default-row>
		</template>
		<template v-else-if="header.type == 'function'">
			<span v-html="header.handler(item, header, viewResource)"></span>
		</template>
		<template v-else-if="header.type == 'image'">
			<a :href="item[header.value]" target="avatar-img">
				<v-avatar :size="mode == 'view' ? 48 : 24" color="gray">
					<v-img :src="item[header.placeholder || header.value]"></v-img>
				</v-avatar>
			</a>
		</template>
		<template v-else-if="header.type == 'number'">{{ item[header.value] | numeric }}</template>
		<template v-else-if="header.type == 'currency'">{{ item[header.value] | money }}</template>
		<template v-else-if="header.type == 'date'">
			<span v-if="item[header.value]">{{ item[header.value] | moment("YYYY-MM-DD") }}</span>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'datetime'">
			<span v-if="item[header.value]">{{ item[header.value] | moment("YYYY-MM-DD hh:mm:ss A") }}</span>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'boolean' || header.type == 'checkbox'">
			<v-icon v-if="item[header.value]">mdi-checkbox-marked-outline</v-icon>
			<v-icon v-else-if="item[header.value] === undefined">mdi-checkbox-indetermediate</v-icon>
			<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
		</template>
		<template v-else-if="header.type == 'relation'">
			<v-btn
				:small="$vuetify.breakpoint.mdAndUp"
				:x-small="$vuetify.breakpoint.smAndDown"
				text
				@click="viewResource(item[header.entity], header)"
				color="primary"
				v-if="item[header.value] && item[header.entity]"
				>{{ item[header.entity][header.attribute] }}</v-btn
			>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'relation_multiple'">
			<template v-if="item[header.entity] && item[header.entity].length">
				<template v-for="(entity, i) in limitBy(item[header.entity], 10)">
					<v-btn x-small color="primary" outlined :key="i" @click="viewResource(entity, header)"
						>{{ entity[header.attribute] }},</v-btn
					>
				</template>
				<span v-if="item[header.entity].length > 10"> [and {{ item[header.entity].length - 10 }} more...]</span>
			</template>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'extra'">
			<template v-if="item[header.value] && item[header.value].length">
				<template v-for="(item, i) in item[header.value]">
					<span :key="i">{{ item.key }}: {{ item.value }},,</span>
				</template>
			</template>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'location_picker' || header.type == 'location'">
			<template v-if="mode == 'view'">
				<span
					class="body-2"
					v-if="item[header.value] && item[header.value].name"
					v-text="item[header.value].name"
				></span>
				<span
					class="caption"
					v-if="item[header.value] && item[header.value].address"
					v-text="item[header.value].address"
				></span>
				<newton-map style="height: 300px" @load="onLoadMap" :auto-center="false">
					<MglMarker v-if="coordinates" :coordinates="coordinates" />
					<v-card v-else>
						<div class="subtitle-2">{{ "no location" | trans }}</div>
					</v-card>
				</newton-map>
			</template>
			<span v-else>
				<span class="body-2" v-if="item[header.value] && item[header.value].name"></span>
				<span v-else>--</span>
			</span>
		</template>
		<template v-else-if="header.type == 'email'">
			<a :href="'mailto:' + item[header.value]" target="_blank">{{ item[header.value] | truncate(40) }}</a>
		</template>
		<template v-else-if="header.type == 'color'">
			<v-icon :color="item[header.value]">mdi-circle</v-icon>
		</template>
		<template v-else-if="header.type == 'component'">
			<component v-bind="header.props" :is="header.component" :item="item" :value="item.value"></component>
		</template>
		<template v-else-if="header.type == 'select' && header.multiple">
			<template v-if="item[header.value] && item[header.value].length">
				<span>{{ toShow }}</span>
			</template>
			<span v-else>--</span>
		</template>
		<template v-else-if="header.type == 'select' && !header.multiple">
			<component
				:is="header.chip ? 'v-chip' : 'span'"
				:class="header.chip ? `${item[header.value]}`.toLowerCase() : ''"
				v-if="header.translate"
				>{{ toShow | trans }}</component
			>
			<component
				:is="header.chip ? 'v-chip' : 'span'"
				:class="header.chip ? `${item[header.value]}`.toLowerCase() : ''"
				v-else
				>{{ toShow }}</component
			>
		</template>
		<template v-else-if="header.type == 'richtext'">
			<div v-html="$options.filters.truncate(item[header.value] || '--', 140)"></div>
		</template>
		<template v-else>
			<component
				:is="header.chip ? 'v-chip' : 'span'"
				:class="header.chip ? `${item[header.value]}`.toLowerCase() : ''"
				style="min-width: 75px; justify-content: center"
				small
				>{{ get(item, header.value) | ellipsed(70) }}</component
			>
		</template>

		<v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" max-width="500px" v-model="resourceModal">
			<viewer
				:headers="resourceHeaders"
				:cancel-action="() => (resourceModal = false)"
				:item="resource"
				:color="null"
			></viewer>
		</v-dialog>
	</span>
</template>
<script>
import { get } from "lodash";
import Vue from "vue";
import NewtonMap from "@/components/main/NewtonMap";
import CrudViewer from "@/components/main/CrudViewer";
import models from "@/models/modelsDefinitions";
import { MglMarker } from "vue-mapbox";
export default {
	name: "DefaultRow",
	beforeMount() {
		Vue.component("viewer", CrudViewer);
	},
	components: { NewtonMap, MglMarker },
	props: {
		header: {
			type: Object,
			default: () => {
				return {
					text: "full name",
					value: "full_name",
					type: "text",
				};
			},
		},
		item: {
			type: Object,
			default: () => {},
		},
		mode: {
			type: String,
			default: "table",
		},
	},
	data() {
		return {
			resourceModal: false,
			resource: null,
			resourceHeaders: [],
		};
	},
	methods: {
		get(item, key) {
			const val = get(item, key);
			return val !== undefined ? val : "--";
		},
		onLoadMap(map) {
			console.log(map);
			if (this.coordinates) {
				map.setCenter(this.coordinates);
			} else {
				map.setCenter([-72.9479608, 4.0741733]);
				map.setZoom(3);
			}
			map.boxZoom.disable();
			map.scrollZoom.disable();
			map.dragPan.disable();
			map.dragRotate.disable();
			map.keyboard.disable();
			map.doubleClickZoom.disable();
			map.touchZoomRotate.disable();
		},
		viewResource(resource, header) {
			this.resource = resource;
			const model = Object.values(models).find(
				(m) => m.model === header.singleEntity || m.model === header.entity || m.model === header.model
			);
			if (model)
				this.resourceHeaders = model.headers.filter(
					(h) =>
						h.type !== "relation" &&
						h.type !== "relation_multiple" &&
						h.type !== "component" &&
						h.value !== "salario" &&
						h.editonly !== true
				);
			else
				this.resourceHeaders = [
					{
						type: "text",
						text: header.attribute,
						value: header.attribute,
					},
				];
			this.resourceModal = true;
		},
	},
	computed: {
		coordinates() {
			if (!this.item[this.header.value]) return null;
			if (this.item[this.header.value].lat || this.item[this.header.value].latitude) {
				return [
					this.item[this.header.value].longitude ||
						this.item[this.header.value].lng ||
						this.item[this.header.value].lon,
					this.item[this.header.value].latitude || this.item[this.header.value].lat,
				];
			}
			return null;
		},
		toShow() {
			if (!this.item[this.header.value]) return "--";
			if (this.header.multiple) {
				if (typeof this.header.options[0] == "string") {
					return this.item[this.header.value].join(",");
				}
				return this.header.options
					.filter((v) => {
						return this.item[this.header.value].indexOf(v[this.header.trackBy || "id"]) > -1;
					})
					.map((o) => o[this.header.attribute])
					.join(",");
			} else {
				if (this.header.options && this.header.options.length > 0 && typeof this.header.options[0] != "string") {
					var opt = this.header.options.find((v) => {
						return this.item[this.header.value] == v[this.header.trackBy || "id"];
					});
					if (opt) {
						return opt[this.header.attribute];
					}
				}
				return this.item[this.header.value];
			}
		},
	},
};
</script>
