import moment from "moment";
export default {
	required: (v) => {
		if (!v) return "Required";
		if (Array.isArray(v) && v.length === 0) return "Required";
		return true;
	},
	nospecialchar: (v) => !v || /^[^<>|*$%&@#!'{}`~]*$/.test(v) || "No debe poseer ningún carácter especial", // deepscan-disable-line
	nit: (v) => !v || /(^[0-9]+-{1}[0-9]{1})$/gm.test(v) || "No es un NIT válido",
	min3: (v) => !v || v.length > 3 || "Min. Characters 3",
	min6: (v) => !v || v.length > 6 || "Min. Characters 6",
	min8: (v) => !v || v.length > 8 || "Min. Characters 8",
	max50: (v) => !v || v.length <= 50 || "Max. Characters 50",
	max255: (v) => !v || v.length <= 255 || "Max. Characters 255",
	email: (v) => (v && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(v)) || "Invalid e-mail.",
	emails: (v) =>
		(v &&
			/^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,14}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/.test(
				v
			)) ||
		"Invalid e-mail.",
	array: (v) => !v || Array.isArray(v) || "must be a list",
	numeric: (v) => !v || !isNaN(v) || "must be a number",
	boolean: (v) => v == undefined || typeof v === "boolean" || "must be true or false",
	string: (v) => v == undefined || typeof v === "string" || "must be a string",
	date: (v) => !v || moment(v).isValid() || "debe ser una fecha valida",
};
