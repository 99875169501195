<template>
	<v-layout style="width: 100%" @keyup.esc.stop="opened = false" @click.stop>
		<v-img
			:src="src"
			:contain="contain"
			:size="size"
			@click.native.stop="opened = true"
			style="cursor: pointer; max-height: 100%; max-width: 100%; width: auto; height: auto; margin: 0 auto"
		></v-img>
		<v-overlay v-model="opened" z-index="200000" v-if="opened" :opacity="0.9">
			<v-icon @click="opened = false" x-large style="position: fixed; top: 20px; right: 20px">mdi-close</v-icon>
			<img :src="src" v-bind="$props" style="max-width: 90vw; max-height: 90vh; height: auto; width: auto" />
		</v-overlay>
	</v-layout>
</template>
<script>
var func;
export default {
	mounted() {
		func = (evt) => {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = evt.key === "Escape" || evt.key === "Esc";
			} else {
				isEscape = evt.keyCode === 27;
			}
			if (isEscape && this.opened) {
				this.opened = false;
				evt.stopPropagation();
			}
		};
		document.addEventListener("keydown", func);
	},
	beforeDestroy() {
		document.removeEventListener("keydown", func);
	},
	data() {
		return {
			opened: false,
		};
	},
	props: ["src", "contain", "size"],
};
</script>
