var Toolbar = {
	buttons: [],
};

export default {
	data: () => {
		return {
			toolbar: Toolbar,
		};
	},
};

export { Toolbar };
