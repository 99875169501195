export const validations = {
	name: "required|min:2|max:200|unique:AppModelsCompany,name,NULL,id,deleted_at,NUL",
	document: "required|min:2|max:200|unique:AppModelsCompany,document,NULL,id,deleted_at,NULL",
	description: "nullable|string|min:2|max:5000",
	entidad_id: "nullable|exists:AppModelsEntidad,id",
	email: "nullable|email",
	phone_number: "nullable",
	address: "nullable",
	active: "nullable",
	location: "nullable|array",
	extra: "array|nullable",
	contact_person: "string|nullable",
	sector: "string|nullable",
};

export const model = {
	model: "company",
	load: "entidad",
	defaultOrder: "document",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			readonly: true,
		},
		{
			value: "document",
			text: "document",
			type: "text",
			tab: "company",
		},
		{
			value: "name",
			text: "name",
			type: "text",
			tab: "company",
		},
		{
			value: "email",
			text: "email",
			type: "email",
			tab: "company",
		},
		{
			value: "phone_number",
			text: "phone_number",
			type: "phone_number",
			tab: "company",
		},
		{
			value: "address",
			text: "address",
			type: "textarea",
			visible: false,
			tab: "company",
		},
		{
			value: "active",
			text: "active",
			type: "check",
			visible: true,
			tab: "company",
		},
		{
			value: "entidad_id",
			text: "entity",
			type: "relation",
			entity: "entidad",
			resource: "entidades",
			attribute: "full_name",
			tab: "company",
			visible: true,
		},
		{
			value: "location",
			text: "location",
			type: "location_picker",
			visible: false,
			tab: "advanced",
		},
		{
			value: "contact_person",
			text: "contact_person",
			type: "text",
			visible: false,
			tab: "advanced",
		},
		{
			value: "sector",
			text: "sector",
			type: "text",
			visible: false,
			tab: "advanced",
		},
		{
			value: "extra",
			text: "extra",
			type: "extra",
			visible: true,
			tab: "advanced",
		},
	],
};

export default {
	...model,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return {
			...h,
			rules,
		};
	}),
};
