import staticTables from "@/../public/staticTables.json";
const clientes = {
	model: "cliente",
	filters: [],
	headers: [
		{
			text: "#",
			value: "id",
			type: "text",
			readonly: true,
			tab: "client",
		},
		{
			text: "Tipo de organizacicion",
			value: "tipo_organizacion",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["21tiporganizacion"],
			default: 1,
			visible: false,
			rules: "required",
			tab: "client",
		},
		{
			text: "full_name",
			value: "full_name",
			type: "text",
			sortable: false,
			readonly: true,
			tab: "client",
		},
		// Names
		{
			text: "first name",
			value: "first_name",
			editonly: true,
			align: "center",
			type: "text",
			visibleIf: (i) => i.tipo_organizacion != 1,
			rules: "required",
			tab: "client",
			counter: 80,
		},
		{
			text: "Nombre de Razon Social",
			value: "first_name",
			editonly: true,
			align: "center",
			type: "text",
			visibleIf: (i) => i.tipo_organizacion == 1,
			rules: "required",
			tab: "client",
			counter: 80,
		},
		{
			text: "middle name",
			value: "middle_name",
			editonly: true,
			align: "center",
			type: "text",
			visibleIf: (i) => i.tipo_organizacion != 1,
			tab: "client",
			rules: [],
		},
		{
			text: "last name",
			value: "last_name",
			editonly: true,
			align: "center",
			type: "text",
			rules: "required",
			visibleIf: (i) => i.tipo_organizacion != 1,
			tab: "client",
		},
		{
			text: "Nombre Comercial",
			value: "nombre_comercial",
			editonly: true,
			align: "center",
			type: "text",
			hint: "Dejar en blanco para usar Razon Social",
			visibleIf: (i) => i.tipo_organizacion != 1,
			tab: "client",
			rules: [],
		},
		// End Names
		{
			text: "Tipo de Identificacion",
			value: "tipo_document",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			visible: false,
			options: staticTables["21docidentificacion"],
			default: "31",
			rules: "required",
			tab: "client",
		},
		{
			text: "document",
			value: "nit",
			type: "text",
			rules: "required",
			tab: "client",
		},
		{
			text: "email",
			value: "email",
			type: "email",
			rules: "emails",
			tab: "client",
		},
		{
			text: "phone_number",
			value: "telefono",
			type: "text",
			tab: "client",
		},

		{
			text: "country",
			value: "pais",
			type: "select",
			attribute: "name",
			trackBy: "code",
			visible: false,
			options: staticTables["21paises"],
			default: "CO",
			rules: "required",
			tab: "client",
		},
		{
			text: "Departamento",
			value: "departamento",
			type: "select",
			attribute: "name",
			trackBy: "code",
			visible: false,
			options: staticTables["21departamentos"],
			default: "11",
			rules: "required",
			tab: "client",
		},
		{
			text: "Municipio",
			value: "municipio",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			visible: false,
			options: (i) => staticTables["21municipios"].filter((o) => o.codigo.startsWith(i.departamento)),
			visibleIf: (i) => i.pais == "CO" && i.departamento,
			default: "11001",
			rules: "required",
			tab: "client",
		},

		{
			text: "color",
			value: "color",
			type: "color",
			visible: false,
			default: "#2196f3",
			tab: "client",
		},
		{
			text: "Codigo de Actividad Economica",
			value: "codigo_actividad_economica",
			type: "text",
			rules: "required",
			visible: false,
			hint: "Actividad Economica principal",
			tab: "client",
		},

		{
			text: "Matricula Mercantil",
			value: "matricula_mercantil",
			type: "text",
			visible: false,
			rules: "required",
			tab: "client",
		},
		{
			text: "address",
			value: "direccion",
			type: "textarea",
			rules: "required|nospecialchar",
			visible: false,
			tab: "client",
			fullWidth: true,
		},
		{
			text: "image",
			value: "imagen",
			type: "image",
			placeholder: "foto_url",
			tab: "client",
			fullWidth: true,
		},
		// Advanced Tab
		{
			text: "Regimen Fiscal",
			value: "regimen_fiscal",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["21tiporegimen"],
			default: "05",
			rules: "required",
			tab: "advanced",
		},

		{
			text: "Responsabilidades fiscales",
			value: "resposabilidad_fiscal",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			multiple: true, // se pueden escoger varias opciones seprar valores por ;
			options: staticTables["21responsabilidad"],
			rules: "required",
			tab: "advanced",
		},

		{
			text: "Tipos de Operacion",
			value: "tipo_operacion",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["21tipoperacion"],
			rules: "required",
			tab: "advanced",
		},

		{
			text: "Tributos",
			value: "tributo",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["21tributos"],
			rules: "required",
			tab: "advanced",
		},

		{
			text: "Esquema de Impuestos",
			value: "esquema_de_impuestos",
			type: "select",
			attribute: "name",
			trackBy: "codigo",
			options: staticTables["21grupotax"],
			rules: "required",
			tab: "advanced",
		},

		{
			text: "nombre_empresa",
			value: "nombre_empresa",
			type: "text",
			visible: false,
			tab: "advanced",
		},
		{
			text: "resolucion_dian",
			value: "resolucion_dian",
			type: "textarea",
			rows: 4,
			visible: false,
			tab: "advanced",
		},
		{
			text: "cabecera_factura",
			value: "cabecera_factura",
			type: "textarea",
			rows: 4,
			visible: false,
			tab: "advanced",
		},
		{
			text: "pie_factura",
			value: "pie_factura",
			type: "textarea",
			rows: 4,
			visible: false,
			tab: "advanced",
		},
		{
			text: "prefix",
			value: "prefix",
			type: "text",
			visible: false,
			tab: "advanced",
		},
		{
			text: "suffix",
			value: "suffix",
			type: "text",
			visible: false,
			tab: "advanced",
		},
		{
			text: "invoice_number",
			value: "invoice_number",
			type: "number",
			visible: false,
			tab: "advanced",
		},
		{
			text: "Maneja AIU",
			value: "aiu",
			type: "checkbox",
			visible: false,
			tab: "advanced",
		},

		// Others Tab
		{
			text: "Fecha de Ingreso A la Empresa",
			value: "ingreso",
			type: "date",
			visible: false,
			tab: "others",
		},
		{
			text: "Fecha de Egreso A la Empresa",
			value: "egreso",
			type: "date",
			visible: false,
			tab: "others",
		},
		{
			text: "note",
			value: "nota",
			type: "textarea",
			visible: false,
			tab: "others",
		},
		{
			text: "Usuario/Vendedor Asociado",
			value: "user_id",
			type: "relation",
			attribute: "full_name",
			resource: "users",
			visible: false,
			tab: "others",
		},

		// EDX TAB
		{
			text: "Modo Prueba EDX",
			value: "edx_test",
			type: "checkbox",
			align: "center",
			rules: [],
			tab: "EDX",
		},
		{
			text: "URL EDX para Webservice",
			value: "edx_url",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			default: "http://integrationlat.edxsolutions.com/xDoc.CO/Core.asmx?WSDL",
			hint: "Url para enviar facturas y notas",
		},
		{
			text: "URL EDX para Webservice de consulta",
			value: "edx_url_consult",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			default: "https://integrationlat.edxsolutions.com/SServerCO.Service/Core.asmx?WSDL",
			hint: "Url para consultar XML de facturas y notas",
		},
		{
			text: "Usuario EDX para Webservice",
			value: "edx_username",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			hint: "Usuario Proporcionado por la EDX para Conexion con webservice",
		},
		{
			text: "Password EDX para Webservice",
			value: "edx_password",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			hint: "Contrasena Proporcionado por la EDX para Conexion con webservice",
		},
		{
			text: "Usuario EDX para Consulta DIAN",
			value: "edx_consult_username",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			hint: "Usuario Proporcionado por la EDX para Consulta de estado de Factura",
		},
		{
			text: "Password EDX para Consulta DIAN",
			value: "edx_consult_password",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			hint: "Contrasena Proporcionado por la EDX para Consulta de estado de Factura",
		},
		{
			text: "Url EDX para Contingencia DIAN",
			value: "contingencia_url",
			type: "text",
			editonly: true,
			rules: [],
			tab: "EDX",
			hint: "Url EDX para Contingencia DIAN",
		},
		{
			text: "Version del spool del cliente",
			value: "spool_version",
			type: "select",
			visible: false,
			options: [
				{ text: "2.0", value: "2.0" },
				{ text: "2.1", value: "2.1" },
				{ text: "acepta", value: "acepta" },
			],
			rules: [],
			tab: "EDX",
			default: "2.0",
		},

		// "Facturación Electrónica TAB"
		{
			text: "Fecha de Expedicion de Certificado Digital",
			value: "fecha_expedicion_certificado",
			type: "date",
			editonly: true,
			tab: "Facturación Electrónica",
		},
		{
			text: "Fecha de Inicio de Facturacion asignada por la DIAN",
			value: "fecha_inicio_facturacion",
			type: "date",
			editonly: true,
			default: () => "2019-01-19",
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Fecha de Fin de Facturación asignada por la DIAN",
			value: "fecha_fin_facturacion",
			default: () => "2030-01-19",
			type: "date",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Rango de Inicio de Facturación asignada por la DIAN",
			value: "rango_inicio_facturacion",
			type: "number",
			editonly: true,
			default: () => 1,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Rango de Fin de Facturación asignada por la DIAN",
			value: "rango_fin_facturacion",
			type: "number",
			default: () => 5000000,
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Numero de Resolución asignada por la DIAN",
			value: "numero_resolucion_facturacion",
			type: "text",
			editonly: true,
			default: () => 18760000001,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Clave Técnica DIAN",
			value: "clave_tecnica",
			type: "text",
			default: () => "fc8eac422eba16e22ffd8c6f94b3f40a6e38162c",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},

		// "Contingencia Facturación Electrónica TAB"
		{
			text: "Contingencia | Fecha de Inicio de Facturacion asignada por la DIAN",
			value: "contingencia_fecha_inicio_facturacion",
			type: "date",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Fecha de Fin de Facturación asignada por la DIAN",
			value: "contingencia_fecha_fin_facturacion",
			type: "date",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Rango de Inicio de Facturación asignada por la DIAN",
			value: "contingencia_rango_inicio_facturacion",
			type: "number",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Rango de Fin de Facturación asignada por la DIAN",
			value: "contingencia_rango_fin_facturacion",
			type: "number",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Numero de Resolución asignada por la DIAN",
			value: "contingencia_numero_resolucion_facturacion",
			type: "text",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Clave Técnica DIAN",
			value: "contingencia_clave_tecnica",
			type: "text",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},
		{
			text: "Contingencia | Prefijo DIAN",
			value: "contingencia_prefijo",
			type: "text",
			editonly: true,
			rules: "required",
			tab: "Facturación Electrónica",
		},

		// "Nomina Electrónica TAB"
		{
			text: "Nomina Token",
			value: "nomina_token",
			type: "text",
			editonly: true,
			tab: "Nomina Electrónica",
		},
		{
			text: "Nomina Set Id",
			value: "nomina_set_id",
			type: "text",
			editonly: true,
			tab: "Nomina Electrónica",
		},
		{
			text: "Nomina Numeración",
			value: "nomina_numeracion",
			type: "text",
			editonly: true,
			tab: "Nomina Electrónica",
		},
		{
			text: "Nomina Modo Test",
			value: "dian_test",
			type: "boolean",
			editonly: true,
			tab: "Nomina Electrónica",
		},

		// SMTP TAB
		{
			text: "URL de HOST SMTP",
			value: "smtp_host",
			type: "text",
			editonly: true,
			tab: "Mail de Distribucion",
		},
		{
			text: "Usuario de SMTP",
			value: "smtp_username",
			type: "text",
			editonly: true,
			tab: "Mail de Distribucion",
		},
		{
			text: "Contrasena de SMTP",
			value: "smtp_password",
			type: "text",
			editonly: true,
			tab: "Mail de Distribucion",
		},
		{
			text: "Puerto de SMTP",
			value: "smtp_port",
			type: "number",
			editonly: true,
			default: 587,
			tab: "Mail de Distribucion",
		},
		{
			text: "Encriptacion de SMTP",
			value: "smtp_encryption",
			type: "text",
			editonly: true,
			default: "",
			tab: "Mail de Distribucion",
		},

		// IMAP Tab

		{
			text: "URL de HOST IMAP",
			value: "imap_host",
			type: "text",
			editonly: true,
			tab: "Mail de Recepcion",
		},
		{
			text: "Usuario de IMAP",
			value: "imap_username",
			type: "text",
			editonly: true,
			tab: "Mail de Recepcion",
		},
		{
			text: "Contrasena de IMAP",
			value: "imap_password",
			type: "text",
			editonly: true,
			tab: "Mail de Recepcion",
		},
		{
			text: "Puerto de IMAP",
			value: "imap_port",
			type: "number",
			editonly: true,
			default: 993,
			tab: "Mail de Recepcion",
		},
		{
			text: "Encriptacion de IMAP",
			value: "imap_encryption",
			type: "select",
			default: "ssl",
			options: ["ssl", "tls", { text: "--", valuse: "" }],
			editonly: true,
			tab: "Mail de Recepcion",
		},
		{
			text: "Protocolo de conexion IMAP",
			value: "imap_protocol",
			type: "select",
			default: "imap",
			options: ["imap", "pop"],
			editonly: true,
			tab: "Mail de Recepcion",
		},
	],
};
export default clientes;
