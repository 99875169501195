export const validations = {
	referencia: "required|min:2|max:200",
	name: "required|min:2|max:200",
	description: "string|min:2|max:5000",
	precio: "required|number|min:0",
	precio_compra: "number|min:0",
	categoria_id: "nullable|exists:App\\Models\\CategoriasProductos,id",
	notas: "string|max:255",
	stock: "number",
	impuesto: "number",
	discount: "number",
	unit_id: "nullable|exists:App\\Models\\Unit,id",
	user_id: "nullable|exists:App\\Models\\User,id",
	entidad_id: "nullable|exists:App\\Models\\Entidad,id",
	proveedor_id: "nullable|exists:App\\Models\\Proveedor,id",
	minimum_stock: "number|nullable|min:-1",
	maximum_stock: "number|nullable|min:-1",
	imagen: "file",
	extra: "array",
	advanced_stock: "array",
};

export const model = {
	model: "producto",
	filters: [
		{
			name: "categoria_id",
			label: "category",
			type: "relation",
			resource: "categorias-productos",
			handler: (v) => `scope[ofCategoria]=${v}`,
			searchLogic: (v, i) => `${i.categoria_id}` == `${v}`,
		},
		{
			name: "actives",
			label: "actives",
			type: "boolean",
			handler: () => `where[active]=1`,
			searchLogic: (v, i) => !!i.active,
		},
		{
			name: "starreds",
			label: "starreds",
			type: "boolean",
			handler: () => `where[destacado]=1`,
			searchLogic: (v, i) => !!i.destacado,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			visible: true,
			align: "center",
			type: "text",
			readonly: true,
		},
		{
			text: "reference",
			value: "referencia",
			visible: true,
			type: "text",
			tab: "product",
		},
		{
			text: "name",
			value: "name",
			visible: true,
			type: "text",
			tab: "product",
		},
		{
			value: "description",
			text: "description",
			required: false,
			default: null,
			type: "richtext",
			tab: "product",
			height: "200px",
			visible: false,
		},
		{
			text: "price",
			value: "precio",
			visible: true,
			type: "currency",
			tab: "product",
			default: 1,
			min: 0,
		},
		{
			value: "precio_compra",
			text: "purchase price",
			required: true,
			default: 0,
			type: "number",
			step: "any",
			min: 0,
			tab: "product",
		},
		{
			text: "category",
			value: "categoria_id",
			visible: true,
			attribute: "nombre",
			type: "relation",
			entity: "categoria",
			model: "categoriaProducto",
			tab: "product",
			resource: "categorias-productos",
		},
		{
			value: "notas",
			text: "notas",
			required: false,
			default: null,
			type: "textarea",
			tab: "advanced",
			visible: false,
		},
		{
			value: "stock",
			text: "stock",
			required: true,
			default: 1,
			type: "number",
			tab: "product",
			visible: true,
		},
		{
			value: "impuesto",
			text: "tax",
			required: true,
			default: 19,
			type: "number",
			tab: "product",
			visible: false,
		},
		{
			value: "discount",
			text: "discount",
			required: false,
			default: 0,
			type: "number",
			step: "any",
			tab: "product",
			visible: false,
		},
		{
			value: "destacado",
			text: "starred",
			required: true,
			default: false,
			type: "boolean",
			tab: "product",
			visible: true,
		},
		{
			value: "active",
			text: "active",
			required: true,
			default: true,
			type: "boolean",
			tab: "product",
			visible: false,
		},
		{
			value: "es_vendible_sin_stock",
			text: "Es vendible sin stock",
			required: true,
			default: true,
			type: "boolean",
			tab: "product",
			visible: false,
		},
		{
			value: "mostrar_stock",
			text: "Mostrar stock en Tienda",
			required: true,
			default: true,
			type: "boolean",
			tab: "product",
			visible: false,
		},
		{
			value: "mostrar_precio",
			text: "Mostrar Precio en Tienda",
			required: true,
			default: true,
			type: "boolean",
			tab: "product",
			visible: false,
		},
		{
			value: "unit_id",
			text: "unit",
			required: false,
			default: 1,
			type: "relation",
			visible: false,
			attribute: "name",
			resource: "units",
			tab: "advanced",
			entity: "unit",
		},
		{
			value: "user_id",
			text: "Usuario",
			required: false,
			default: null,
			type: "relation",
			visible: false,
			attribute: "full_name",
			tab: "advanced",
			entity: "user",
		},
		{
			value: "entidad_id",
			text: "entity",
			required: false,
			default: null,
			type: "relation",
			visible: false,
			entity: "entidad",
			attribute: "full_name",
			tab: "advanced",
			resource: "entidades",
		},
		{
			value: "proveedor_id",
			text: "supplier",
			required: false,
			default: null,
			entity: "proveedor",
			type: "relation",
			visible: false,
			attribute: "full_name",
			resource: "proveedores",
			tab: "advanced",
		},
		{
			value: "minimum_stock",
			text: "minimum stock",
			required: false,
			default: -1,
			type: "number",
			visible: false,
			tab: "advanced",
		},
		{
			value: "maximum_stock",
			text: "maximum stock",
			required: false,
			default: -1,
			type: "number",
			visible: false,
			tab: "advanced",
		},
		{
			text: "image",
			value: "image_url",
			placeholder: "image_url",
			field: "imagen",
			aspectRatio: 1,
			visible: true,
			type: "image",
			tab: "product",
			fullWidth: true,
		},
		{
			value: "extra",
			text: "extra",
			required: false,
			default: null,
			type: "extra",
			editonly: true,
			tab: "advanced",
			fullWidth: true,
		},
		{
			value: "advanced_stock",
			text: "advanced stock",
			required: false,
			default: () => [],
			type: "component",
			component: "advanced-stock-input",
			editonly: true,
			tab: "advanced stock",
			fullWidth: true,
		},
	],
};

for (let i = 2; i <= 20; i++) {
	model.headers.push({
		value: "precio" + i,
		text: "Precio " + i,
		tab: "prices",
		type: "number",
		min: 0,
		rules: "min:0",
		visible: false,
	});
}
for (let i = 2; i <= 20; i++) {
	model.headers.push({
		value: "precio_compra" + i,
		text: "Precio Compra " + i,
		tab: "prices",
		type: "number",
		min: 0,
		rules: "min:0",
		visible: false,
	});
}
// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
