export const validations = {
	titulo: "required|min:3",
	descripcion: "min:3",
	activo: "boolean",
	categoria_id: "required|exists:AppModelsCategoriaDocumentos",
	file: "file|required|max:10240",
	expired_at: "date|after:today",
	worker_id: "nullable|exists:AppModelsWorker",
	cliente_id: "nullable|exists:AppModelsCliente",
	proveedor_id: "nullable|exists:AppModelsProveedor",
};

export const model = {
	model: "documento",
	filters: [
		{
			name: "categoria_id",
			label: "category",
			type: "relation",
			resource: "categorias-documentos",
			handler: (v) => `whereIn[categoria_id]=${v}`,
			searchLogic: (v, i) => `${i.categoria_id}` == `${v}`,
		},
	],
	headers: [
		{
			value: "titulo",
			type: "text",
			text: "Titulo",
			tab: "document",
		},
		{
			value: "activo",
			text: "Activo",
			type: "checkbox",
			default: () => true,
			tab: "document",
		},
		{
			value: "descripcion",
			text: "description",
			type: "textarea",
			tab: "document",
		},
		{
			value: "categoria_id",
			text: "category",
			type: "relation",
			attribute: "full_name",
			entity: "categoria",
			resource: "categorias-documentos",
			tab: "document",
		},
		{
			value: "file",
			text: "file",
			type: "file",
			tab: "document",
			rules: "required",
		},
		{
			value: "expired_at",
			text: "expired_at",
			type: "date",
			tab: "document",
		},
		{
			value: "worker_id",
			text: "worker",
			type: "relation",
			attribute: "full_name",
			entity: "worker",
			resource: "workers",
			tab: "relations",
			modulo: "tablas",
			can: "Editar Trabajadores",
		},
		{
			value: "cliente_id",
			text: "client",
			type: "relation",
			attribute: "full_name",
			entity: "cliente",
			resource: "clientes",
			tab: "relations",
			modulo: "clientes",
			can: "Editar Clientes",
		},
		{
			value: "proveedor_id",
			text: "supplier",
			type: "relation",
			attribute: "full_name",
			entity: "proveedor",
			resource: "proveedores",
			tab: "relations",
			modulo: "proveedores",
			can: "Editar Proveedores",
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
