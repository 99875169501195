<template>
	<v-card class="v-card">
		<!-- Header -->
		<slot name="header">
			<v-toolbar dense flat :color="color" :dark="!!color">
				<v-toolbar-title class="text-capitalize subtitle-2 ma-0 pa-0 row">
					<slot name="title">
						{{ item.name || item.full_name || item.nombre || item.item || item.id }}
					</slot>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<span class="caption hidden-xs-only" v-can="'SuperAdmin'" v-if="item.id"> ID: {{ item.id }} &nbsp; </span>
				<v-icon @click="cancel()">close</v-icon>
				<template v-if="!oneTab" v-slot:extension>
					<v-tabs v-model="tab" background-color="transparent" dark centered center-active slider-color="white">
						<v-tab v-for="(tab, key) in tabs" :key="key + '-tab'" :href="`#${key}`">{{ key | trans }}</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
		</slot>
		<!-- Form -->
		<v-card-text style="height: 100%">
			<!-- No Tabs -->
			<v-container fill-height v-if="oneTab">
				<v-layout row wrap grid-list-xs>
					<v-flex xs12>
						<v-list dense>
							<template v-for="(header, i) in $data.$headers">
								<v-list-item row wrap :key="i" v-if="header.type != 'location_picker'">
									<v-list-item-content>
										<div class="subtitle-2 text-justify text-capitalize">{{ header.text | trans }}:</div>
									</v-list-item-content>
									<v-list-item-action style="width: 200px" class="subtitle-1 align-end text-right">
										<d-row mode="view" :item="item" :header="header"></d-row>
									</v-list-item-action>
								</v-list-item>
								<v-list-item v-else :key="i">
									<v-list-item-content>
										<d-row mode="view" :item="item" :header="header"></d-row>
									</v-list-item-content>
								</v-list-item>
								<!-- <v-divider :key="i + '-divider'"></v-divider> -->
							</template>
						</v-list>
					</v-flex>
				</v-layout>
			</v-container>

			<!-- Tabs -->
			<v-tabs-items touchless v-else v-model="tab">
				<v-tab-item v-for="(tab, key) in tabs" class="pa-2" :key="key + '-tab-item'" :value="key">
					<v-container fluid fill-height>
						<v-layout row wrap>
							<template v-for="(header, i) in tab">
								<v-list-item row wrap :key="i">
									<v-list-item-content>
										<div class="subtitle-2 text-justify text-capitalize">{{ header.text | trans }}:</div>
									</v-list-item-content>
									<v-list-item-action>
										<div class="subtitle-1 align-end text-right">
											<d-row mode="view" :item="item" :header="header"></d-row>
										</div>
									</v-list-item-action>
								</v-list-item>
								<!-- <v-divider :key="i + '-divider'"></v-divider> -->
							</template>
						</v-layout>
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
		<!-- Actions -->
		<slot name="actions" :cancel-action="cancelAction" :save-action="save">
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" depressed @click="cancel()">
					{{ "crud.close" | trans }}
				</v-btn>
			</v-card-actions>
		</slot>
	</v-card>
</template>
<script>
import Vue from "vue";
import DefaultRow from "@/components/main/DefaultRow";
import _ from "lodash";
export default {
	beforeMount() {
		Vue.component("d-row", DefaultRow);
	},
	name: "CrudViewer",
	mounted() {
		this.$data.$headers = this.headers.filter((h) => h.editonly !== true);
		this.tabs = _.groupBy(this.$data.$headers, "tab");
		if (this.tabs["undefined"]) {
			this.tabs["info"] = this.tabs["undefined"];
			delete this.tabs["undefined"];
		}
		this.tab = Object.keys(this.tabs)[0];
		this.oneTab = Object.keys(this.tabs).length === 1;
		this.oneTab = true;
	},
	data() {
		return {
			validation: false,
			$headers: [],
			tabs: [],
			tab: "info",
			oneTab: true,
			saving: false,
		};
	},
	methods: {
		async save() {
			this.saving = true;
			this.$emit("save", this.item);
			var resp = await this.saveAction(this.item);
			this.saving = false;
			return resp;
		},
		cancel() {
			this.saving = false;
			this.$emit("cancel");
			this.cancelAction(false);
		},
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		color: {
			type: String,
			default: "primary darken-3",
		},
		saveAction: {
			type: Function,
		},
		cancelAction: {
			type: Function,
		},
	},
};
</script>
