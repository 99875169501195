var routes = [
	{
		path: "/entities",
		name: "entities",
		component: function () {
			return import(/* webpackChunkName: "Crud" */ "@/views/Entidades.vue");
		},
	},
	{
		path: "/clients",
		name: "clients",
		component: function () {
			return import(/* webpackChunkName: "Crud" */ "@/views/Clientes.vue");
		},
	},
	{
		path: "/:resource/reorder",
		name: "Reorder",
		component: function () {
			return import(/* webpackChunkName: "Crud" */ "@/views/Reorder.vue");
		},
		props: true,
		meta: {
			back: -1,
		},
	},
	{
		path: "/:resource/:id/images",
		name: "Images",
		component: function () {
			return import(/* webpackChunkName: "Images" */ "@/components/main/ImagesViewer.vue");
		},
		props: true,
		meta: {
			back: -1,
		},
	},
	// Nomina
	{
		path: "/workers",
		name: "workers",
		component: function () {
			return import(/* webpackChunkName: "workers" */ "@/views/Workers.vue");
		},
	},
	{
		path: "/novelties",
		name: "novelties",
		component: function () {
			return import(/* webpackChunkName: "novelties" */ "@/views/Novelties.vue");
		},
	},
	{
		path: "/nomina/:year/:month",
		name: "Nomina",
		component: function () {
			return import(/* webpackChunkName: "nomina" */ "@/views/Nomina.vue");
		},
		props: true,
	},
	{
		path: "/charges",
		name: "charges",
		component: function () {
			return import(/* webpackChunkName: "charges" */ "@/views/Charges.vue");
		},
	},
	{
		path: "/concepts",
		name: "concepts",
		component: function () {
			return import(/* webpackChunkName: "concepts" */ "@/views/Concepts.vue");
		},
	},
	{
		path: "/banks",
		name: "banks",
		component: function () {
			return import(/* webpackChunkName: "banks" */ "@/views/Banks.vue");
		},
	},
];

export default routes;
