import moment from "moment";
import { api } from "@/services/api";

import tickets from "@/models/tickets";
import CategoriasTickets from "@/models/categoriasTickets";
import workers from "@/models/workers";
import documentos from "@/models/documentos";
import productos from "@/models/productos";
import pedidos from "@/models/pedidos";
import users from "@/models/users";
import roles from "@/models/roles";
import permissions from "@/models/permissions";
import sliders from "@/models/sliders";
import entidades from "@/models/entidades";
import purchase_orders from "@/models/purchase_orders";
import banks from "@/models/banks";
import concepts from "@/models/concepts";
import novelties from "@/models/novelties";
import charges from "@/models/charges";
import companies from "@/models/companies";
import proveedores from "@/models/proveedores";
import bodegas from "@/models/bodegas";
import conductores from "@/models/conductores";
import vehiculos from "@/models/vehiculos";
import puestos from "@/models/puestos";
import clientes from "@/models/clientes";

var CategoriasDocumentos = {
	model: "CategoriaTicket",
	filters: [
		{
			name: "parent_id",
			label: "parent",
			type: "relation",
			resource: "categorias-documentos?whereNull[]=parent_id",
			handler: (v) => `whereIn[parent_id]=${v}`,
			searchLogic: (v, i) => `${i.parent_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			visible: true,
			align: "center",
			type: "text",
			readonly: true,
		},
		{
			text: "name",
			value: "nombre",
			visible: true,
			type: "text",
			tab: "category",
			rules: "required|min:3",
		},
		{
			text: "parent",
			value: "parent_id",
			visible: true,
			fullWidth: true,
			attribute: "full_name",
			type: "relation",
			entity: "parent",
			tab: "category",
			resource: "categorias-documentos",
		},
	],
};

var invoicesProveedores = {
	model: "InvoiceProveedor",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `whereDategte[fecha]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `whereDatelwe[fecha]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha) < moment(v),
		},
		{
			name: "status",
			label: "status",
			type: "select",
			options: [
				{
					text: "emitida",
					value: "emitida",
				},
				{
					text: "pagada",
					value: "pagada",
				},
				{
					text: "anulada",
					value: "anulada",
				},
				{
					text: "vencida",
					value: "vencida",
				},
				{
					text: "rechazada",
					value: "rechazada",
				},
			],
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[estado]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.estado) > -1;
				return true;
			},
		},
	],
	headers: [
		{
			text: "number",
			value: "number",
			visible: true,
			align: "right",
			type: "text",
			tab: "invoice",
			rules: "required",
		},
		{
			text: "supplier",
			value: "proveedor_id",
			visible: true,
			attribute: "full_name",
			default: null,
			type: "relation",
			resource: "proveedores",
			entity: "proveedor",
			tab: "invoice",
			rules: "required",
		},
		{
			text: "warehouse",
			value: "bodega_id",
			visible: true,
			default: null,
			attribute: "nombre",
			type: "relation",
			resource: "bodegas",
			entity: "bodega",
			tab: "invoice",
		},
		{
			text: "suppliers.orden_compra",
			value: "orden_compra",
			visible: true,
			align: "left",
			type: "text",
			tab: "invoice",
		},
		{
			text: "date",
			value: "fecha",
			visible: true,
			align: "left",
			type: "date",
			default: () => moment().format("YYYY-MM-DD"),
			format: "LL",
			tab: "invoice",
			rules: "required",
		},
		{
			text: "status",
			value: "estado",
			visible: true,
			align: "left",
			type: "select",
			chip: true,
			tab: "invoice",
			default: "emitida",
			rules: "required",
			options: [
				{
					text: "Emitida",
					value: "emitida",
				},
				{
					text: "Pagada",
					value: "pagada",
				},
				{
					text: "Anulada",
					value: "anulada",
				},
				{
					text: "Vencida",
					value: "vencida",
				},
				{
					text: "Rechazada",
					value: "rechazada",
				},
			],
		},
		{
			text: "address",
			value: "direccion",
			type: "textarea",
			editonly: false,
			tab: "invoice",
			rules: "required",
		},
		{
			text: "user",
			value: "user_id",
			visible: false,
			attribute: "full_name",
			type: "relation",
			entity: "user",
			resource: "users",
			tab: "invoice",
		},
		{
			text: "total",
			value: "total",
			readonly: true,
			align: "right",
			type: "currency",
		},
		{
			text: "Forma de Pago",
			value: "forma_pago",
			visible: false,
			type: "select",
			tab: "invoice",
			rules: "required",
			default: "Débito",
			options: [
				{
					text: "Débito",
					value: "Débito",
				},
				{
					text: "Crédito",
					value: "Crédito",
				},
			],
		},
		{
			text: "Método de Pago",
			value: "pago",
			visible: true,
			type: "select",
			tab: "invoice",
			chip: true,
			rules: "required",
			default: "efectivo",
			options: [
				{ value: "efectivo", text: "Efectivo" },
				{ value: "tarjeta Débito", text: "Tarjeta Débito" },
				{ value: "tarjeta credito", text: "Tarjeta Crédito" },
				{ value: "cheque", text: "Cheque" },
				{ value: "otro", text: "Otro" },
				{ value: "credito", text: "Crédito" },
			],
		},
		{
			text: "items",
			value: "items",
			editonly: true,
			type: "component",
			component: "item-form-proveedores",
			fullWidth: true,
			min: 1,
			tab: "invoice",
		},
	],
};

var CategoriasProductos = {
	model: "categoriaProducto",
	filters: [
		{
			name: "parent_id",
			label: "parent",
			type: "relation",
			resource: "categorias-productos?whereNull[]=parent_id",
			handler: (v) => `whereIn[parent_id]=${v}`,
			searchLogic: (v, i) => `${i.parent_id}` == `${v}`,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			visible: true,
			align: "center",
			type: "text",
			readonly: true,
		},
		{
			text: "name",
			value: "nombre",
			visible: true,
			type: "text",
			tab: "category",
			rules: "required",
		},
		{
			text: "entity",
			value: "entidad_id",
			visible: true,
			attribute: "full_name",
			type: "relation",
			entity: "entidad",
			tab: "category",
			resource: "entidades",
		},
		{
			text: "description",
			value: "descripción",
			required: false,
			default: null,
			type: "textarea",
			fullWidth: true,
			tab: "category",
			visible: true,
		},
		{
			text: "parent",
			value: "parent_id",
			visible: true,
			fullWidth: true,
			attribute: "nombre",
			type: "relation",
			entity: "parent",
			tab: "category",
			resource: "categorias-productos",
		},
		{
			text: "image",
			value: "image_url",
			placeholder: "image_url",
			field: "imagen",
			visible: true,
			type: "image",
			tab: "category",
			aspectRatio: 1,
		},
		{
			text: "banner",
			value: "banner_url",
			placeholder: "banner_url",
			field: "banner_imagen",
			visible: true,
			type: "image",
			tab: "category",
			aspectRatio: 16 / 9,
		},
		{
			text: "color",
			value: "color",
			visible: true,
			type: "color",
			tab: "category",
		},
	],
};

var invoices = {
	model: "invoice",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `whereDategte[fecha]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `whereDatelwe[fecha]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.fecha) < moment(v),
		},
		{
			name: "cliente_id",
			label: "clients",
			type: "relation",
			resource: "clientes",
			ajax: true,
			multiple: true,
			handler: (v) => (v && v.length > 0 ? `whereIn[cliente_id]=${v}` : ``),
			searchLogic: (v, i) => `${i.cliente_id}` == `${v}`,
		},
		{
			name: "status",
			label: "status",
			type: "select",
			options: [
				{
					text: "emitida",
					value: "emitida",
				},
				{
					text: "pagada",
					value: "pagada",
				},
				{
					text: "anulada",
					value: "anulada",
				},
				{
					text: "vencida",
					value: "vencida",
				},
				{
					text: "rechazada",
					value: "rechazada",
				},
			],
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[estado]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.estado) > -1;
				return true;
			},
		},
	],
	headers: [
		{
			text: "number",
			value: "number",
			visible: true,
			align: "right",
			type: "text",
			tab: "invoice",
			rules: "required",
		},
		{
			text: "orden_compra",
			value: "orden_compra",
			visible: true,
			align: "left",
			type: "text",
			tab: "invoice",
		},
		{
			text: "date",
			value: "fecha",
			visible: true,
			align: "left",
			type: "date",
			default: () => moment().format("YYYY-MM-DD"),
			format: "LL",
			tab: "invoice",
			rules: "required",
		},
		{
			text: "status",
			value: "estado",
			visible: true,
			align: "left",
			type: "select",
			chip: true,
			tab: "invoice",
			default: "emitida",
			rules: "required",
			options: [
				{
					text: "Emitida",
					value: "emitida",
				},
				{
					text: "Pagada",
					value: "pagada",
				},
				{
					text: "Anulada",
					value: "anulada",
				},
				{
					text: "Vencida",
					value: "vencida",
				},
				{
					text: "Rechazada",
					value: "rechazada",
				},
			],
		},
		{
			text: "Estado Segun DIAN",
			value: "estado_dian",
			readonly: true,
			visibleIf: () => api.user.canSendToDian,
			align: "left",
			type: "text",
			chip: true,
		},
		{
			text: "CUFE",
			value: "codigo_dian",
			visible: false,
			readonly: true,
			visibleIf: () => api.user.canSendToDian,
			align: "left",
			type: "text",
		},
		{
			text: "client",
			value: "cliente_id",
			visible: true,
			attribute: "full_name",
			type: "relation",
			entity: "cliente",
			resource: "clientes",
			tab: "invoice",
			ajax: true,
			rules: "required",
		},
		{
			text: "address",
			value: "direccion",
			type: "textarea",
			editonly: true,
			tab: "invoice",
			rules: "required",
		},
		{
			text: "entity",
			value: "entidad_id",
			visible: false,
			attribute: "full_name",
			type: "relation",
			entity: "entidad",
			resource: "entidades",
			tab: "invoice",
		},
		{
			text: "expiration",
			value: "vencimiento",
			visible: false,
			align: "left",
			type: "date",
			format: "LL",
			tab: "invoice",
			rules: "required_if:forma_pago,Crédito",
		},
		{
			text: "note",
			value: "nota",
			visible: false,
			rows: 1,
			type: "textarea",
			tab: "invoice",
		},
		{
			text: "total",
			value: "total",
			readonly: true,
			sortable: false,
			align: "right",
			type: "currency",
		},
		{
			text: "Forma de Pago",
			value: "forma_pago",
			visible: false,
			type: "select",
			tab: "invoice",
			rules: "required",
			default: "Débito",
			options: [
				{
					text: "Débito",
					value: "Débito",
				},
				{
					text: "Crédito",
					value: "Crédito",
				},
			],
		},
		{
			text: "Método de Pago",
			value: "pago",
			readonly: true,
			visible: true,
			type: "text",
			tab: "invoice",
			chip: true,
			rules: "required",
		},
		{
			text: "Método de Pago",
			value: "pago",
			editonly: true,
			type: "component",
			component: "check-payment",
			tab: "invoice",
			rules: "required",
			default: "efectivo",
			fullWidth: true,
			props: {
				validate: true,
				outlined: true,
				dense: true,
				options: [
					{ value: "efectivo", text: "Efectivo" },
					{ value: "tarjeta Débito", text: "Tarjeta Débito" },
					{ value: "tarjeta credito", text: "Tarjeta Crédito" },
					{ value: "cheque", text: "Cheque" },
					{ value: "otro", text: "Otro" },
					{ value: "credito", text: "Crédito" },
				],
			},
		},

		{
			text: "items",
			value: "items",
			editonly: true,
			type: "component",
			component: "item-form",
			fullWidth: true,
			min: 1,
			tab: "invoice",
		},
	],
};

var annotations = {
	model: "annotation",
	load: "creator,image,entidad",
	order: "-timestamp",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `whereDategte[timestamp]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.timestamp) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `whereDatelwe[timestamp]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.timestamp) < moment(v),
		},
		{
			name: "user",
			label: "users",
			type: "relation_multiple",
			attribute: "full_name",
			model: "user",
			resource: "users",
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[user_id]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.user_id) > -1;
				return true;
			},
		},
	],
	headers: [
		{
			text: "type",
			value: "type",
			type: "text",
			default: () => "nota",
			rules: "required",
		},
		{
			text: "timestamp",
			value: "timestamp",
			type: "datetime",
			default: () => moment().format("YYYY-MM-DD HH:mm:ss"),
			rules: "required",
			tab: "annotation",
		},
		{
			text: "quantity",
			value: "quantity",
			type: "number",
			default: 0,
			tab: "annotation",
		},
		{
			text: "user",
			value: "user_id",
			type: "relation",
			rules: "required",
			visible: true,
			entity: "user",
			resource: "users",
			align: "center",
			attribute: "full_name",
			tab: "annotation",
		},
		{
			text: "worker",
			value: "worker_id",
			type: "relation",
			visible: true,
			entity: "worker",
			resource: "workers",
			align: "center",
			attribute: "full_name",
			tab: "annotation",
		},
		{
			text: "entity",
			value: "entidad_id",
			type: "relation",
			visible: true,
			entity: "entidad",
			resource: "entidades",
			align: "center",
			attribute: "full_name",
			tab: "annotation",
		},
		{
			text: "job place",
			value: "puesto_id",
			type: "relation",
			visible: true,
			entity: "puesto",
			resource: "puestos",
			align: "center",
			attribute: "nombre",
			tab: "annotation",
		},
		{
			text: "note",
			value: "note",
			type: "textarea",
			visible: false,
			tab: "annotation",
		},
		{
			text: "extra",
			value: "extra",
			type: "extra",
			visible: false,
			tab: "extra",
		},
	],
};

var schedules = {
	model: "schedule",
	load: "user,worker,entidad,puesto",
	order: "-start",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `whereDategte[start]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.start) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `whereDatelwe[end]=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.end) < moment(v),
		},
		{
			name: "user",
			label: "users",
			type: "relation_multiple",
			attribute: "full_name",
			model: "user",
			resource: "users",
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[user_id]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.user_id) > -1;
				return true;
			},
		},
		{
			name: "worker",
			label: "workers",
			type: "relation_multiple",
			attribute: "full_name",
			model: "worker",
			resource: "workers",
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[worker_id]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.worker_id) > -1;
				return true;
			},
		},
		{
			name: "entidad",
			label: "entities",
			type: "relation_multiple",
			attribute: "full_name",
			model: "entidad",
			resource: "entidades",
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[entidad_id]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.entidad_id) > -1;
				return true;
			},
		},
		{
			name: "puesto",
			label: "puestos",
			type: "relation_multiple",
			attribute: "full_name",
			model: "puesto",
			resource: "puestos",
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[puesto_id]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.puesto_id) > -1;
				return true;
			},
		},
	],
	headers: [
		{
			text: "user",
			value: "user_id",
			type: "relation",
			entity: "user",
			resource: "users",
			align: "center",
			attribute: "full_name",
			tab: "schedule",
		},
		{
			text: "worker",
			value: "worker_id",
			type: "relation",
			visible: true,
			entity: "worker",
			resource: "workers",
			align: "center",
			attribute: "full_name",
			tab: "schedule",
		},
		{
			text: "date.start",
			value: "start",
			type: "datetime",
			default: () => moment().add(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
			rules: "required",
			tab: "schedule",
		},
		{
			text: "date.end",
			value: "end",
			type: "datetime",
			tab: "schedule",
		},
		{
			text: "entity",
			value: "entidad_id",
			type: "relation",
			entity: "entidad",
			resource: "entidades",
			align: "center",
			attribute: "full_name",
			tab: "schedule",
		},
		{
			text: "job place",
			value: "puesto_id",
			type: "relation",
			entity: "puesto",
			resource: "puestos",
			align: "center",
			attribute: "nombre",
			tab: "schedule",
		},
		{
			text: "note",
			value: "note",
			type: "textarea",
			visible: false,
			tab: "schedule",
		},
		{
			text: "observations",
			value: "observations",
			type: "textarea",
			visible: false,
			tab: "schedule",
		},
		{
			text: "extra",
			value: "extra",
			type: "extra",
			visible: false,
			tab: "extra",
		},
	],
};

var horarios = {
	model: "horario",
	load: "entidad",
	order: "id",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
	],
	headers: [
		{
			value: "nombre",
			text: "name",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "monday",
			text: "Lunes",
			required: true,
			default: '[["08:00","17:00"]]',
			type: "hidden",
			visible: false,
		},
		{
			value: "tuesday",
			text: "Martes",
			required: true,
			default: '[["08:00","17:00"]]',
			type: "hidden",
			visible: false,
		},
		{
			value: "wednesday",
			text: "Miercoles",
			required: true,
			default: '[["08:00","17:00"]]',
			type: "hidden",
			visible: false,
		},
		{
			value: "thursday",
			text: "Jueves",
			required: true,
			default: '[["08:00","17:00"]]',
			type: "hidden",
			visible: false,
		},
		{
			value: "friday",
			text: "Viernes",
			required: true,
			default: '[["08:00","17:00"]]',
			type: "hidden",
			visible: false,
		},
		{
			value: "saturday",
			text: "Sabado",
			required: true,
			default: "[]",
			type: "hidden",
			visible: false,
		},
		{
			value: "sunday",
			text: "Domingo",
			required: true,
			default: "[]",
			type: "hidden",
			visible: false,
		},
		{
			value: "entidad_id",
			text: "entity",
			required: false,
			type: "relation",
			resource: "entidades",
			visible: true,
			attribute: "full_name",
			entity: "entidad",
		},
		{
			value: "active",
			text: "active",
			required: true,
			default: true,
			type: "boolean",
			visible: true,
		},
		{
			value: "note",
			text: "notes",
			type: "textarea",
			visible: true,
		},
	],
};

var units = {
	model: "unit",
	filters: [],
	headers: [
		{
			text: "name",
			value: "name",
			type: "text",
			rules: "required",
		},
		{
			text: "short",
			value: "short",
			type: "text",
			rules: "required",
		},
		{
			text: "description",
			value: "description",
			type: "textarea",
		},
		{
			text: "rate conversion",
			value: "rate_conversion",
			type: "number",
			min: 0,
			step: "any",
		},
	],
};

var transports = {
	model: "transport",
	filters: [],
	headers: [
		{
			text: "name",
			value: "name",
			type: "text",
			rules: "required",
			fullWidth: true,
		},
		{
			text: "icon",
			value: "icon",
			type: "url",
			rules: "min:3",
			editonly: true,
		},
		{
			text: "icon",
			value: "icon",
			type: "image",
			readonly: true,
		},
		{
			text: "active",
			value: "active",
			type: "checkbox",
			default: true,
		},
		{
			text: "conditions",
			value: "conditions",
			type: "component",
			component: "transport-conditions",
			rules: "required",
			fullWidth: true,
			editonly: true,
			default: () => {
				return [
					{
						option: "",
						charge: 0,
						min_amount: 0,
						max_amount: null,
					},
				];
			},
		},
	],
};

export default {
	users,
	clientes,
	tickets,
	companies,
	documentos,
	productos,
	invoices,
	entidades,
	proveedores,
	purchase_orders,
	bodegas,
	pedidos,
	annotations,
	schedules,
	horarios,
	workers,
	novelties,
	units,
	sliders,
	puestos,
	transports,
	banks,
	concepts,
	charges,
	roles,
	permissions,
	conductores,
	vehiculos,
	"invoices-proveedores": invoicesProveedores,
	"categorias-productos": CategoriasProductos,
	"categorias-tickets": CategoriasTickets,
	"categorias-documentos": CategoriasDocumentos,
};
