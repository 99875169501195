import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[(_vm.reference)?_c(VTextField,{attrs:{"label":_vm._f("trans")(_vm.label),"placeholder":_vm.reference.address,"type":"text","flat":"","solo":"","single-line":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('input', _vm.reference)}},model:{value:(_vm.reference.name),callback:function ($$v) {_vm.$set(_vm.reference, "name", $$v)},expression:"reference.name"}}):_vm._e(),_c('newton-map',{style:(`height:${_vm.height};width:${_vm.width};cursor:pointer !important`),attrs:{"auto-center":false},on:{"load":_vm.getMap}},[_c('div',{staticStyle:{"width":"40%","top":"5px","left":"5px","position":"absolute"}},[_c(VTextField,{attrs:{"solo":"","hide-details":"","dense":"","loading":_vm.loading,"light":"","append-icon":"search","clearable":"","label":_vm._f("trans")('crud.search')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.searchAddress.apply(null, arguments)},"click:append":_vm.searchAddress},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_vm._l((_vm.geolocations),function(loc){return _c(VList,{key:loc.id,attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.map.setCenter([loc.lon, loc.lat]);
							_vm.geolocations = [];}}},[_c(VListItemAvatar,{attrs:{"size":"16"}},[_c(VImg,{attrs:{"src":loc.icon}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(loc.display_name)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(loc.lat)+", "+_vm._s(loc.lon)+" ")])],1)],1)],1)})],2),_c('MglMarker',{attrs:{"draggable":"","coordinates":_vm.coordinates,"color":"green"},on:{"dragend":_vm.setDrag}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }