import moment from "moment";

export const validations = {
	name: "required|min:2|max:50",
};

export const model = {
	model: "conductor",
	filters: [],
	headers: [
		{
			text: "name",
			tab: "driver",
			value: "full_name",
			visible: true,
			field: "nombres",
			align: "center",
			type: "text",
		},
		{
			value: "apellidos",
			text: "last_name",
			tab: "driver",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "cedula",
			text: "document",
			tab: "driver",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "email",
			text: "email",
			tab: "driver",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "nacimiento",
			text: "birthday",
			tab: "advanced",
			default: () => moment().format("YYYY-MM-DD"),
			required: true,
			type: "date",
			visible: true,
		},
		// {
		// 	value: "sangre",
		// 	text: "sangre",
		// tab: "driver",
		// 	required: true,
		// 	default: null,
		// 	type: "text",
		// 	visible: true,
		// },
		{
			value: "sexo",
			text: "sexo",
			required: true,
			tab: "advanced",
			default: "M",
			type: "select",
			options: ["M", "F", "--"],
			visible: false,
		},
		{
			value: "estadoCivil",
			text: "Estado Civil",
			tab: "advanced",
			required: true,
			default: "Soltero",
			type: "select",
			options: ["Soltero", "Casado", "Divorciado", "Viudo"],
			visible: false,
		},
		{
			value: "telefono",
			text: "phone_number",
			tab: "driver",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "nota",
			text: "nota",
			tab: "advanced",
			required: true,
			default: "N/A",
			type: "text",
			visible: false,
		},
		{
			value: "direccion",
			text: "address",
			tab: "driver",
			required: true,
			default: null,
			type: "text",
			visible: false,
		},
		{
			value: "ingreso",
			text: "Fecha de Ingreso A la Empresa",
			default: () => moment().format("YYYY-MM-DD"),
			tab: "advanced",
			required: false,
			type: "date",
			visible: true,
		},
		{
			value: "egreso",
			text: "Fecha de Egreso A la Empresa",
			tab: "advanced",
			required: false,
			default: null,
			type: "date",
			visible: true,
		},
		// {
		// 	value: "eps_id",
		// 	text: "Eps",
		// tab: "driver",
		// 	required: false,
		// 	default: null,
		// 	type: "relation",
		// 	visible: true,
		// 	attribute: "full_name",
		// 	resource: "eps",
		// 	entity: "eps",
		// },
		// {
		// 	value: "arl_id",
		// 	text: "Arl",
		// tab: "driver",
		// 	required: false,
		// 	default: null,
		// 	type: "relation",
		// 	visible: true,
		// 	attribute: "full_name",
		// 	resource: "arl",
		// 	entity: "arl",
		// },
		// {
		// 	value: "fondosDePensiones_id",
		// 	text: "fondosDePensiones",
		// tab: "driver",
		// 	required: false,
		// 	default: null,
		// 	type: "relation",
		// 	visible: true,
		// 	attribute: "full_name",
		// 	resource: "fondos_de_pensiones",
		// 	entity: "fondosDePensiones",
		// },
		// {
		// 	value: "ibc",
		// 	text: "ibc",
		// 	tab: "advanced",
		// 	required: true,
		// 	default: null,
		// 	type: "number",
		// 	step: "any",
		// 	visible: true,
		// },
		{
			value: "foto_url",
			field: "foto",
			text: "image",
			tab: "driver",
			required: false,
			default: null,
			type: "image",
			visible: true,
		},
		{
			value: "location",
			text: "location",
			tab: "advanced",
			required: false,
			type: "location_picker",
			visible: false,
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
