<template>
	<div>
		<v-container fluid>
			<v-layout row wrap align-baseline>
				<v-flex xs12>
					<label class="subhead">{{ label | trans }}</label>
				</v-flex>
				<template v-for="(field, index) in extra">
					<v-flex :key="`key-${index}`" xs5 class="pa-1">
						<v-text-field
							:outlined="outlined"
							:filled="filled"
							:dense="dense"
							:rounded="dense"
							:solo="solo"
							:label="'key' | trans"
							v-model="field.key"
							:change="$emit('input', extra)"
						></v-text-field>
					</v-flex>
					<v-flex xs6 :key="`value-${index}`" class="pa-1">
						<v-text-field
							:outlined="outlined"
							:filled="filled"
							:dense="dense"
							:rounded="rounded"
							:solo="solo"
							:label="'value' | trans"
							v-model="field.value"
							:change="$emit('input', extra)"
						></v-text-field>
					</v-flex>
					<v-flex :key="`remove-${index}`" shrink v-if="(!min || extra.length > min) && (!max || extra.length < max)">
						<v-btn
							small
							text
							icon
							color="red"
							@click="
								extra.splice(index, 1);
								$emit('input', extra);
							"
							><v-icon>remove_circle</v-icon></v-btn
						>
					</v-flex>
				</template>
				<v-flex xs12 class="text-right">
					<v-btn
						icon
						color="primary"
						@click="
							extra.push({ key: '', value: '' });
							$emit('input', extra);
						"
						><v-icon>add</v-icon></v-btn
					>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "ExtraInput",
	async mounted() {
		await this.api.ready;
		if (this.value && Array.isArray(this.value) && this.value.length > 0) {
			this.extra = this.value;
		} else if (this.default && this.default.length > 0) {
			console.log(this.default);
			this.default.forEach((field) => {
				this.extra.push({
					key: field,
					value: "",
				});
			});
			this.$emit("input", this.extra);
		}
	},
	data: function () {
		return {
			extra: [],
		};
	},
	methods: {},
	props: {
		value: {
			default: () => [],
		},
		default: {
			type: Array,
			default: () => [],
		},
		label: {
			default: () => "extra fields",
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: null,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		solo: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style>
.ellipsed {
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
