<template>
	<div>
		<v-layout row wrap>
			<v-flex xs12 style="position: absolute; margin-top: -20px">
				<label class="caption">{{ label | trans }}</label>
			</v-flex>
			<v-flex xs6 sm4 md3 lg3>
				<v-select
					:rules="[rules.required]"
					@input="$emit('input', getAddress())"
					label="Calle,Carrera..."
					:outlined="outlined"
					:items="types"
					v-model="type"
				></v-select>
			</v-flex>
			<v-flex xs6 sm4 md3 lg3>
				<v-text-field
					:rules="[rules.required]"
					@input="$emit('input', getAddress())"
					:outlined="outlined"
					class="mx-1"
					:label="type"
					v-model="street"
				></v-text-field>
			</v-flex>
			<v-flex xs6 sm4 md3 lg3>
				<v-text-field
					:rules="[rules.required]"
					@input="$emit('input', getAddress())"
					label="#"
					placeholder="ej:10D-97 bis"
					:outlined="outlined"
					class="mx-1"
					v-model="address"
				></v-text-field>
			</v-flex>
			<v-flex xs6 sm4 md3 lg3>
				<v-text-field
					@input="$emit('input', getAddress())"
					label="Piso, Habitación, Bloque..."
					placeholder="Ej:Torre 4"
					:outlined="outlined"
					class="mx-1"
					v-model="extra"
				></v-text-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	name: "AddressInput",
	async mounted() {
		await this.api.ready;

		if (this.value) {
			if (this.types.includes(this.value.split(" ")[0])) {
				this.type = this.value.split(" ")[0];
			}
			if (this.types.indexOf(this.value.split(" ")[1])) {
				this.street = this.value.split(" ")[1].split(" ")[0];
			}
			if (this.value.split("#")[1]) {
				this.address = this.value.split("#")[1].split(" ")[0];
				if (this.value.split("#")[1].split(" ")[1]) {
					this.extra = this.value
						.substring(this.value.indexOf("#") + 1)
						.replace(this.value.split("#")[1].split(" ")[0], "");
				}
			}
		}
	},
	data: function () {
		return {
			rules: {
				required: (v) => !!v || "required",
				min: (v) => v.length >= 4 || "Min 4 characters",
			},
			type: "Calle",
			address: "",
			street: "",
			extra: "",
			types: ["Calle", "Carrera", "Transversal", "Diagonal", "Troncal"],
		};
	},
	methods: {
		getAddress() {
			this.$emit("update:error", this.type.length > 0 && this.address.length > 0 && this.street.length > 0);
			var val = `${this.type} ${this.street} #${this.address} ${this.extra}`.replace("##", "#");
			return val;
		},
	},
	props: {
		value: {
			default: () => "",
		},
		label: {
			default: () => "address",
		},
		outlined: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style>
.img-thumbnail {
	cursor: pointer;
}
.ellipsed {
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
