import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fill-height":"","layout":"","column":"","wrap":""}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),_c(VList,{staticStyle:{"width":"100%"},attrs:{"two-line":""}},[_vm._l((_vm.notifications),function(not){return [_c(VListItem,{key:not.id,class:{ 'grey lighten-3': !not.read_at },attrs:{"light":!not.read_at},on:{"dblclick":function($event){return _vm.deleteNoti(not)},"click":function($event){return _vm.clickNotification(not)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(not.titulo))]),_c(VListItemSubtitle,[_vm._v(_vm._s(not.mensaje))])],1),_c(VListItemAction,[(not.read_at)?_c(VIcon,[_vm._v("notifications_none")]):_c(VIcon,[_vm._v("notifications")])],1)],1),_c(VDivider,{key:not.id + '-divider'})]})],2),(!_vm.loading && _vm.notifications.length == 0)?_c(VLayout,{attrs:{"column":"","fill-height":"","justify-center":"","align-center":"","align-content-center":""}},[_c(VFlex,{staticClass:"text-center",attrs:{"xs12":"","align-self-center":""}},[_c(VIcon,{staticStyle:{"font-size":"100px"},attrs:{"color":"grey lighten-2","x-large":""}},[_vm._v("mdi-bell-sleep")]),_c('div',{staticClass:"display-1 text-capitalize pa-3"},[_vm._v(" "+_vm._s(_vm._f("trans")("no notifications"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }