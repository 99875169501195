import moment from "moment";

export const validations = {
	documento: "required",
	email: "emails",
	direccion: "required",
};

export const model = {
	model: "proveedor",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
		{
			name: "active",
			label: "active",
			type: "boolean",
			handler: () => `where[active]=1`,
			searchLogic: (v, i) => i.active,
		},
	],
	headers: [
		{
			text: "#",
			value: "id",
			type: "number",
			readonly: true,
			tab: "supplier",
		},
		{
			text: "name",
			value: "nombre",
			type: "text",
			tab: "supplier",
		},
		{
			text: "document",
			value: "documento",
			type: "text",
			tab: "supplier",
		},
		{
			text: "email",
			value: "email",
			type: "email",
			tab: "supplier",
		},
		{
			text: "phone_number",
			value: "telefono",
			type: "text",
			tab: "supplier",
		},
		{
			text: "active",
			value: "activo",
			type: "boolean",
			default: true,
			tab: "supplier",
		},

		{
			text: "Bien o Servicio",
			value: "bien_o_servicio",
			type: "text",
			visible: false,
			tab: "supplier",
		},
		{
			text: "address",
			value: "direccion",
			type: "textarea",
			visible: false,
			tab: "supplier",
			fullWidth: true,
		},
		{
			text: "entity",
			value: "entidad_id",
			type: "relation",
			attribute: "full_name",
			entity: "entidad",
			title: "full_name",
			resource: "entidades",
			visible: true,
			tab: "supplier",
		},
		{
			text: "image",
			field: "imagen",
			type: "image",
			value: "foto_url",
			placeholder: "foto_url",
			fullWidth: true,
			tab: "supplier",
		},

		// Others Tab
		{
			text: "Fecha de Ingreso A la Empresa",
			value: "fecha_ingreso",
			default: () => moment().format("YYYY-MM-DD"),
			type: "date",
			visible: false,
			tab: "others",
		},
		{
			text: "type",
			value: "tipo",
			type: "select",
			options: ["bien", "servicio", "bien_servicio"],
			default: "bien_servicio",
			visible: false,
			tab: "others",
		},
		{
			text: "note",
			value: "nota",
			type: "textarea",
			visible: false,
			tab: "others",
		},
		{
			text: "Codigo de Ciudad",
			value: "codigo_ciudad",
			type: "text",
			editonly: false,
			tab: "others",
		},
		{
			text: "Codigo de Actividad Economica",
			value: "codigo_actividad_economica",
			type: "text",
			editonly: false,
			tab: "others",
		},
		{
			value: "extra",
			text: "extra",
			required: false,
			default: null,
			type: "extra",
			editonly: true,
			tab: "others",
			fullWidth: true,
		},
		{
			value: "location",
			text: "location",
			type: "location",
			visible: false,
			tab: "others",
			fullWidth: true,
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
