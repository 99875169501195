import moment from "moment";

export const validations = {
	name: "required|min:2|max:50",
};

export const model = {
	model: "vehiculo",
	filters: [],
	headers: [
		{
			value: "placa",
			text: "plate",
			tab: "vehicle",
			required: false,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "marca",
			text: "mark",
			tab: "vehicle",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "modelo",
			text: "model",
			tab: "vehicle",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "linea",
			text: "line",
			tab: "vehicle",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "tipo",
			text: "type",
			tab: "vehicle",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "color",
			text: "color",
			tab: "vehicle",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "motor",
			text: "engine",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "chasis",
			text: "chasis",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "cilindraje",
			text: "Cilindraje",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "direccion",
			text: "address",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "uso",
			text: "Uso",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "licencia_transito",
			text: "Licencia Transito",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "dueño",
			text: "owner",
			tab: "advanced",
			required: true,
			default: "--",
			type: "text",
			visible: false,
		},
		{
			value: "fecha_ingreso",
			text: "admission date",
			tab: "advanced",
			required: true,
			default: () => moment().format("YYYY-MM-DD"),
			type: "date",
			visible: true,
		},
		{
			value: "activo",
			text: "active",
			tab: "vehicle",
			required: true,
			default: "1",
			type: "boolean",
			visible: true,
		},
		{
			value: "nota",
			text: "notes",
			tab: "advanced",
			required: false,
			default: null,
			type: "textarea",
			visible: false,
		},
		{
			value: "archivo_id",
			text: "file",
			tab: "advanced",
			type: "file",
			visible: false,
			entity: "archivo",
		},
		{
			value: "foto_url",
			field: "foto",
			text: "image",
			tab: "vehicle",
			required: false,
			type: "image",
			visible: true,
		},
		{
			value: "location",
			text: "location",
			tab: "advanced",
			type: "location_picker",
			visible: false,
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
