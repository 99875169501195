import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signature-container"},[_c(VLayout,{staticClass:"pa-2",attrs:{"row":"","wrap":"","align-content-center":"","justify-center":"","align-center":""}},[_c('span',{staticClass:"body-1 ml-2"},[_vm._v(_vm._s(_vm._f("trans")(_vm.label)))]),_c(VSpacer),_c(VBtn,{attrs:{"small":"","text":"","icon":""},on:{"click":_vm.clear}},[_c(VIcon,[_vm._v("format_clear")])],1)],1),_c('vue-signature-pad',{ref:"signaturePad",attrs:{"width":_vm.width,"height":_vm.height,"options":{ onBegin: _vm.onBegin, onEnd: _vm.onEnd }}}),_c(VDivider),_vm._t("actions",null,{"clear":_vm.clear,"value":_vm.value,"fire":() => _vm.$emit('fire', _vm.val)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }