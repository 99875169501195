export const validations = {
	name: "required|min:2",
	codigo: "min:1",
	location: "nullable|string",
	horario_id: "nullable|exists:AppModelsHorario",
	parent_id: "nullable|exists:AppModelsEntidad",
};

export const model = {
	model: "entidad",
	filters: [],
	headers: [
		{
			value: "name",
			text: "name",
			required: true,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "codigo",
			text: "codigo",
			required: false,
			default: null,
			type: "text",
			visible: true,
		},
		{
			value: "location",
			text: "location",
			required: false,
			default: null,
			type: "text",
			visible: true,
		},
		// {
		// 	value: "horario_id",
		// 	text: "horario",
		// 	required: false,
		// 	default: null,
		// 	type: "relation",
		// 	visible: true,
		// 	attribute: "full_name",
		// 	resource: "horarios"
		// },
		{
			value: "parent_id",
			text: "parent",
			required: false,
			default: null,
			type: "relation",
			visible: true,
			attribute: "full_name",
			resource: "entidades",
		},
	],
};

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
