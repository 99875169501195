export const validations = {
	code: "required|min:1|max:200|string|unique:App\\ModelsBank,code",
	name: "required|min:1|max:200|string|unique:App\\ModelsBank,name",
};

export const model = {
	model: "charges",
	load: "",
	defaultOrder: "code",
	filters: [],
	headers: [
		{
			value: "name",
			text: "name",
			required: true,
			type: "text",
			visible: true,
		},
		{
			value: "code",
			text: "code",
			required: true,
			type: "text",
			visible: true,
		},
		{
			value: "description",
			text: "description",
			required: false,
			type: "textarea",
			visible: true,
		},
		{
			value: "percent_arl",
			text: "Porcentaje arl",
			required: true,
			default: () => 4,
			type: "number",
			visible: false,
		},
		{
			value: "horario_id",
			text: "Horario",
			required: false,
			type: "relation",
			visible: false,
			attribute: "full_name",
			resource: "horarios",
			entity: "horario",
		},
		{
			value: "Auxilios",
			text: "Auxilios",
			required: false,
			type: "select",
			multiple: true,
			options: ["Transporte", "Vivienda", "Internet", "Desplazamiento"],
			visible: true,
		},
	],
};

export default {
	...model,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return {
			...h,
			rules,
		};
	}),
};
