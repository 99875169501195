<template>
	<div>
		<template v-if="modal">
			<slot
				name="activator"
				:on="openDialog"
				:attrs="{
					placeholder,
					solo,
					filled,
					flat,
					outlined,
					dense,
					labeled,
					required,
					rules,
					ready,
				}"
			>
				<v-text-field
					name="name"
					:label="placeholder"
					:solo="solo"
					:flat="flat"
					:filled="filled"
					:outlined="outlined"
					:dense="dense"
					:rounded="rounded"
					readonly
					:value="labeled"
					@click="openDialog"
					:required="required"
					:rules="rules"
					:loading="!ready"
					autocomplete="off"
				>
					<template v-slot:prepend-inner>
						<slot
							name="prepend-inner"
							v-if="prependIcon || image"
							:image="image"
							:prepend-icon="prependIcon"
							:selected="selected"
						>
							<div v-if="selected">
								<v-icon v-if="prependIcon">{{ prependIcon }}</v-icon>
								<v-avatar v-if="image" size="24" left>
									<img :src="selected[image]" :alt="selected[title]" />
								</v-avatar>
							</div>
						</slot>
					</template>
					<template v-slot:append>
						<slot name="append">
							<v-icon @click="$emit('input', multiple ? [] : null)" v-if="!required && value && value.length"
								>clear</v-icon
							>
							<v-icon @click="openDialog">
								{{ appendIcon ? appendIcon : "arrow_drop_down" }}
							</v-icon>
						</slot>
					</template>
				</v-text-field>
			</slot>
			<v-dialog eager scrollable no-click-animation v-model="dialog" max-width="800px" height="500px">
				<v-card>
					<v-progress-linear :indeterminate="true" v-if="!ready"></v-progress-linear>
					<v-card-actions class="py-3 px-1 row layout">
						<v-text-field
							class="mx-4"
							autofocus
							hide-details
							type="search"
							prepend-inner-icon="search"
							solo
							flat
							v-model="query"
							@input="debounce"
							:label="placeholder"
							append-icon="close"
							@click:append="
								$emit(`click:cancel`);
								dialog = false;
							"
						></v-text-field>
						<v-autocomplete
							class="mx-4"
							v-model="filterSelected"
							clearable
							autocomplete="off"
							v-if="filterableBy"
							:label="'crud.filter' | trans | capitalize"
							outlined
							@input="filter"
							hide-details
							dense
							:items="filterables"
						></v-autocomplete>
					</v-card-actions>
					<v-card-text class="pa-0" v-if="dialog">
						<span v-if="!filteredOptions.length" class="ma-3">
							<span v-if="query">{{ "crud.emptyTable" | trans }}</span>
							<span v-else>{{ "Comienza a escribir para buscar" | trans }}</span>
						</span>
						<v-list dense style="min-height: 350px; height: 100%">
							<RecycleScroller
								v-if="dialog"
								style="height: 100%"
								dense
								ref="scroller"
								:items="filteredOptions"
								:item-size="50"
								:key-field="trackBy"
								v-slot="{ item }"
							>
								<div style="height: 50px">
									<v-list-item style="height: 50px" @click="selectOption(item)">
										<slot name="item" :item="item">
											<v-list-item-avatar v-if="image" size="32">
												<img :src="item[image]" />
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title :title="item[title]" v-text="item[title]"></v-list-item-title>
												<v-list-item-subtitle
													v-if="description"
													:title="item[description]"
													v-html="item[description]"
												></v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action
												v-if="action"
												:title="item[action]"
												v-html="item[action]"
											></v-list-item-action>
											<v-list-item-action v-if="multiple">
												<v-checkbox readonly v-model="item.__selected" color="primary"></v-checkbox>
											</v-list-item-action>
											<v-list-item-action v-else>
												<v-icon v-if="trackBy ? item[trackBy] == value : item == value">check</v-icon>
											</v-list-item-action>
										</slot>
									</v-list-item>
									<v-divider></v-divider>
								</div>
							</RecycleScroller>
						</v-list>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							depressed
							color="primary"
							@click="
								$emit(`click:save`);
								dialog = false;
							"
							>Ok</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
		<v-autocomplete
			v-else
			small-chips
			v-model="selected"
			:loading="!ready"
			:items="options"
			:rules="rules"
			:label="placeholder"
			:multiple="multiple"
			:hide-selected="multiple"
			:search-input.sync="query"
			:cache-items="ajax"
			:no-data-text="noDataText"
			@input="select"
			autocomplete="off"
			:item-avatar="image"
			:item-text="title"
			:item-value="trackBy"
			:chips="multiple"
			:clearable="!required"
			:required="required"
			deletable-chips
			:solo="solo"
			:flat="flat"
			:rounded="rounded"
			:filled="filled"
			:filter="filterFunction"
			:outlined="outlined"
			:dense="dense"
			:single-line="singleLine"
			:prepend-icon="prependIcon"
			:prepend-inner-icon="prependInnerIcon"
			:append-icon="appendIcon"
		>
			<template v-slot:selection="data">
				<div>
					<v-chip
						small
						:input-value="data.selected"
						:class="{ 'white black--text': !multiple && !filled }"
						class="chip--select-single"
						:close="multiple"
						@click:close="removeFromIndex(data)"
					>
						<v-avatar v-if="image" left>
							<v-img :src="data.item[image]"></v-img>
						</v-avatar>
						{{ data.item[title] }}
					</v-chip>
				</div>
			</template>
			<template v-slot:item="data">
				<template>
					<v-list-item-avatar v-if="image" size="48">
						<img :src="data.item[image]" />
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title v-html="data.item[title]"></v-list-item-title>
						<v-list-item-subtitle v-if="description" v-html="data.item[description]"></v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action v-if="action" v-html="data.item[action]"></v-list-item-action>
				</template>
			</template>
		</v-autocomplete>
	</div>
</template>
<script>
import _ from "lodash";
var timeout;
export default {
	data() {
		return {
			dialog: false,
			options: [],
			filteredOptions: [],
			filterables: [],
			filterSelected: null,
			ready: false,
			placeholder: "Seleccionar",
			selected: null,
			query: "",
			alreadyLoaded: false,
			filterFunction: (item, queryText) => {
				const q = queryText.toLocaleLowerCase();
				return JSON.stringify(item).toLocaleLowerCase().includes(q);
			},
		};
	},
	methods: {
		async searchAjax() {
			this.ready = false;
			var symbol = this.resource.indexOf("?") > -1 ? "&" : "?";
			let filter = `${symbol}limit=250`;
			if (this.query) filter = `${symbol}q=${this.query}&limit=250`;
			if (this.filterableBy && !this.filterableBy.includes(".") && this.filterSelected)
				filter += `&where[${this.filterableBy}]=${this.filterSelected}`;
			try {
				const resp = await this.api.get(`${this.resource}${filter}`);
				resp.data.forEach((option) => {
					if (this.title) option[this.title] = _.get(option, this.title);
					if (this.filterableBy) option[this.filterableBy] = _.get(option, this.filterableBy);
					if (this.description) option[this.description] = _.get(option, this.description);
					if (this.action) option[this.action] = _.get(option, this.action);
				});
				this.options = resp.data;
				if (this.filterableBy) {
					if (this.filterableResource) {
						this.filterables = (await this.api.load(this.filterableResource))
							.map((item) => ({
								text: item[this.filterableText],
								value: item[this.filterableValue],
							}))
							.filter((v) => v);
					} else {
						this.filterables = _.uniq(this.options.map((i) => i[this.filterableBy])).filter((v) => v);
					}
				}
				const order = this.sortBy ? JSON.parse(JSON.stringify(this.sortBy)) : [this.title];
				this.filteredOptions = _.orderBy(this.options, order).filter((v) => v);
				if (this.filterSelected) {
					this.filteredOptions = this.filteredOptions
						.filter((i) => i[this.filterableBy] === this.filterSelected)
						.filter((v) => v);
				}
				this.ready = true;
			} catch (error) {
				console.error(error);
				this.ready = false;
				this.api.handleError(error);
			}
		},
		setHandler() {
			const handler = (results) => {
				this.setPlaceholder();
				this.options = results;
				if (this.nullValue) {
					var nullItem = {};
					nullItem[this.title] = "[Ninguno] ";
					nullItem[this.description] = "[Vacio]";
					nullItem[this.trackBy] = "null";
					var ind = this.options.findIndex((f) => f[this.trackBy] == "null");
					if (ind == -1) this.options.unshift(nullItem);
				}
				if (this.value) {
					if (this.trackBy) {
						if (this.multiple) {
							var values = this.value;
							if (!this.value) values = [];
							if (this.value && !Array.isArray(this.value)) values = [this.value];
							values = values.map((v) => `${v}`);
							this.selected = this.options.filter((i) => {
								this.$set(i, "__selected", values.includes(`${i[this.trackBy]}`));
								return i.__selected;
							});

							return this.selected;
						} else {
							this.selected = this.options.find((i) => i[this.trackBy] == this.value);
						}
					} else {
						this.selected = this.value;
					}
				}
				this.filter();
				if (this.filterableBy)
					this.filterables = _.uniq(this.options.map((i) => i[this.filterableBy])).filter((v) => v);
				this.ready = true;
			};
			if (this.items) {
				handler(this.items);
			} else if (this.resource) {
				this.deferred.load(this.resource, handler).then((results) => {
					handler(results);
				});
			}
		},
		removeFromIndex(data) {
			const copy = this.value.slice(0);
			const index = this.value.findIndex((i) => {
				if (this.trackBy) return i == data.item[this.trackBy];
				else return i == data.item;
			});
			if (index > -1) copy.splice(index, 1);
			this.$emit("input", copy);
		},
		openDialog() {
			this.dialog = true;
			setTimeout(() => {
				var index = 0;
				if (this.multiple) {
					if (this.selected && this.selected.length > 0) {
						index = this.filteredOptions.findIndex((i) => i.__selected);
						if (index >= 0) this.$refs.scroller ? this.$refs.scroller.scrollToItem(index) : null;
					}
				} else {
					if (this.selected) {
						index = this.filteredOptions.findIndex((i) =>
							this.trackBy ? i[this.trackBy] == this.selected[this.trackBy] : i == this.selected
						);
						if (index >= 0) this.$refs.scroller ? this.$refs.scroller.scrollToItem(index) : null;
					}
				}
			}, 1000);
			if (this.defaultFilterableBy) this.filterSelected = this.defaultFilterableBy;
			this.filter();
		},
		debounce() {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.filter();
			}, 700);
		},
		async filter() {
			if (this.ajax) return await this.searchAjax();
			if (!this.modal) return;
			const q = this.query.toLocaleLowerCase();
			const items = _.filter(this.options, (i) => {
				if (this.filterSelected && i[this.filterableBy] != this.filterSelected) return false;
				if (
					i[this.title] &&
					i[this.title]
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.indexOf(q) > -1
				)
					return true;
				if (
					i[this.description] &&
					i[this.description]
						.toLocaleLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.indexOf(q) > -1
				)
					return true;

				return false;
			});
			const order = this.sortBy ? JSON.parse(JSON.stringify(this.sortBy)) : [this.title];
			this.filteredOptions = _.orderBy(items, order).filter((v) => v);
		},
		selectOption(selection) {
			var selected;
			if (this.multiple) {
				if (!this.selected) this.selected = [];
				var index = this.selected.findIndex((opt) => {
					return this.trackBy ? opt[this.trackBy] == selection[this.trackBy] : opt == selection;
				});
				if (index == -1) {
					this.selected.push(selection);
				} else {
					this.selected.splice(index, 1);
				}
				selected = this.selected.map((opt) => (this.trackBy ? opt[this.trackBy] : opt));
			} else {
				if (!selection) return this.$emit("input", null);
				this.selected = selection;
				selected = this.trackBy ? selection[this.trackBy] : selection;
				this.dialog = false;
			}
			this.$emit("input", selected);
		},
		select(item) {
			this.selected = item;
			if (!item) return this.$emit("input", this.multiple ? [] : null);
			this.$emit("input", this.selected);
			this.dialog = false;
		},
		setPlaceholder() {
			if (this.label) {
				this.placeholder = this.label;
			} else {
				var translatable = "literals." + this.resource;
				if (this.resource == "clientes") {
					translatable = "literals.client";
				}
				if (this.resource == "users") {
					translatable = "literals.user";
				}
				if (this.resource == "entidades") {
					translatable = "literals.entity";
				}
				if (this.resource == "productos") {
					translatable = "literals.product";
				}
				if (this.resource == "categorias-productos") {
					translatable = "literals.category";
				}
				if (this.resource == "items") {
					translatable = "literals.item";
				}
				if (this.resource == "bodegas") {
					translatable = "literals.warehouse";
				}
				this.placeholder = this.$options.filters.trans("crud.select") + " " + this.$options.filters.trans(translatable);
			}
		},
	},
	watch: {
		resource: {
			immediate: true,
			async handler() {
				await this.api.ready;
				this.setPlaceholder();
				if (!this.ajax) this.setHandler();
			},
		},
		items() {
			this.setPlaceholder();
			this.items.forEach((option) => {
				if (this.title) option[this.title] = _.get(option, this.title);
				if (this.filterableBy) option[this.filterableBy] = _.get(option, this.filterableBy);
				if (this.description) option[this.description] = _.get(option, this.description);
				if (this.action) option[this.action] = _.get(option, this.action);
			});
			this.options = this.items;
			this.ready = true;
			if (this.trackBy) {
				if (this.multiple) {
					return (this.selected = this.options.filter((i) => {
						this.$set(i, "__selected", this.value && this.value.indexOf(i[this.trackBy]) > -1);
						return i.__selected;
					}));
				} else {
					return (this.selected = this.options.find((i) => i[this.trackBy] == this.value));
				}
			} else {
				this.selected = this.value;
			}
			this.filter();
			if (this.filterableBy) this.filterables = _.uniq(this.options.map((i) => i[this.filterableBy])).filter((v) => v);
		},
		value: {
			immediate: true,
			handler() {
				const handler = (results) => {
					results.forEach((option) => {
						if (this.title) option[this.title] = _.get(option, this.title);
						if (this.filterableBy) option[this.filterableBy] = _.get(option, this.filterableBy);
						if (this.description) option[this.description] = _.get(option, this.description);
						if (this.action) option[this.action] = _.get(option, this.action);
					});
					this.options = results;
					this.ready = true;
					if (this.trackBy) {
						if (this.multiple) {
							var values = this.value;
							if (!this.value) values = [];
							if (this.value && !Array.isArray(this.value)) values = [this.value];
							values = values.map((v) => `${v}`);
							this.selected = this.options.filter((i) => {
								this.$set(i, "__selected", values.includes(`${i[this.trackBy]}`));
								return i.__selected;
							});
						} else {
							return (this.selected = this.options.find((i) => i[this.trackBy] == this.value));
						}
					} else {
						this.selected = this.value;
					}
				};
				if (this.items) {
					handler(this.items);
				} else {
					if (this.ajax) {
						if (this.value != null && !this.alreadyLoaded) {
							var symbol = this.resource.indexOf("?") > -1 ? "&" : "?";
							this.api.get(`${this.resource}${symbol}where[id]=${this.value}&limit=1`).then((resp) => {
								handler(resp.data);
								this.alreadyLoaded = true;
							});
						}
						this.ready = true;
					} else {
						this.deferred.load(this.resource, handler).then((results) => {
							handler(results);
						});
					}
				}
			},
		},
		label() {
			this.setPlaceholder();
		},
		query() {
			if (this.ajax) {
				this.debounce();
			}
		},
		dialog(newVal, oldVal) {
			if (!newVal && oldVal) {
				this.$emit("close");
			}
		},
	},
	computed: {
		labeled() {
			if (!this.selected) return "";
			if (!this.multiple) return this.selected[this.title];
			return `${this.selected.length} ${this.$options.filters.trans("crud.selected")}`;
		},
		noDataText() {
			return this.ajax
				? !this.query
					? this.$options.filters.trans("Comienza a escribir para buscar")
					: this.$options.filters.trans("crud.infoEmpty")
				: this.$options.filters.trans("crud.emptyTable");
		},
	},
	props: {
		modal: {
			type: Boolean,
			default: false,
		},
		filterableBy: {
			type: String,
		},
		filterableResource: {
			type: String,
		},
		filterableValue: {
			type: String,
			default: "id",
		},
		filterableText: {
			type: String,
			default: "full_name",
		},
		defaultFilterableBy: {
			type: [String, Number],
		},
		sortBy: {
			type: [Array, String],
		},
		items: {
			type: Array,
			default: null,
		},
		value: {
			default: null,
		},
		trackBy: {
			type: String,
			default: "id",
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: () => null,
		},
		resource: {
			type: String,
			default: "users",
		},
		title: {
			type: String,
			default: "full_name",
		},
		description: {
			type: String,
			default: "document",
		},
		action: {
			type: String,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		image: {
			type: String,
			default: null,
		},
		solo: {
			type: Boolean,
			default: false,
		},
		flat: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		singleLine: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		prependInnerIcon: {
			type: String,
			default: undefined,
		},
		prependIcon: {
			type: String,
			default: undefined,
		},
		appendIcon: {
			type: String,
			default: undefined,
		},
		nullValue: {
			type: Boolean,
			default: false,
		},
		ajax: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
