import jsPDF from "jspdf";
import "jspdf-autotable";

function toXLSX(items, sheetName, filename, ext = "xlsx") {
	import("xlsx").then((mod) => {
		var XLSX = mod.default;
		var wb = XLSX.utils.book_new();
		var headers = Object.keys(items[0]);
		var columns = [];
		items.forEach((i) => {
			var column = [];
			headers.forEach((h) => {
				if (i[h] && typeof i[h].link == "string") {
					column[h] = {
						t: i[h].text,
						f: `HYPERLINK("${i[h].link}","${i[h].text}")`,
					};
				} else {
					column[h] = i[h];
				}
			});
			columns.push(column);
		});
		var ws = XLSX.utils.json_to_sheet(columns);
		XLSX.utils.book_append_sheet(wb, ws, sheetName);
		XLSX.writeFile(wb, filename + "." + ext);
	});
}

function toPdf(items, title = "", filename) {
	var doc = new jsPDF({
		orientation: "landscape",
		format: [279, 431],
	});
	var headers = Object.keys(items[0]);
	var linksColumns = {};
	var body = items.map((i) => {
		var column = [];
		headers.forEach((h, index2) => {
			if (i[h] && typeof i[h].link == "string") {
				linksColumns[index2] = true;
			}
			column.push(i[h]);
		});
		return column;
	});
	var totalPagesExp = "{total_pages_count_string}";
	doc.autoTable({
		head: [headers],
		body: body,
		rowPageBreak: "auto",
		bodyStyles: { minCellHeight: 5, valign: "middle" },
		margin: { top: 20 },

		didDrawPage: function (data) {
			// Header
			doc.setFontSize(15);
			doc.setTextColor(40);
			// doc.setFont("normal");
			doc.text(title, data.settings.margin.left, 12);

			// Footer
			var str = "Page " + doc.internal.getNumberOfPages();
			// Total page number plugin only available in jspdf v1.0+
			if (typeof doc.putTotalPages === "function") {
				str = str + " of " + totalPagesExp;
			}
			doc.setFontSize(10);

			// jsPDF 1.4+ uses getWidth, <1.4 uses .width
			var pageSize = doc.internal.pageSize;
			var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
			doc.text(str, data.settings.margin.left, pageHeight - 10);
		},
		didParseCell: function (data) {
			if (linksColumns[data.column.index] && data.cell.section === "body") {
				data.cell.text = "";
			}
		},
		didDrawCell: function (data) {
			if (linksColumns[data.column.index] && data.cell.section === "body") {
				doc.setTextColor("#0645AD");
				doc.textWithLink(data.cell.raw.text, data.cell.x + 2, data.cell.y + 4, {
					url: data.cell.raw.link,
					maxWidth: 50,
				});
				doc.setTextColor("#000000");
			}
		},
	});
	if (typeof doc.putTotalPages === "function") {
		doc.putTotalPages(totalPagesExp);
	}
	doc.save(`${filename}.pdf`);
	return doc;
}

function fromXLSX(data, options = {}) {
	const { skipRows = 0, sheetIndex = 0 } = options;
	return new Promise((resolve) => {
		import("xlsx").then((mod) => {
			const XLSX = mod.default;
			const workbook = XLSX.read(data, {
				type: "binary",
				cellDates: true,
			});
			const worksheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
			if (skipRows > 0) {
				/* get the range */
				const range = XLSX.utils.decode_range(worksheet["!ref"]);
				/* skip n rows */
				range.s.r += skipRows;
				if (range.s.r >= range.e.r) range.s.r = range.e.r;
				/* update range */
				worksheet["!ref"] = XLSX.utils.encode_range(range);
			}
			const json = XLSX.utils.sheet_to_json(worksheet);
			resolve(json);
		});
	});
}

function fromTable(querySelector, filename, ext = "xlsx") {
	var tbl = document.querySelector(querySelector);
	import("xlsx").then((mod) => {
		var XLSX = mod.default;
		var wb = XLSX.utils.table_to_book(tbl);
		XLSX.writeFile(wb, filename + "." + ext);
	});
}

function fromCSV(csv) {
	var lines = csv.split("\n");
	var results = [];
	var headers = lines[0].split(",").map((j) => trimchar(j.trim(), '"'));
	var i = 1;
	while (i < lines.length) {
		var obj = {};
		var currentline = lines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
		if (currentline && currentline.length > 1) {
			var j = 0;
			while (j < headers.length) {
				if (currentline[j]) {
					var aux = trimchar(currentline[j].trim(), '"');
					if (aux == "null") aux = null;
					if (aux != null && isFinite(aux)) aux = Number(aux);
					obj[headers[j]] = aux;
				}
				j++;
			}
			results.push(obj);
		}
		i++;
	}
	return results;
}

function trimchar(string, char) {
	if (char == null) {
		char = " ";
	}
	if (string == null) {
		return null;
	}
	if (string[0] === char) {
		string = trimchar(string.substring(1), char);
	}
	if (string[string.length - 1] === char) {
		string = trimchar(string.substring(0, string.length - 1), char);
	}
	return string;
}

function toMultipleSheets(data, filename, ext = "xlsx") {
	import("xlsx").then((mod) => {
		var XLSX = mod.default;
		var wb = XLSX.utils.book_new();
		for (const sheetName in data) {
			const items = data[sheetName];
			var columns = [];
			if (Array.isArray(items[0])) {
				var ws = XLSX.utils.aoa_to_sheet(items);
			} else {
				var headers = Object.keys(items[0]);
				items.forEach((i) => {
					var column = [];
					headers.forEach((h) => {
						if (i[h] && typeof i[h].link == "string") {
							column[h] = {
								t: i[h].text,
								f: `HYPERLINK("${i[h].link}","${i[h].text}")`,
							};
						} else {
							column[h] = i[h];
						}
					});
					columns.push(column);
				});
				ws = XLSX.utils.json_to_sheet(columns);
			}
			XLSX.utils.book_append_sheet(wb, ws, sheetName);
		}
		XLSX.writeFile(wb, filename + "." + ext);
	});
}

export default {
	async pdf(source, filename) {
		const { default: html2pdf } = await import("html2pdf.js");
		const doc = html2pdf();
		doc.set({
			filename: `${filename}.pdf`,
			margin: 1,
			image: { type: "jpeg", quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
		});
		console.log(source);
		const output = await doc.from(source).output("blob");
		const newWindow = window.open(URL.createObjectURL(output), "_blank");
		newWindow.document.title = `${filename}`;
	},

	export(data = [], filename, ext = "xlsx", name_of_sheet = null) {
		if (ext == "xlsx" || ext == "xls") {
			if (Array.isArray(data)) return toXLSX(data, name_of_sheet, filename, ext);
			else return toMultipleSheets(data, filename, ext);
		}
		if (ext == "pdf") {
			return toPdf(data, name_of_sheet, filename);
		}
		if (ext == "json") {
			const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${filename}.json`);
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(url);
			link.parentElement.removeChild(link);
		}
	},

	import(readAs = "auto", accept = "*", options) {
		var input = document.createElement("input");
		var promise = new Promise((resolve, reject) => {
			input.setAttribute("type", "file");
			input.setAttribute("accept", accept);
			input.setAttribute("display", "none");
			input.onchange = (event) => {
				try {
					var reader = new FileReader();
					var file = event.target.files[0];
					var extension = file.name.split(".").pop().toLowerCase();
					if (readAs == "auto") readAs = extension;
					reader.onload = () => {
						if (readAs == "json" || readAs == "geojson") {
							resolve({
								data: JSON.parse(reader.result),
								extension: extension,
								file: file,
							});
						} else if (readAs == "xml") {
							// xmlConverter.xml2json(reader.result, {
							//     compact: true
							// })
						} else if (readAs == "csv") {
							resolve({
								data: fromCSV(reader.result),
								extension: extension,
								file: file,
							});
						} else if (readAs == "xlsx" || readAs == "xls") {
							fromXLSX(reader.result, options).then((data) => {
								resolve({
									data: data,
									extension: extension,
									file: file,
								});
							});
						} else {
							resolve({
								data: reader.result,
								extension: extension,
								file: file,
							});
						}
					};

					if (readAs == "xlsx") reader.readAsBinaryString(file);
					else reader.readAsText(file);
				} catch (e) {
					console.error(e);
					reject(e);
				}
			};
		});
		input.click();
		return promise;
	},

	importMultiple(accept = "*") {
		var input = document.createElement("input");
		var promise = new Promise((resolve, reject) => {
			input.setAttribute("type", "file");
			input.setAttribute("accept", accept);
			input.setAttribute("display", "none");
			input.setAttribute("multiple", "multiple");
			input.onchange = (event) => {
				try {
					var files = event.target.files;
					resolve(files);
				} catch (e) {
					console.error(e);
					reject(e);
				}
			};
		});
		input.click();
		return promise;
	},

	fromTable: fromTable,

	dataURItoFile(dataURI, fileName = "image.jpg") {
		let byteString;
		if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
		else byteString = unescape(dataURI.split(",")[1]);

		let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		var blob = new Blob([ia], { type: mimeString });
		return new File([blob], fileName);
	},
};
