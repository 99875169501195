<template>
	<v-app>
		<v-navigation-drawer
			app
			:permanent="!mini"
			:mini-variant.sync="isOpen"
			v-model="drawer"
			:expand-on-hover="mini && !$vuetify.breakpoint.mdAndDown"
			v-if="api.user && mode == 'app'"
			class="d-print-none"
		>
			<v-layout
				class="hero text-left px-2 pt-1 primary darken-1"
				style="background-image: url(./img/hero.png)"
				align-center
				align-content-center
				@click="
					$router.push('/profile');
					drawer = $vuetify.breakpoint.mdAndUp;
				"
			>
				<v-flex xs3>
					<v-avatar size="42" contain class="my-2 mx-1 elevation-1">
						<img :src="api.user.imagen" class="elevation-4" />
					</v-avatar>
				</v-flex>
				<v-flex xs9 v-if="!mini || !isOpen" class="layout column">
					<div class="subtitle-2 white--text layout column">
						{{ api.user.full_name }}
						<span class="font-weight-bold" v-if="api.user.cliente">{{ api.user.cliente.full_name }}</span>
					</div>
					<span class="white--text caption flex">{{ api.user.email }}</span>
				</v-flex>
			</v-layout>
			<v-list nav dense>
				<v-flex xs12 v-if="api.user && api.user.clients.length > 1">
					<v-autocomplete
						:items="api.user.clients"
						v-model="api.user.cliente_id"
						:label="'client' | trans"
						solo
						dense
						height="30"
						hide-details
						hide-no-data
						hide-selected
						item-text="full_name"
						item-value="id"
						single-line
						full-width
						@input="changeClient"
						flat
					>
						<template v-slot:item="{ item }">
							<v-list-item-avatar>
								<v-img :src="item.foto_url" alt />
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ item.full_name }}</v-list-item-title>
								<v-list-item-subtitle>{{ item.document }}</v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</v-flex>
				<template v-for="(p, i) in pages">
					<template>
						<v-list-item
							v-module="p.modulo"
							v-if="!p.type || p.type === 'menu'"
							v-can="p.can"
							:key="p.name"
							:to="p.to"
							@click="
								drawer = $vuetify.breakpoint.mdAndUp;
								p.handler ? p.handler() : null;
							"
						>
							<v-list-item-action>
								<v-icon> {{ p.icon }}</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ p.name | trans }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action v-if="p.external">
								<v-icon x-small>mdi-link</v-icon>
							</v-list-item-action>
						</v-list-item>
						<v-subheader v-can="p.can" v-module="p.modulo" v-else-if="p.type === 'header'" :key="p.name + '-header'">
							{{ p.name }}
						</v-subheader>
						<v-divider
							v-can="p.can"
							v-module="p.modulo"
							v-else-if="p.type === 'divider'"
							:key="i + '-divider'"
						></v-divider>
						<v-list-group
							v-else-if="p.type === 'group'"
							v-module="p.modulo"
							:key="p.name + '-group'"
							v-can="p.can"
							:prepend-icon="p.icon"
						>
							<template v-slot:activator>
								<v-list-item-title>{{ p.name | trans }}</v-list-item-title>
							</template>
							<template v-for="page in p.pages">
								<v-list-item
									class="pl-3"
									v-module="page.modulo"
									v-can="page.can"
									:key="page.name + '-2'"
									:to="page.to"
									@click="drawer = $vuetify.breakpoint.mdAndUp"
								>
									<v-list-item-action>
										<v-icon>{{ page.icon }}</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>{{ page.name | trans }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-list-group>
					</template>
				</template>
				<v-list-item to="/profile">
					<v-list-item-action>
						<v-icon>mdi-account-circle</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ "profile" | trans }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<v-divider></v-divider>
				<v-list nav dense>
					<v-list-item @click="logout()">
						<v-list-item-action>
							<v-icon>exit_to_app</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Salir</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
		</v-navigation-drawer>
		<v-snackbar class="offline-bar mx-5 d-print-none" fixed bottom left :value="!deferred.onLine">
			<v-layout class="px-1 row" align-center>
				<v-icon dark left>mdi-cloud-off-outline</v-icon>
				<span class="caption">{{ "You Are Offline" | trans("__") }}</span>
			</v-layout>
		</v-snackbar>

		<v-app-bar app dark color="primary" class="d-print-none" v-if="api.user && mode == 'app'">
			<template v-if="api.user && mode == 'app'">
				<v-icon
					left
					@click="$route.meta.back < 0 ? $router.go($route.meta.back) : $router.push($route.meta.back)"
					v-if="$route.meta.back && $vuetify.breakpoint.smAndDown"
					>arrow_back</v-icon
				>
				<v-app-bar-nav-icon
					v-else-if="!mini || $vuetify.breakpoint.mdAndDown"
					@click.stop="drawer = !drawer"
				></v-app-bar-nav-icon>
			</template>
			<v-toolbar-title class="text-capitalize ma-0 pa-0 title">
				<template v-if="$route.meta.title">{{ $route.meta.title | trans }}</template>
				<template v-else-if="$route.name">{{ $route.name | trans }}</template>
				<img v-else :src="api.url + 'img/logo-newton.png'" style="height: 50px" class="mt-2" />
			</v-toolbar-title>
			<div class="flex-grow-1"></div>
			<v-badge
				color="green"
				v-if="api.user && mode == 'app' && api.user.broadcasting"
				v-can="'SuperAdmin'"
				overlap
				class="mx-4"
			>
				<span slot="badge">{{ api.usersConnected.length }}</span>
				<v-btn small :to="'/live-users'" icon>
					<v-icon>mdi-account-network</v-icon>
				</v-btn>
			</v-badge>
			<v-btn
				@click="initPush"
				color="white"
				small
				depressed
				light
				class="mx-2"
				v-if="notificationPermission !== 'granted'"
				>{{ "__.allow notifications" | trans }}</v-btn
			>
			<component
				v-bind:is="btn.component || 'v-btn'"
				:class="btn.class"
				v-for="(btn, i) in toolbar.buttons"
				:loading="btn.loading"
				:key="i"
				@click="btn.handler"
				:icon="!btn.text"
				:text="!!btn.text"
				:title="btn.title"
			>
				<v-icon v-if="btn.icon" :left="!!btn.text">{{ btn.icon }}</v-icon>
				<span v-if="btn.text">{{ btn.text }}</span>
			</component>
			<v-badge overlap color="pink" v-if="api.user && mode == 'app'">
				<span v-if="api.user.notificaciones_count > 0" slot="badge">{{ api.user.notificaciones_count }}</span>
				<v-icon @click="dialogNotifications = true">
					{{ api.user.notificaciones_count == 0 ? "notifications" : "notifications_active" }}
				</v-icon>
			</v-badge>
		</v-app-bar>

		<v-main>
			<v-progress-linear class="mt-1" v-if="api.progress != null" :value="api.progress"></v-progress-linear>
			<transition :duration="{ enter: 150, leave: 150 }" mode="out-in" name="slide-x-transition">
				<router-view />
			</transition>
		</v-main>

		<v-dialog v-model="dialogNotifications" fullscreen persistent :overlay="false" transition="dialog-transition">
			<v-card>
				<v-toolbar color="pink" dark>
					<v-toolbar-title class="title">{{ "notifications" | trans }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn color="primary" class="mx-2" depressed @click="readAllNotifications" small>Leer Todas</v-btn>
					<v-toolbar-items>
						<v-icon @click="dialogNotifications = false">close</v-icon>
					</v-toolbar-items>
				</v-toolbar>
				<notifications @close="dialogNotifications = false" v-if="dialogNotifications"></notifications>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import Notifications from "@/components/main/Notifications";
import { pages } from "./pages";
import { Device } from "@capacitor/device";
import { primary_color } from "./plugins/vuetify";

var beforePrint = () => {
	window.Tawk_API ? window.Tawk_API.hideWidget() : null;
};
var afterPrint = () => {
	window.Tawk_API ? window.Tawk_API.showWidget() : null;
};

function urlBase64ToUint8Array(base64String) {
	var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
let func;
let interval;
export default {
	components: { Notifications },
	name: "App",
	beforeCreate() {
		document.addEventListener("deviceready", func, false);
		window.addEventListener("beforeprint", beforePrint);
		window.addEventListener("afterprint", afterPrint);
	},
	beforeDestroy() {
		document.removeEventListener("deviceready", func);
		window.removeEventListener("beforeprint", beforePrint);
		window.removeEventListener("afterprint", afterPrint);
		clearInterval(interval);
	},
	async mounted() {
		this.isOpen = this.$vuetify.breakpoint.lgAndUp;
		this.drawer = this.$vuetify.breakpoint.lgAndUp;
		const platform = (await Device.getInfo()).platform;
		console.log("platform", platform);
		if (platform !== "web") {
			this.notificationPermission = "granted";
		} else {
			this.notificationPermission = `${Notification.permission}`;
		}
		this.$vuetify.theme.dark = await this.api.storage.get(
			"themeDark",
			window.matchMedia("(prefers-color-scheme: dark)").matches
		);
		this.$vuetify.theme.themes.light.primary = this.settings.color || primary_color;
		this.$vuetify.theme.themes.dark.primary = this.settings.color || primary_color;
		document.querySelector("meta[name=theme-color]").setAttribute("content", this.settings.color || primary_color);
		if (
			this.notificationPermission === "granted" &&
			(await this.api.ready) &&
			this.api.user &&
			(await Device.getInfo()).platform === "web"
		) {
			this.initPush(false);
		}
		// interval = setInterval(() => {
		// 	this.getNotificationsCount();
		// }, 5 * 60 * 1000);
	},
	data() {
		return {
			themeDark: false,
			drawer: true,
			mini: true,
			isOpen: true,
			mode: "app",
			notificationPermission: "ask",
			dialogNotifications: false,
			pages: pages,
		};
	},
	methods: {
		initPush(sendNotification = true) {
			if (!navigator.serviceWorker.ready) {
				return;
			}

			new Promise(function (resolve, reject) {
				const permissionResult = Notification.requestPermission(function (result) {
					resolve(result);
				});

				if (permissionResult) {
					permissionResult.then(resolve, reject);
				}
			}).then((permissionResult) => {
				if (permissionResult !== "granted") {
					throw new Error("We weren't granted permission.");
				}
				this.notificationPermission = permissionResult;
				this.subscribeUser(this.api.user.notification_token, sendNotification);
			});
		},
		subscribeUser(public_token = null, sendNotification = true) {
			navigator.serviceWorker.ready
				.then((registration) => {
					const subscribeOptions = {
						userVisibleOnly: true,
						applicationServerKey: urlBase64ToUint8Array(
							public_token || "BDSVywVDyY6r5ZGkKiqySgxxfXlci2XaC9dy2T2ID7yOfnqKaw0XJmYVlzrFAj6IrA1fPatODq1BcJ5y-8eBoa8"
						),
					};

					return registration.pushManager.subscribe(subscribeOptions);
				})
				.then((pushSubscription) => {
					console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
					this.storePushSubscription(pushSubscription, sendNotification);
				});
		},
		storePushSubscription(pushSubscription, sendNotification = true) {
			console.warn(pushSubscription, sendNotification, "notification no implemented yet");
			// this.api
			// 	.post(`users/${this.api.user.id}/subscribe-web-push?sendNotification=${sendNotification}`, pushSubscription)
			// 	.then((resp) => {
			// 		console.log(resp.data);
			// 	})
			// 	.catch((err) => {
			// 		console.error(err);
			// 	});
		},
		async changeClient() {
			await this.api.put(`users/${this.api.user.id}`, {
				cliente_id: this.api.user.cliente_id,
			});
			await this.api.storage.set("user", this.api.user);
			document.location.reload();
		},
		async logout() {
			await this.api.logout();
			await this.$router.replace("/login");
			document.location.reload();
		},
		readAllNotifications() {
			this.api.get("notificaciones/leer-todas").then(() => {
				this.api.user.notificaciones_count = 0;
				this.dialogNotifications = false;
			});
		},
		async getNotificationsCount() {
			const { data: count } = await this.api.get(`notifications?count=1&whereNull[]=read_at`);
			this.$set(this.api.user, "notificaciones_count", count);
		},
	},
	watch: {
		"$vuetify.theme.dark"() {
			this.api.storage.set("themeDark", this.$vuetify.theme.dark, false);
		},
		$route() {
			if (this.api.user && this.api.user.broadcasting) {
				this.api.joined.whisper("navigate", {
					user_id: this.api.user.id,
					route: {
						path: this.$route.fullPath,
						name: this.$route.name,
						title: document.title,
					},
				});
			}
		},
		"$route.query": {
			immediate: true,
			handler() {
				if (this.$route.query.view_mode) {
					this.mode = this.$route.query.view_mode;
				}
				if (this.$route.query.theme) {
					this.$vuetify.theme.dark = this.$route.query.theme == "dark";
				}
			},
		},
	},
};
</script>
<style>
@media only print {
	iframe {
		visibility: hidden !important;
		display: none !important;
	}
}
@media only screen and (min-width: 600px) {
	.offline-bar .v-snack__content {
		text-align: center;
		font-weight: bold;
	}

	.offline-bar .v-snack__wrapper {
		min-width: 120px;
		max-width: 100%;
		width: auto;
	}
}

.round {
	border-radius: 50px;
}

.hero {
	background-size: cover;
	background-repeat: repeat-x;
}

.hero v-img,
.hero div,
.hero span {
	cursor: pointer;
}

html {
	overflow: scroll;
	overflow-x: hidden;
}

*::-webkit-scrollbar {
	width: 1px; /* remove scrollbar space */
	height: 4px; /* remove scrollbar space */
	background: transparent; /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
*::-webkit-scrollbar-thumb {
	background: #ccccff;
}

/**
 * chips colors
 */
.recibida,
.emitida,
.abierta {
	background-color: #4caf50 !important;
	color: white !important;
	font-weight: 700;
}

.vencida,
.rechazada,
.anulada {
	background-color: #f44336 !important;
	color: white !important;
	font-weight: 700;
}

.completada,
.terminada,
.pagada,
.activa,
.activo {
	background-color: #43a047 !important;
	color: white !important;
	font-weight: 700;
}

.en.proceso,
.enviada {
	background-color: #ffeb3b !important;
	color: black !important;
	font-weight: 700;
}

.low,
.Baja {
	color: #43a047 !important;
	border-color: #43a047 !important;
}

.medium,
.Media {
	color: #ffeb3b !important;
	border-color: #ffeb3b !important;
}

.high,
.Alta {
	color: #fb8c00 !important;
	border-color: #fb8c00 !important;
}

.urgent,
.Urgente {
	color: #f44336 !important;
	border-color: #f44336 !important;
}
.overdue,
.Atrasada {
	color: #8e24aa !important;
	border-color: #8e24aa !important;
}

.hidden-hover:hover .hidden-hover-feature {
	visibility: visible;
}
.hidden-hover .hidden-hover-feature {
	visibility: hidden;
}

.apexcharts-menu-item {
	color: black;
}

.layout.flex:not(.row) {
	flex-direction: column;
}

.flex > div {
	flex: 1;
}
</style>
