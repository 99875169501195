import { api } from "@/services/api";
import { get } from "lodash";
const trans = function (string, key = "literals", replacers = null) {
	if (!string) return "";
	if (string.indexOf(".") === -1) string = `${key}.${string}`;
	var trans = get(api.trans, string);
	if (!trans) trans = string;
	if (replacers)
		Object.keys(replacers).forEach((key) => {
			trans = trans.replace(`:${key}`, replacers[key]);
		});
	return trans.replace("__.", "").replace("literals.", "").replace("crud.", "");
};

export default trans;
