export const validations = {
	nombre: "required|min:3|max:50",
	email: "required|email|max:50",
	cargo: "string|min:3|max:50",
	departamento: "string|min:3|max:50",
	direccion: "string|min:3|max:50",
	documento: "required|min:3|max:50",
	telefono: "string|number",
	password: "nullable|confirmed|min:6|max:50",
	password_confirmation: "min:6|max:50|min:6|max:50",
	imagen: "nullable|file",
	admin: "",
	active: "",
	entidad_id: "nullable|exists:entidades,id",
	cliente_id: "nullable|exists:clientes,id",
	// horario_id: "nullable|exists:horarios,id",
	extra: "array",
};

export const model = {
	model: "user",
	filters: [
		{
			name: "entidad_id",
			label: "entity",
			type: "relation",
			resource: "entidades",
			handler: (v) => `whereIn[entidad_id]=${v}`,
			searchLogic: (v, i) => `${i.entidad_id}` == `${v}`,
		},
		// {
		// 	name: "admin",
		// 	label: "admin",
		// 	type: "boolean",
		// 	handler: () => `where[admin]=1`,
		// 	searchLogic: (v, i) => i.active,
		// },
	],
	headers: [
		{
			text: "#",
			value: "id",
			visible: true,
			align: "center",
			type: "text",
			readonly: true,
			tab: "user",
		},
		{
			text: "name",
			value: "full_name",
			visible: true,
			field: "nombre",
			align: "center",
			type: "text",
			rules: "required|min:3",
			tab: "user",
		},
		{
			text: "email",
			value: "email",
			visible: true,
			align: "center",
			type: "email",
			rules: "required|emails",
			tab: "user",
		},
		{
			text: "document",
			value: "documento",
			visible: true,
			align: "center",
			type: "number",
			rules: "required",
			tab: "user",
		},
		{
			text: "phone_number",
			value: "telefono",
			visible: true,
			align: "center",
			type: "number",
			tab: "user",
		},
		{
			text: "password",
			value: "password",
			editonly: true,
			align: "center",
			type: "password",
			tab: "user",
		},
		{
			text: "permissionmanager.password_confirmation",
			value: "password_confirmation",
			editonly: true,
			align: "center",
			type: "password",
			tab: "user",
		},
		{
			text: "image",
			value: "imagen",
			placeholder: "imagen",
			field: "image",
			camera: true,
			visible: true,
			align: "center",
			type: "image",
			sortable: false,
			aspectRatio: 1,
			tab: "user",
		},
		{
			text: "admin",
			value: "admin",
			visible: false,
			default: true,
			align: "center",
			type: "boolean",
			tab: "others",
		},
		{
			text: "active",
			value: "active",
			visible: false,
			default: true,
			align: "center",
			type: "boolean",
			tab: "user",
		},
		{
			text: "departamento",
			value: "departamento",
			visible: false,
			align: "center",
			type: "text",
			tab: "others",
		},
		{
			text: "cargo",
			value: "cargo",
			visible: false,
			align: "center",
			type: "text",
			tab: "others",
		},
		{
			text: "address",
			value: "direccion",
			visible: false,
			align: "center",
			type: "text",
			tab: "others",
		},
		{
			text: "entity",
			value: "entidad_id",
			visible: true,
			entity: "entidad",
			resource: "entidades",
			align: "center",
			modulo: "entidades",
			type: "relation",
			attribute: "full_name",
			tab: "others",
		},
		{
			text: "company",
			value: "company_id",
			visible: true,
			entity: "company",
			resource: "companies",
			modulo: "empresas",
			align: "center",
			type: "relation",
			attribute: "full_name",
			tab: "others",
		},
		{
			text: "extra",
			value: "extra",
			visible: false,
			default: ["language"],
			type: "extra",
			tab: "others",
		},
		{
			text: "roles",
			value: "roles",
			visible: false,
			entity: "roles",
			resource: "roles",
			align: "center",
			type: "relation_multiple",
			default: [],
			attribute: "name",
			tab: "advanced",
		},
		{
			text: "permissions",
			value: "permissions",
			visible: false,
			entity: "permissions",
			resource: "permissions",
			align: "center",
			type: "relation_multiple",
			default: [],
			attribute: "name",
			tab: "advanced",
		},
		// {
		// 	text: "job places",
		// 	value: "puestos",
		// 	visible: false,
		// 	entity: "puestos",
		// 	resource: "puestos",
		// 	align: "center",
		// 	type: "relation_multiple",
		// 	default: [],
		// 	attribute: "nombre",
		// 	tab: "advanced",
		// 	modulo: "tablas",
		// },
		// {
		// 	text: "warehouses",
		// 	value: "bodegas",
		// 	visible: false,
		// 	entity: "bodegas",
		// 	resource: "bodegas",
		// 	align: "center",
		// 	type: "relation_multiple",
		// 	default: [],
		// 	attribute: "full_name",
		// 	tab: "advanced",
		// 	modulo: "inventarios",
		// },
		// {
		// 	text: "categories_documents",
		// 	value: "categorias-documentos",
		// 	visible: false,
		// 	entity: "categoriasdocumentos",
		// 	resource: "categorias-documentos",
		// 	align: "center",
		// 	type: "relation_multiple",
		// 	default: [],
		// 	attribute: "full_name",
		// 	tab: "advanced",
		// 	modulo: "gestion_documental",
		// },
		// {
		// 	text: "tickets",
		// 	value: "categorias_tickets",
		// 	entity: "categorias_tickets",
		// 	type: "relation_multiple",
		// 	resource: "categorias-tickets",
		// 	default: [],
		// 	visible: false,
		// 	tab: "advanced",
		// 	can: "SuperAdmin",
		// 	modulo: "tickets",
		// 	attribute: "full_name",
		// },
		// {
		// 	text: "client",
		// 	value: "cliente_id",
		// 	visible: false,
		// 	entity: "cliente",
		// 	resource: "clientes",
		// 	align: "center",
		// 	type: "relation",
		// 	can: "SuperAdmin",
		// 	attribute: "full_name",
		// 	tab: "clients",
		// },
		{
			text: "clients",
			value: "clients",
			visible: false,
			entity: "clients",
			resource: "clientes",
			align: "center",
			type: "relation_multiple",
			default: [],
			can: "staff",
			attribute: "full_name",
			tab: "clients",
		},
	],
};

// var rules = {};
// model.headers.map((h) => {
// 	rules[h.value || h.field] = h.rules;
// });
// console.log(rules);
// debugger;

export default {
	model: model.model,
	filters: model.filters,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return { rules: rules, ...h };
	}),
};
