import axios from "axios";
import Vue from "vue";
import lang from "@/services/lang.json";
import storage from "@/services/storage";
import Router from "@/router";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import Swal from "sweetalert2";
import { RequestInterceptor, ResponseInterceptor } from "@/services/interceptors";
const staffEmails = ["newtoneyc@gmail.com", "seedgabo@gmail.com", "gerencia@eycproveedores.com"];
const staffIds = [1, 2];
let url = process.env.VUE_APP_URL;
let endpoint = process.env.VUE_APP_ENDPOINT || "web";
if (url === "auto") url = window.location.href.replace(window.location.hash, "").replace(`/${endpoint}/`, "/public/");
if (url && url.includes("/public/")) {
	url = url.split("/public/")[0] + "/public/";
}
window.url = url;
window.Pusher = Pusher;
axios.defaults.headers.common = {
	"Content-Type": "application/json",
};
axios.interceptors.request.use((config) => RequestInterceptor(config, data));
axios.interceptors.response.use((config) => ResponseInterceptor(config, data));
var resolver;
var data = {
	perPromise: null,
	ready: new Promise((resolve) => {
		resolver = resolve;
	}),
	registrationOpen: false,
	Echo: null,
	trans: lang,
	objects: {},
	user: window.user ? window.user : null,
	modulos: window.modulos ? window.modulos : {},
	url: window.url ? window.url : "",
	storage: storage,
	usersConnected: [],
	progress: null,
	async startEcho() {
		await this.ready;
		if (data.Echo) {
			console.warn("already started Echo");
			data.stopEcho();
		}
		console.log("echo to:", data.user.hostEcho);

		data.Echo = window.$Echo = new Echo({
			broadcaster: "pusher",
			key: data.user.pusherAppKey || "f1a0c95966ac87f66913bb0b3ec20594",
			wsHost: data.user.hostEcho,
			wsPort: data.user.hostPort,
			authEndpoint: data.url + "broadcasting/auth",
			wssHost: data.user.hostEcho,
			wssPort: data.user.hostPort,
			enabledTransports: ["ws", "wss"],
			disableStats: true,
			auth: {
				headers: {
					"Auth-Token": data.user.token,
				},
			},
		});
		if (data.modulos.rastreo) {
			this.Echo.private("Application")
				.listen("Panic", (data) => {
					console.log("Panic ", data);
				})
				.listen("PanicUpdate", (data) => {
					console.log("PanicUpdate", data);
				});
		}

		// Automatic updated objects
		this.Echo.private("Application").subscription.bind_global((event, payload) => {
			console.log("new event", event);
			if (event.endsWith(".updated") || event.endsWith(".created")) {
				let resource = payload.resource;
				let newObj = payload.model;
				let collect = data.objects[resource];
				if (collect) {
					let index = collect.findIndex((i) => {
						return i.id == newObj.id;
					});

					if (collect.collection) {
						if (collect.collection[newObj.id]) {
							collect.collection[newObj.id] = Object.assign(collect.collection[newObj.id], newObj);
						} else {
							collect.collection[newObj.id] = newObj;
						}
					}
					if (index == -1) collect.push(newObj);
				}
				for (const key in data.objects) {
					if (key.startsWith(resource)) {
						const list = data.objects[key];
						if (list.collection && list.collection[newObj.id]) {
							list.collection[newObj.id] = Object.assign(list.collection[newObj.id], newObj);
						}
					}
				}
			}
			if (event.endsWith(".deleted")) {
				let resource = payload.resource;
				let collect = data.objects[resource];
				let id = payload.model.id;
				if (collect) {
					let index = collect.findIndex((i) => {
						return i.id == id;
					});
					delete collect.collection[id];
					if (index > -1) {
						return collect.splice(index, 1);
					}
				}
				for (const key in data.objects) {
					if (key.startsWith(resource)) {
						const list = data.objects[key];
						if (list.collection) {
							let index = collect.findIndex((i) => {
								return i.id == id;
							});
							delete list.collection[id];
							if (index > -1) {
								return collect.splice(index, 1);
							}
						}
					}
				}
			}
		});

		data.joined = this.Echo.join("App.Mobile")
			.here((users) => {
				console.log("here", users);
				this.usersConnected = users.filter(
					(u) => staffEmails.includes(data.user.email) || !staffEmails.includes(u.email)
				);
				setTimeout(() => {
					data.joined.whisper("navigate", {
						user_id: data.user.id,
						route: {
							path: Router.currentRoute.fullPath,
							name: Router.currentRoute.name,
							title: document.title,
						},
					});
				}, 100);
			})
			.joining((user) => {
				console.log("joining", user);
				if (staffEmails.includes(data.user.email) || !staffEmails.includes(user.email)) {
					this.usersConnected.push(user);
				}
				setTimeout(() => {
					data.joined.whisper("navigate", {
						user_id: data.user.id,
						route: {
							path: Router.currentRoute.fullPath,
							name: Router.currentRoute.name,
							title: document.title,
						},
					});
				}, 100);
			})
			.leaving((user) => {
				console.log("leaving", user);
				var ind = this.usersConnected.findIndex((u) => u.id == user.id);
				if (ind > -1) this.usersConnected.splice(ind);
			})
			.listenForWhisper("navigate", (e) => {
				var user = this.usersConnected.find((u) => u.id == e.user_id);
				if (user) Vue.set(user, "route", e.route);
			});

		return data.Echo;
	},
	stopEcho() {
		if (this.Echo) {
			this.Echo.leave("Application");
			this.Echo.leave("App.Mobile");
			this.Echo = undefined;
		}
	},

	async init() {
		try {
			if (!window.url) {
				var url = window.localStorage.getItem("url");
				if (url) {
					data.url = url;
					data.storage.prefix = url;
				}
			}
			if (window.auth_token) {
				data.user = (await data.get(`login?token=${window.auth_token}`)).data;
				await data.storage.set("user", data.user);
			}
			data.user = await data.storage.get("user");
			data.modulos = await data.storage.get("modulos");
			data.roles = await data.storage.get("roles");
			var trans = await data.storage.get("trans");
			if (trans) {
				data.trans = trans;
			}
			if (!data.cart) data.cart = [];
			if (data.user) {
				window.user = data.user;
				axios.defaults.headers.common = {
					"Auth-Token": data.user.token,
					"Content-Type": "application/json",
				};
			}
			if (data.user) {
				var resp = await data.get("login");
				var set = window.localStorage.getItem("settings");
				if (set) set = JSON.parse(set);
				else set = { language: "es" };
				data.loadTranslations(set.language);
				data.user = Object.assign(data.user, resp.data);
				window.user = await data.storage.set("user", resp.data);
				data.modulos = await data.storage.set("modulos", resp.data.modulos);
				data.roles = resp.data.roles;
				data.roles.collection = data.mapToCollection(resp.data.roles, "name");
				data.roles = await data.storage.set("roles", data.roles);
				if (data.user.broadcasting) data.startEcho();
				let file = document.createElement("link");
				file.rel = "stylesheet";
				file.href = `${data.url}css/override.css`;
				document.head.appendChild(file);
			}
		} catch (error) {
			data.handleError(error);
			console.error(error);
		}
		resolver(data.user);
	},

	get(uri) {
		return axios.get(data.url + `api/` + uri);
	},
	post(uri, payload = {}) {
		var formData = new FormData();
		var type = "application/json";
		formData.append("_method", "POST");
		Object.keys(payload).forEach((k) => {
			var val = payload[k];
			if (val instanceof File) {
				formData.append(k, val);
				type = "multipart/form-data";
			} else if (val instanceof Array && val.length === 1 && val[0] instanceof File) {
				formData.append(k, val[0]);
				type = "multipart/form-data";
			} else if (typeof val == "object") {
				formData.append(k, JSON.stringify(val));
			} else if (val != undefined && val != "null") {
				formData.append(k, val);
			}
		});
		var toSend = type == "application/json" ? payload : formData;
		var promise = axios.post(data.url + `api/` + uri, toSend, {
			headers: { "Content-Type": type },
		});
		promise.then((resp) => data.updateObject(uri.split("/")[0], resp.data));
		return promise;
	},
	put(uri, payload = {}) {
		var formData = new FormData();
		var type = "application/json";
		formData.append("_method", "PUT");
		Object.keys(payload).forEach((k) => {
			var val = payload[k];
			if (val instanceof File) {
				formData.append(k, val);
				type = "application/x-www-form-urlencoded";
			} else if (val instanceof Array && val.length === 1 && val[0] instanceof File) {
				formData.append(k, val[0]);
				type = "application/x-www-form-urlencoded";
			} else if (typeof val == "object") {
				formData.append(k, JSON.stringify(val));
			} else if (val != undefined && val != "null") {
				formData.append(k, val);
			}
		});
		var promise;
		if (type == "application/json") {
			promise = axios.put(data.url + `api/` + uri, payload, {
				headers: { "Content-Type": type },
			});
		} else {
			promise = axios.post(data.url + `api/` + uri, formData, {
				headers: { "Content-Type": type },
			});
		}

		promise.then((resp) => data.updateObject(uri.split("/")[0], resp.data));
		return promise;
	},
	delete(uri) {
		var promise = axios.delete(data.url + `api/` + uri);
		promise.then(() => data.deleteObject(uri.split("/")[0], uri.split("/")[1]));
		return promise;
	},
	async getAll(resource, paginate = 500) {
		var adder = resource.indexOf("?") > -1 ? "&" : "?";
		// TODO: makes this no do paginate when loading a resource
		const first = (await data.get(`${resource}${adder}paginate=${paginate}`)).data;
		let array = [];
		if (first.data) array = first.data;
		else array = first;
		const promises = [];
		for (let page = 2; page <= first.last_page; page++) {
			promises.push(data.get(`${resource}${adder}paginate=${paginate}&page=${page}`));
		}
		await Promise.all(promises);
		for (const promise of promises) {
			let chunk = (await promise).data;
			let newData = [];
			if (chunk.data) newData = chunk.data;
			else newData = chunk;
			array.push(...newData);
		}
		return array;
	},
	loadAll(resource, paginate = 500) {
		if (this.objects[resource] && this.objects[resource].promise) return this.objects[resource].promise;
		this.objects[resource] = [];
		let promise = (this.objects[resource].promise = new Promise((resolve, reject) => {
			data
				.getAll(resource, paginate)
				.then((array) => {
					this.objects[resource] = array;
					this.objects[resource].promise = promise;
					if (Array.isArray(array)) {
						array.collection = this.mapToCollection(array);
					}
					Vue.set(this.objects, resource, array);
					resolve(this.objects[resource]);
				})
				.catch((error) => {
					reject(error);
				});
		}));
		return this.objects[resource].promise;
	},
	async load(resource) {
		return await data.loadAll(resource);
	},
	downloadFile(uri, name, additional = {}, onDownloadProgress = undefined, method = "GET") {
		let url = data.url + `api/` + uri;

		if (uri.startsWith("https://") || uri.startsWith("http://")) url = uri;
		if (window.cordova) {
			if (window.cordova.platformId == "android") {
				return navigator.app.loadUrl(url, { openExternal: true });
			} else if (window.cordova.platformId == "ios") {
				return window.open(url, "_system", "location=yes");
			}
		}
		return axios({
			url: url,
			method: method,
			responseType: "blob",
			data: additional,
			onDownloadProgress: function (progressEvent) {
				if (onDownloadProgress) onDownloadProgress(progressEvent);
				if (progressEvent.total == 0) data.progress = 0;
				else data.progress = (progressEvent.loaded / progressEvent.total) * 100;
				console.log(data.progress);
			},
		})
			.then((response) => {
				data.progress = null;
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				const contentDisposition = response.headers["content-disposition"];
				if (!name && contentDisposition) {
					const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
					if (fileNameMatch.length === 2) name = fileNameMatch[1];
				}
				link.setAttribute("download", `${name}`);
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url);
				link.parentElement.removeChild(link);
			})
			.catch(() => {
				data.progress = null;
			});
	},
	async login(username, password, remember = false, config = { saveUser: true }) {
		axios.defaults.headers.common = {
			"Content-Type": "application/json",
		};
		var promise = axios.get(
			data.url + `api/login?username=${username}&password=${password}${remember ? "&remember=true" : ""}`
		);
		if (config.saveUser) {
			promise.then((resp) => {
				data.user = resp.data;
				data.modulos = resp.data.modulos;
				data.roles = resp.data.roles;
				data.roles.collection = data.mapToCollection(resp.data.roles, "name");
				var set = window.localStorage.getItem("settings");
				data.storage.set("user", data.user);
				data.storage.set("modulos", data.modulos);
				data.storage.set("roles", data.roles);
				axios.defaults.headers.common = {
					"Auth-Token": data.user.token,
					"Content-Type": "application/json",
				};
				if (set) set = JSON.parse(set);
				else set = { language: "es" };
				data.loadTranslations(set.language);
				if (data.user.broadcasting) data.startEcho();
				let file = document.createElement("link");
				file.rel = "stylesheet";
				file.href = `${data.url}css/override.css`;
				document.head.appendChild(file);
			});
		}
		return await promise;
	},
	async checkCSRfToken() {
		return await axios.get(data.url + `sanctum/csrf-cookie`);
	},
	async logout() {
		data.user = null;
		data.objects = {};
		data.trans = lang;
		data.perPromise = null;
		data.modulos = window.modulos ? window.modulos : {};
		data.url = window.url ? window.url : "";
		data.stopEcho();
		await data.storage.clear();
		return new Promise((resolve) => {
			axios
				.get(data.url + `api/` + "logout")
				.then(resolve)
				.catch(resolve);
		});
	},
	loadTranslations(lang = "es") {
		var promise = data.get(`lang?lang=${lang}`);
		promise.then((resp) => {
			data.trans = resp.data;
			data.storage.set(this.trans);
		});
		return promise;
	},
	async loadSettings() {
		await data.load("settings");
		data.objects.settings.collection = data.mapToCollection(data.objects.settings, "key");
		const keyValuePair = {};
		data.objects.settings.forEach((setting) => {
			keyValuePair[setting.key] = setting.value;
		});
		return keyValuePair;
	},
	clearSettings() {
		delete data.objects.settings;
	},
	// Payment method using PAYU
	async payment(pedidoId, responseUrl = null) {
		try {
			var { payload, url } = (await data.get(`pedidos/${pedidoId}/payu-payload`)).data;
		} catch (error) {
			console.error(error);
			throw error;
		}
		var form = document.createElement("form");
		form.method = "POST";
		form.action = url;
		form.style.visibility = "hidden";
		data.storage.set("referenceCode", payload.referenceCode);
		Object.keys(payload).forEach((param) => {
			var input = document.createElement("input");
			input.type = "hidden";
			input.name = param;
			input.value = payload[param];
			form.appendChild(input);
		});
		var input = document.createElement("input");
		if (responseUrl) {
			input.type = "hidden";
			input.name = "repsonseUrl";
			input.value = responseUrl;
			form.appendChild(input);
		} else {
			input.type = "hidden";
			input.name = "repsonseUrl";
			input.value = "https://utilesya.com/payment-approved/" + pedidoId;
			form.appendChild(input);
		}
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	},
	successful(resp) {
		console.info(resp);
		var text = "Exito!";
		if (typeof resp == "string") text = resp;
		if (resp && resp.data && resp.data.status) text = resp.data.status;
		if (resp && resp.data && resp.data.message) text = resp.data.message;
		return Swal.fire({
			icon: "success",
			showConfirmButton: false,
			position: "top-end",
			toast: true,
			timer: 3000,
			title: text,
		});
	},
	handleError(err = "Error!") {
		var text = "Internal Server Error";
		var error_list = [];
		console.error(err.response ? err.response : err);
		if (err.response) {
			if (err.response.status == 401 && Router.currentRoute.path != "/login") {
				return Router.replace("/login?returnUrl=" + Router.currentRoute.path);
			}
			if (err.response.status == 419 && Router.currentRoute.path != "/login") {
				return data.checkCSRfToken();
			}
			if (err.response.status && data.trans && data.trans.http && data.trans.http[err.response.status])
				text = data.trans.http[err.response.status];
			if (err.response.data && err.response.data.errors)
				for (const k in err.response.data.errors) error_list = error_list.concat(err.response.data.errors[k]);
			if (err.response.data && err.response.data.results) error_list = [err.response.data.results];
		}
		if (error_list.length > 0) {
			return Swal.fire({
				title: text,
				icon: "warning",
				html: `<div>
					${error_list.map((e) => `<span>${e}</span>`).join("<br>")}
				</div>`,
				timer: 10000,
			});
		}
		if (typeof err == "string") text = err;
		return Swal.fire({
			icon: "error",
			toast: true,
			position: "bottom",
			showConfirmButton: false,
			timer: 3000,
			title: text,
		});
	},
	handleErrorRedirection(err, resource = "page") {
		console.error(err.response ? err.response : err);
		if (err.response) {
			if (err.response.status !== 200 && Router.currentRoute.path != "/login") {
				if (err.response.status == 500) return Router.replace(`/500?resource=${resource}`);
				else return Router.replace(`/404?resource=${resource}`);
			}
		}
		return Router.replace(`/500?resource=${resource}`);
	},
	mapToCollection(array, key = "id") {
		var object = {};
		array.forEach((item) => {
			object[item[key]] = item;
		});
		return object;
	},
	async can(permissions) {
		await data.ready;
		if (permissions == "staff") {
			return staffEmails.indexOf(data.user.email.toLowerCase()) > -1 || staffIds.indexOf(data.user.id) > -1;
		}

		if (!data.permissions && data.user) {
			if (!data.perPromise) data.perPromise = data.get(`users/${data.user.id}?include=permissions,roles.permissions`);
			var user = (await data.perPromise).data;
			const _permissions = user.permissions;
			user.roles.forEach((role) => {
				role.permissions.forEach((p) => {
					if (!_permissions.find((per) => per.id == p.id)) _permissions.push(p);
				});
			});
			_permissions.collection = data.mapToCollection(_permissions, "name");
			data.permissions = _permissions;
		}

		if (!Array.isArray(permissions)) {
			permissions = [permissions];
		}

		for (let index = 0; index < permissions.length; index++) {
			const permission = permissions[index];

			if (data.roles && data.roles.collection["SuperAdmin"]) return true;

			if (!permission) return true;

			if (data.permissions.collection[permission]) return true;

			if (data.roles.collection[permission]) return true;
		}
		return false;
	},
	updateObject(resource, newObj) {
		let collect = data.objects[resource];
		if (collect && collect.collection) {
			let index = collect.findIndex((i) => {
				return i.id == newObj.id;
			});

			if (collect.collection[newObj.id]) {
				collect.collection[newObj.id] = Object.assign(collect.collection[newObj.id], newObj);
			} else {
				collect.collection[newObj.id] = newObj;
			}
			if (index == -1) collect.push(newObj);
		}

		var assocResource = resource.split("?")[0].split("/")[0];
		for (const key in data.objects) {
			if (key.startsWith(assocResource)) {
				let collect = data.objects[key];
				if (collect.collection) {
					var index = collect.findIndex((i) => {
						return i.id == newObj.id;
					});

					if (collect.collection) {
						if (collect.collection[newObj.id]) {
							collect.collection[newObj.id] = Object.assign(collect.collection[newObj.id], newObj);
						} else {
							collect.collection[newObj.id] = newObj;
						}
					}
					if (index == -1) collect.push(newObj);
				}
			}
		}
	},
	deleteObject(resource, id) {
		var assocResource = resource.split("?")[0].split("/")[0];
		for (const key in data.objects) {
			if (key.startsWith(assocResource)) {
				const list = data.objects[key];
				if (list.collection) {
					let index = list.findIndex((i) => {
						return i.id == id;
					});
					if (index > -1) {
						return list.splice(index, 1);
					}
					delete list.collection[id];
				}
			}
		}
		return false;
	},
	getToken() {
		return encodeURI(data.user.token);
	},
};
data.init();

var deferred = {
	retriesTime: 10000,
	requestQueue: [],
	onLine: navigator.onLine,
	async saveRequests() {
		return await data.storage.set("_deferred_request", JSON.parse(JSON.stringify(deferred.requestQueue)));
	},
	async loadRequests() {
		var queue = await data.storage.get("_deferred_request");
		if (queue) deferred.requestQueue = queue;
		if (deferred.requestQueue.length > 0) {
			this.execRequest();
		}
	},
	request(verb, uri, data = undefined) {
		var item = {
			verb: verb,
			uri: uri,
			data: data,
			promise: null,
			resolve: null,
			reject: null,
		};
		item.promise = new Promise((resolve, reject) => {
			item.resolve = resolve;
			item.reject = reject;
		});
		deferred.requestQueue.push(item);
		deferred.saveRequests();
		if (deferred.requestQueue.length === 1) deferred.execRequest();
		return item.promise;
	},
	get(uri) {
		return deferred.request("get", uri);
	},
	post(uri, payload) {
		return deferred.request("post", uri, payload);
	},
	put(uri, payload) {
		return deferred.request("put", uri, payload);
	},
	delete(uri) {
		return deferred.request("delete", uri);
	},
	async load(resource, cachedHandler = null) {
		var promise = data.load(resource);
		var timerout;
		var executed = false;
		promise
			.then(async (resp) => {
				executed = true;
				clearTimeout(timerout);
				await data.storage.set(`_deferred_cache_response_${resource}`, JSON.parse(JSON.stringify(resp)));
			})
			.catch(async (err) => {
				console.error(err);
				var cached = await data.storage.get(`_deferred_cache_response_${resource}`);
				if (!executed && cached) {
					executed = true;
					var resp = cached;
					resp.collection = data.mapToCollection(cached);
					resp.cached = true;
					data.objects[resource] = resp;
					cachedHandler(cached);
				}
			});
		if (cachedHandler) {
			timerout = setTimeout(async () => {
				var cached = await data.storage.get(`_deferred_cache_response_${resource}`);
				if (!executed && !navigator.onLine && cached) {
					executed = true;
					var resp = cached;
					resp.collection = data.mapToCollection(cached);
					resp.cached = true;
					data.objects[resource] = resp;
					cachedHandler(cached);
				}
			}, 1000);
		}

		return promise;
	},
	execRequest() {
		var request = deferred.requestQueue[0];
		if (navigator.onLine) {
			data[request.verb](request.uri, request.data)
				.then((resp) => {
					if (request.resolve) request.resolve(resp);
					deferred.requestQueue.shift();
					deferred.saveRequests();
					if (deferred.requestQueue.length > 0) deferred.execRequest();
				})
				.catch((err) => {
					console.error(err);
					if (!err.response || err.response.status === 429) {
						console.log("retrying in 5 secs");
						setTimeout(() => deferred.execRequest(), 5000);
					} else {
						if (request.reject) request.reject(err);
						deferred.requestQueue.shift();
						deferred.saveRequests();
						if (deferred.requestQueue.length > 0) deferred.execRequest();
					}
				});
			return true;
		}
		return false;
	},
	callbackOnline() {
		console.log("online again");
		Vue.set(deferred, "onLine", navigator.onLine);
		if (deferred.requestQueue.length > 0) deferred.execRequest();
	},
	callbackOffline() {
		console.log("offline");
		Vue.set(deferred, "onLine", navigator.onLine);
	},
	detectOnline() {
		if (navigator.onLine != deferred.onLine) {
			if (navigator.onLine) {
				deferred.callbackOnline();
			} else {
				deferred.callbackOffline();
			}
			deferred.onLine = navigator.onLine;
		}
	},
	init() {
		var callback = () => {
			document.addEventListener("online", deferred.callbackOnline, false);
			document.addEventListener("offline", deferred.callbackOffline, false);
			deferred.loadRequests();
			if (deferred.requestQueue.length > 0) deferred.execRequest();
			console.log("Online/Offline Callback stablished");
			setInterval(() => {
				deferred.detectOnline();
			}, 3000);
		};
		if (window.cordova) document.addEventListener("deviceready", callback);
		else document.addEventListener("DOMContentLoaded", callback);
	},
};
deferred.init();
export default {
	data: () => {
		return {
			api: data,
			deferred: deferred,
		};
	},
};

export { data as api };

window.$api = data;
window.$deferred = deferred;
