/* eslint-disable no-unused-vars */
function RequestInterceptor(config, api) {
	// if (config.method == "get" && config.url.indexOf("api/clientes") > -1 && config.url.indexOf("scope[byuser]=") == -1) {
	// 	console.warn("`scope[byuser]=` added to " + config.url);
	// 	config.url += config.url.indexOf("?") > -1 ? "&scope[byuser]=" : "?scope[byuser]=";
	// }
	return config;
}

function ResponseInterceptor(config, api) {
	return config;
}

export { RequestInterceptor, ResponseInterceptor };
