import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/main/Login.vue";
import { api } from "@/services/api";
import CrudRoutes from "./crudRoutes";
Vue.use(Router);

var router = new Router({
	routes: [
		{
			path: "/",
			name: "Dashboard",
			component: function () {
				return import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue");
			},
		},
		{
			path: "/dashboard",
			name: "dashboard",
			component: function () {
				return import(/* webpackChunkName: "Dashboard" */ "@/views/Dashboard.vue");
			},
		},
		{
			path: "/users",
			name: "users",
			component: function () {
				return import(/* webpackChunkName: "users" */ "@/views/Users.vue");
			},
		},
		{
			path: "/roles",
			name: "roles",
			component: function () {
				return import(/* webpackChunkName: "roles" */ "@/views/Roles.vue");
			},
		},
		{
			path: "/permissions",
			name: "permissions",
			component: function () {
				return import(/* webpackChunkName: "permissions" */ "@/views/Permissions.vue");
			},
		},
		{
			path: "/:model/:id/revisions",
			name: "Revisions",
			props: true,
			component: function () {
				return import(/* webpackChunkName: "revisions" */ "@/components/main/Revisions.vue");
			},
			meta: {
				back: -1,
			},
		},
		{
			path: "/workers/:workerId",
			name: "worker",
			component: function () {
				return import(/* webpackChunkName: "Workers" */ "@/views/Worker.vue");
			},
			props: true,
			meta: {
				back: -1,
			},
		},
		{
			path: "/settings",
			name: "settings",
			component: function () {
				return import(/* webpackChunkName: "settings" */ "@/components/main/Settings.vue");
			},
		},
		{
			path: "/profile",
			name: "profile",
			component: function () {
				return import(/* webpackChunkName: "profile" */ "@/components/main/Profile.vue");
			},
		},
		{
			path: "/login",
			name: "Sign in",
			component: Login,
		},
		{
			path: "/calendar",
			name: "Calendar",
			component: function () {
				return import(/* webpackChunkName: "calendar" */ "@/views/Calendar.vue");
			},
		},
		{
			path: "/about",
			name: "about",
			component: function () {
				return import(/* webpackChunkName: "about" */ "@/views/About.vue");
			},
		},
		{
			path: "/reports",
			name: "reports",
			component: function () {
				return import(/* webpackChunkName: "report" */ "@/components/main/Reports.vue");
			},
		},
		{
			path: "/reports/:reportId",
			name: "report",
			component: function () {
				return import(/* webpackChunkName: "report" */ "@/components/main/Report.vue");
			},
			props: true,
		},

		// Advanced
		{
			path: "/logs",
			name: "Logs Viewer",
			component: function () {
				return import(/* webpackChunkName: "logs" */ "@/components/main/Logs.vue");
			},
		},
		{
			path: "/logs/:date/:level?",
			name: "Log Viewer",
			component: function () {
				return import(/* webpackChunkName: "logs" */ "@/components/main/Log.vue");
			},
			props: true,
		},
		{
			path: "/live-users",
			name: "live users",
			component: function () {
				return import(/* webpackChunkName: "LiveUsers" */ "@/views/LiveUsers.vue");
			},
		},
		{
			path: "/translations",
			name: "translations",
			component: function () {
				return import(/* webpackChunkName: "logs" */ "@/components/main/Translations.vue");
			},
		},

		// Errors
		{
			path: "/404",
			name: "404 Not Found",
			component: function () {
				return import(/* webpackChunkName: "Error" */ "@/views/404.vue");
			},
			props: (route) => {
				return {
					resource: route.query.resource,
					paragraph: route.query.paragraph,
				};
			},
			meta: {
				back: -1,
			},
		},
		{
			path: "/500",
			name: "500 Internal Server Error",
			component: function () {
				return import(/* webpackChunkName: "Error" */ "@/views/500.vue");
			},
			props: (route) => {
				return {
					resource: route.query.resource,
					paragraph: route.query.paragraph,
				};
			},
			meta: {
				back: -1,
			},
		},
	],
});
CrudRoutes.forEach((route) => router.addRoute(route));

router.beforeEach(async (to, _from, next) => {
	await api.ready;
	if (to.path != "/login" && to.name != "report" && !api.user) {
		document.title = to.name;
		next({
			path: "/login",
			replace: true,
		});
		return;
	}
	next();
});
export default router;
