import trans from "../services/trans";
export const validations = {
	code: "required|min:1|max:200|string|unique:\\App\\Models\\Concept,code",
	name: "required|min:1|max:200|string|unique:\\App\\Models\\Concept,name",
	cuenta_id: "nullable|exists:\\App\\Models\\Cuenta,id",
	description: "string|nullable",
	value_type: "required,in:monetary,percentage",
	type: "string|required",
	extra: "array|nullable",
};

export const model = {
	model: "concept",
	load: "cuenta",
	defaultOrder: "id",
	filters: [],
	headers: [
		{
			value: "code",
			text: "code",
			required: true,
			default: null,
			tab: "concept",
			type: "text",
			visible: true,
		},
		{
			value: "name",
			text: "name",
			required: true,
			default: null,
			tab: "concept",
			type: "text",
			visible: true,
		},
		{
			value: "cuenta_id",
			text: "Cuenta Contable",
			tab: "concept",
			type: "relation",
			resource: "cuentas?where[type]=auxiliar",
			attribute: "full_name",
			description: "number",
			entity: "cuenta",
			action: "nature",
			visible: true,
		},
		{
			value: "description",
			text: "description",
			required: true,
			default: null,
			tab: "concept",
			type: "textarea",
			visible: false,
		},
		{
			value: "type",
			text: "type",
			type: "select",
			options: ["devengado", "deduccion"],
			required: true,
			default: "devengado",
			tab: "concept",
			visible: true,
		},
		{
			text: "value type",
			value: "value_type",
			type: "select",
			options: [
				{ text: trans("monetary"), value: "monetary" },
				{ text: trans("percentage"), value: "percentage" },
			],
			translate: true,
			default: "monetary",
			tab: "concept",
		},

		{
			value: "default_value",
			text: "default value",
			required: false,
			default: 0,
			tab: "concept",
			type: "currency",
			visibleIf: (i) => i.value_type == "monetary",
			min: 0,
			visible: true,
		},
		{
			value: "default_value",
			text: "percentage",
			required: false,
			default: 0,
			tab: "concept",
			type: "number",
			prependInnerIcon: "mdi-percent",
			visibleIf: (i) => i.value_type == "percentage",
			min: 0,
			visible: true,
		},

		{
			value: "active",
			text: "Activo",
			default: "1",
			tab: "concept",
			type: "boolean",
			visible: true,
		},
		{
			value: "is_salarial",
			text: "is salarial",
			default: "1",
			tab: "concept",
			type: "boolean",
			visible: true,
		},
		{
			value: "extra",
			text: "extra",
			required: true,
			default: [],
			type: "extra",
			tab: "advanced",
			visible: false,
		},
	],
};

export default {
	...model,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return {
			...h,
			rules,
		};
	}),
};
