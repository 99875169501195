import moment from "moment";
export const validations = {
	number: "required|string",
	notes: "nullable|string",
	items: "array|min:1",
	user_id: "nullable|exists:App\\Models\\User,id",
	approved_by: "nullable|exists:App\\Models\\User,id",
	proveedor_id: "required|exists:App\\Models\\Proveedor,id",
	entidad_id: "nullable|exists:App\\Models\\Entidad,id",
	bodega_id: "nullable|exists:App\\Models\\Bodega,id",
	cliente_id: "nullable|exists:App\\Models\\Cliente,id",
	transport_id: "nullable|exists:App\\Models\\Transporte,id",
	payment_method: "nullable|string",
	status: "nullable|string",
	date: "date|required",
	delivered_date: "date|nullable",
	extra: "nullable|array",
	is_processed: "nullable|boolean",
	total: "nullable|numeric",
	"items.*.name": "nullable|string|min:1",
	"items.*.precio": "nullable|numeric",
	"items.*.cantidad": "nullable|numeric|min:1",
	"items.*.cantidad_entregada": "nullable|numeric",
};
export const states = [
	{ value: "generado", text: "Generado" },
	{ value: "revisado", text: "Revisado" },
	{ value: "aprobado", text: "Aprobado" },
	{ value: "parcialmente entregado", text: "Parcialmente Entregado" },
	{ value: "entregado", text: "Entregado" },
	{ value: "rechazado", text: "Rechazado" },
	{ value: "cancelado", text: "Cancelado" },
	{ value: "pagado", text: "Pagado" },
];

export const model = {
	model: "purchaseOrder",
	load: "entidad,proveedor,approver,bodega,cliente",
	defaultOrder: "-id",
	filters: [
		{
			name: "from",
			label: "Desde:",
			type: "date",
			handler: (v) => `from=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.date) > moment(v),
		},
		{
			name: "to",
			label: "Hasta:",
			type: "date",
			handler: (v) => `to=${moment(v).format()}`,
			searchLogic: (v, i) => moment(i.date) < moment(v),
		},
		{
			name: "proveedor_id",
			label: "suppliers",
			type: "relation",
			resource: "proveedores",
			ajax: true,
			multiple: true,
			handler: (v) => (v && v.length > 0 ? `whereIn[proveedor_id]=${v}` : ``),
			searchLogic: (v, i) => `${i.proveedor_id}` == `${v}`,
		},
		{
			name: "status",
			label: "status",
			type: "select",
			options: states,
			multiple: true,
			handler: (v) => {
				if (v.length > 0) return `whereIn[status]=${v.join(",")}`;
				return "";
			},
			searchLogic: (v, i) => {
				if (v.length > 0) return v.indexOf(i.status) > -1;
				return true;
			},
		},
	],
	headers: [
		{
			value: "number",
			text: "Numero",
			default: null,
			type: "text",
			tab: "purchase order",
		},
		{
			value: "notes",
			text: "Notas",
			default: null,
			type: "textarea",
			tab: "purchase order",
			visible: false,
			rows: 1,
		},
		{
			value: "status",
			text: "Estado",
			default: "generado",
			chip: true,
			type: "select",
			options: states,
			tab: "purchase order",
		},
		{
			value: "proveedor_id",
			text: "supplier",
			default: null,
			modulo: "proveedores",
			resource: "proveedores",
			type: "relation",
			tab: "purchase order",
			attribute: "full_name",
			entity: "proveedor",
		},
		{
			value: "entidad_id",
			text: "entity",
			default: null,
			resource: "entidades",
			type: "relation",
			tab: "purchase order",
			attribute: "full_name",
			entity: "entidad",
		},
		{
			value: "cliente_id",
			text: "client",
			resource: "clientes",
			modulo: "clientes",
			visible: false,
			type: "relation",
			tab: "purchase order",
			attribute: "full_name",
			entity: "cliente",
		},
		{
			value: "approved_at",
			text: "approved at",
			readonnly: true,
			visible: true,
			default: null,
			type: "date",
			tab: "approval workflow",
		},
		{
			value: "approved_by",
			text: "approver",
			resource: "users",
			type: "relation",
			tab: "approval workflow",
			attribute: "full_name",
			entity: "approver",
		},
		{
			value: "payment_method",
			text: "payment method",
			default: "efectivo",
			type: "select",
			options: ["efectivo", "transferencia", "punto de venta", "Tarjeta Debito", "Tarjeta Credito"],
			tab: "purchase order",
		},
		// {
		//     "value": "transport_id",
		//     "text": "transport",
		//     "resource": "transports",
		//     "required": false,
		//     "default": null,
		//     "type": "relation",
		//     "tab": "purchase order",
		//     "attribute": "full_name",
		//     "entity": "transport"
		// },
		// {
		// 	value: "approval_workflow",
		// 	text: "approval workflow",
		// 	editonly: true,
		// 	fullWidth: true,
		// 	type: "textarea",
		// 	tab: "approval workflow",
		// },
		{
			value: "date",
			text: "Fecha",
			default: () => moment().format("YYYY-MM-DDTHH:mm:ss"),
			type: "date",
			tab: "purchase order",
		},
		{
			value: "delivered_date",
			text: "delivered date",
			readonnly: true,
			visible: false,
			default: null,
			type: "datetime",
			tab: "purchase order",
		},
		{
			value: "is_processed",
			text: "is processed",
			type: "boolean",
			readonly: true,
			tab: "purchase order",
		},
		{
			value: "extra",
			text: "extra",
			type: "extra",
			tab: "advanced",
			fullWidth: true,
			editonly: true,
		},
		{
			value: "total",
			text: "total",
			readonly: true,
			visible: true,
			type: "currency",
			tab: "purchase order",
		},
		{
			value: "bodega_id",
			text: "warehouse",
			resource: "bodegas",
			modulo: "inventarios",
			default: null,
			type: "relation",
			tab: "purchase order",
			attribute: "full_name",
			entity: "bodega",
		},
		{
			value: "items",
			text: "items",
			editonly: true,
			visible: false,
			fullWidth: true,
			type: "component",
			tab: "purchase order",
			component: "item-searcher",
			props: {
				validateStock: false,
				priceValue: "precio_compra",
			},
		},
	],
};

export default {
	...model,
	headers: model.headers.map((h) => {
		let rules = validations[h.value || h.field];
		return {
			...h,
			rules,
		};
	}),
};
